<div class="bg-gray-100 p-2 rounded-lg mt-4">
  <h2 class="mb-2">Hierarchia zariadení:</h2>
  <div class="max-h-64 overflow-auto">
    <ol class="relative ml-2 mt-4 mb-4 text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
      @for (item of pasportEntityParents; track item.uuid) {
      <li class="mb-3 ms-6">
        <span
          class="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -start-1.5 ring-2 ring-white dark:ring-gray-900 dark:bg-gray-700">
        </span>
        <h3 class="font-medium leading-tight">{{ dataService.formatEntityType(item?.type) }} {{ item?.name }}</h3>
        <p class="text-sm">{{ item.address || item?.data?.address }}</p>
      </li>
      }

      <li class="mb-3 ms-6">
        <span
          class="absolute flex items-center justify-center w-3 h-3 bg-green-200 rounded-full -start-1.5 ring-2 ring-white dark:ring-gray-900 dark:bg-green-900">
        </span>
        <h3 class="font-medium leading-tight">{{ dataService.formatEntityType(deviceTypeName) }} {{
          objectName }}</h3>
        <p class="text-sm">{{ address }}</p>
      </li>

      @for (item of pasportEntityChildren; track item.uuid) {
      <li class="mb-3 ms-6">
        <span
          class="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -start-1.5 ring-2 ring-white dark:ring-gray-900 dark:bg-gray-700">
        </span>
        <h3 class="font-medium leading-tight">{{ dataService.formatEntityType(item?.type) }} {{ item?.name }}</h3>
        <p class="text-sm">{{ item.address || item?.data?.address }}</p>
      </li>
      }
    </ol>
  </div>
</div>