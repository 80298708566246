<div class="p-4 bg-gray-50">

  <dl class="mt-5 grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-6">
    <div class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div class="absolute rounded-md bg-blue-100 p-3">
          <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 384 512">
            <path
              d="M192 32c17.7 0 32 14.3 32 32V199.5l111.5-66.9c15.2-9.1 34.8-4.2 43.9 11s4.2 34.8-11 43.9L254.2 256l114.3 68.6c15.2 9.1 20.1 28.7 11 43.9s-28.7 20.1-43.9 11L224 312.5V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V312.5L48.5 379.4c-15.2 9.1-34.8 4.2-43.9-11s-4.2-34.8 11-43.9L129.8 256 15.5 187.4c-15.2-9.1-20.1-28.7-11-43.9s28.7-20.1 43.9-11L160 199.5V64c0-17.7 14.3-32 32-32z" />
          </svg>
        </div>
        <p class="ml-12 truncate text-sm font-medium text-gray-500">Nové</p>
      </dt>
      <dd class="ml-12 flex pb-6 sm:pb-7">
        <div class="text-2xl font-semibold text-gray-900 mr-2">{{
          dashboardStatistics?.allIncidentsCounts?.newIncidentsCount ?? '--' }}</div>
        <div class="flex items-center">
          <span class="text-gray-400 text-sm font-semibold">{{
            dashboardStatistics24?.allIncidentsCounts?.newIncidentsCount ?? '--' }}/24h</span>
        </div>
      </dd>
    </div>

    <div class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div class="absolute rounded-md bg-yellow-100 p-3">
          <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 512 512">
            <path
              d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
          </svg>
        </div>
        <p class="ml-12 truncate text-sm font-medium text-gray-500">Aktivné</p>
      </dt>
      <dd class="ml-12 flex pb-6 sm:pb-7">
        <div class="text-2xl font-semibold text-gray-900 mr-2">{{
          dashboardStatistics?.allIncidentsCounts?.inProgressIncidentsCount ?? '--' }}</div>
        <div class="flex items-center">
          <span class="text-gray-400 text-sm font-semibold">{{
            dashboardStatistics24?.allIncidentsCounts?.inProgressIncidentsCount ?? '--' }}/24h</span>
        </div>
      </dd>
    </div>

    <div class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div class="absolute rounded-md bg-green-100 p-3">
          <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 448 512">
            <path
              d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
          </svg>
        </div>
        <p class="ml-12 truncate text-sm font-medium text-gray-500">Vyriešené</p>
      </dt>
      <dd class="ml-12 flex pb-6 sm:pb-7">
        <div class="text-2xl font-semibold text-gray-900 mr-2">{{
          dashboardStatistics?.allIncidentsCounts?.solvedIncidentsCount ?? '--' }}</div>
        <div class="flex items-center">
          <span class="text-gray-400 text-sm font-semibold">{{
            dashboardStatistics24?.allIncidentsCounts?.solvedIncidentsCount ?? '--' }}/24h</span>
        </div>
      </dd>
    </div>

    <div class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div class="absolute rounded-md bg-purple-100 p-3">
          <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 384 512">
            <path
              d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9H112z" />
          </svg>
        </div>
        <p class="ml-12 truncate text-sm font-medium text-gray-500">Pozastavené</p>
      </dt>
      <dd class="ml-12 flex pb-6 sm:pb-7">
        <div class="text-2xl font-semibold text-gray-900 mr-2">{{
          dashboardStatistics?.allIncidentsCounts?.pendingIncidentsCount ?? '--' }}</div>
        <div class="flex items-center">
          <span class="text-gray-400 text-sm font-semibold">{{
            dashboardStatistics24?.allIncidentsCounts?.pendingIncidentsCount ?? '--' }}/24h</span>
        </div>
      </dd>
    </div>

    <div class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div class="absolute rounded-md bg-orange-100 p-3">
          <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 512 512">
            <path
              d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
          </svg>
        </div>
        <p class="ml-12 truncate text-sm font-medium text-gray-500">Bez riešenia</p>
      </dt>
      <dd class="ml-12 flex pb-6 sm:pb-7">
        <div class="text-2xl font-semibold text-gray-900 mr-2">{{
          dashboardStatistics?.allIncidentsCounts?.unresolvedIncidentsCount ?? '--' }}</div>
        <div class="flex items-center">
          <span class="text-gray-400 text-sm font-semibold">{{
            dashboardStatistics24?.allIncidentsCounts?.unresolvedIncidentsCount ?? '--' }}/24h</span>
        </div>
      </dd>
    </div>

    <div class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div class="absolute rounded-md bg-red-100 p-3">
          <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 448 512">
            <path
              d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
          </svg>
        </div>
        <p class="ml-12 truncate text-sm font-medium text-gray-500">Zrušené</p>
      </dt>
      <dd class="ml-12 flex pb-6 sm:pb-7">
        <div class="text-2xl font-semibold text-gray-900 mr-2">{{
          dashboardStatistics?.allIncidentsCounts?.terminatedIncidentsCount ?? '--' }}</div>
        <div class="flex items-center">
          <span class="text-gray-400 text-sm font-semibold">{{
            dashboardStatistics24?.allIncidentsCounts?.terminatedIncidentsCount ?? '--' }}/24h</span>
        </div>
      </dd>
    </div>
  </dl>

  <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-6">
    <div class="relative lg:col-span-4 overflow-hidden rounded-lg bg-white shadow">
      <table class="w-full rounded-lg text-sm text-left rtl:text-right">
        <thead class="text-xs uppercase border-b border-gray-200">
          <tr>
            <th scope="col" class="px-2 py-3 bg-white">
              Agent
            </th>
            <th scope="col" class="pr-2 py-3">
              <div class="rounded-md bg-blue-100 p-3">
                <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 384 512">
                  <path
                    d="M192 32c17.7 0 32 14.3 32 32V199.5l111.5-66.9c15.2-9.1 34.8-4.2 43.9 11s4.2 34.8-11 43.9L254.2 256l114.3 68.6c15.2 9.1 20.1 28.7 11 43.9s-28.7 20.1-43.9 11L224 312.5V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V312.5L48.5 379.4c-15.2 9.1-34.8 4.2-43.9-11s-4.2-34.8 11-43.9L129.8 256 15.5 187.4c-15.2-9.1-20.1-28.7-11-43.9s28.7-20.1 43.9-11L160 199.5V64c0-17.7 14.3-32 32-32z" />
                </svg>
              </div>
            </th>
            <th scope="col" class="pr-2 py-3">
              <div class="rounded-md bg-yellow-100 p-3">
                <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 512 512">
                  <path
                    d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                </svg>
              </div>
            </th>
            <th scope="col" class="pr-2 py-3">
              <div class="rounded-md bg-green-100 p-3">
                <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 448 512">
                  <path
                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
            </th>
            <th scope="col" class="pr-2 py-3">
              <div class="rounded-md bg-purple-100 p-3">
                <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 384 512">
                  <path
                    d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9H112z" />
                </svg>
              </div>
            </th>
            <th scope="col" class="pr-2 py-3">
              <div class="rounded-md bg-orange-100 p-3">
                <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 512 512">
                  <path
                    d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                </svg>
              </div>
            </th>
            <th scope="col" class="pr-2 py-3">
              <div class="rounded-md bg-red-100 p-3">
                <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 448 512">
                  <path
                    d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                </svg>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>

          @for (entry of dashboardStatistics?.agentsIncidentsCounts | keyvalue; track entry.key) {
          <tr class="even:bg-gray-50">
            <th scope="row" class="px-2 py-2 font-medium">
              {{entry.key}}
            </th>
            <td class="pl-4 py-2">{{entry.value.newIncidentsCount}}</td>
            <td class="pl-4 py-2">{{entry.value.inProgressIncidentsCount}}</td>
            <td class="pl-4 py-2">{{entry.value.solvedIncidentsCount}}</td>
            <td class="pl-4 py-2">{{entry.value.pendingIncidentsCount}}</td>
            <td class="pl-4 py-2">{{entry.value.unresolvedIncidentsCount}}</td>
            <td class="pl-4 py-2">{{entry.value.terminatedIncidentsCount}}</td>
          </tr>
          }

        </tbody>
      </table>
    </div>

    <div class="relative lg:col-span-2 overflow-hidden rounded-lg bg-white shadow">
      <table class="w-full rounded-lg text-sm text-left rtl:text-right">
        <thead class="text-xs uppercase border-b border-gray-200">
          <tr>
            <th scope="col" class="px-2 py-3 bg-white">
              Typ poruchy
            </th>
            <th scope="col" class="pr-2 py-3">
              Počet
            </th>
          </tr>
        </thead>
        <tbody>
          @for (entry of dashboardCategoryCount; track entry.category) {
          <tr class="even:bg-gray-50">
            <th scope="row" class="px-2 py-2 font-medium">
              {{ nameMapping[entry.category]}}
            </th>
            <td class="pl-4 py-2">{{entry.count}}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </dl>

  <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
    <div class="relative overflow-hidden rounded-lg bg-white shadow">
      <div class="w-full p-4 md:p-6 grid grid-cols-1 sm:grid-cols-2">
        <div class="flex-1">
          <div class="flex justify-between mb-3">
            <div class="flex items-center">
              <div class="flex justify-center items-center">
                <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white pe-1">Čas odozvy na poruchu</h5>
              </div>
            </div>
          </div>

          <div class="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <div id="more-details" class="space-y-2">
              <dl class="flex items-center justify-between">
                <dt class="text-gray-500 dark:text-gray-400 text-sm font-normal">Priemer:</dt>
                <dd
                  class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">
                  {{ null | formatDhmTime:
                  dashboardStatistics?.timeDifferences?.averageIncidentResponseTime?.years ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResponseTime?.months ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResponseTime?.days ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResponseTime?.hours ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResponseTime?.minutes ?? 0
                  }}&nbsp;&nbsp;&nbsp;
                  <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 10 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 13V1m0 0L1 5m4-4 4 4" />
                  </svg> xx%
                </dd>
              </dl>
              <dl class="flex items-center justify-between">
                <dt class="text-gray-500 dark:text-gray-400 text-sm font-normal">Min.:</dt>
                <dd
                  class="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-gray-600 dark:text-gray-300">
                  {{ 0 | formatDhmTime:
                  dashboardStatistics?.timeDifferences?.minIncidentResponseTime?.years ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResponseTime?.months ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResponseTime?.days ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResponseTime?.hours ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResponseTime?.minutes ?? 0
                  }}
                </dd>
              </dl>
              <dl class="flex items-center justify-between">
                <dt class="text-gray-500 dark:text-gray-400 text-sm font-normal">Max.:</dt>
                <dd
                  class="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-gray-600 dark:text-gray-300">
                  {{ 0 | formatDhmTime:
                  dashboardStatistics?.timeDifferences?.maxIncidentResponseTime?.years ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResponseTime?.months ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResponseTime?.days ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResponseTime?.hours ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResponseTime?.minutes ?? 0
                  }}
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <!-- Radial Chart -->
        <div #chartResponseTime id="chartResponseTime" class="flex-1"></div>

      </div>
    </div>

    <div class="relative overflow-hidden rounded-lg bg-white shadow">
      <div class="w-full p-4 md:p-6 grid grid-cols-1 sm:grid-cols-2">
        <div class="flex-1">
          <div class="flex justify-between mb-3">
            <div class="flex items-center">
              <div class="flex justify-center items-center">
                <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white pe-1">Čas riešenia poruchy</h5>
              </div>
            </div>
          </div>

          <div class="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <div id="more-details" class="space-y-2">
              <dl class="flex items-center justify-between">
                <dt class="text-gray-500 dark:text-gray-400 text-sm font-normal">Priemer:</dt>
                <dd
                  class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">
                  {{ 0 | formatDhmTime:
                  dashboardStatistics?.timeDifferences?.averageIncidentResolveTime?.years ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResolveTime?.months ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResolveTime?.days ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResolveTime?.hours ?? 0 :
                  dashboardStatistics?.timeDifferences?.averageIncidentResolveTime?.minutes ?? 0
                  }}&nbsp;&nbsp;&nbsp;
                  <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 10 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 13V1m0 0L1 5m4-4 4 4" />
                  </svg> xx%
                </dd>
              </dl>
              <dl class="flex items-center justify-between">
                <dt class="text-gray-500 dark:text-gray-400 text-sm font-normal">Min.:</dt>
                <dd
                  class="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-gray-600 dark:text-gray-300">
                  {{ 0 | formatDhmTime:
                  dashboardStatistics?.timeDifferences?.minIncidentResolveTime?.years ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResolveTime?.months ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResolveTime?.days ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResolveTime?.hours ?? 0 :
                  dashboardStatistics?.timeDifferences?.minIncidentResolveTime?.minutes ?? 0
                  }}
                </dd>
              </dl>
              <dl class="flex items-center justify-between">
                <dt class="text-gray-500 dark:text-gray-400 text-sm font-normal">Max.:</dt>
                <dd
                  class="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-gray-600 dark:text-gray-300">
                  {{ 0 | formatDhmTime:
                  dashboardStatistics?.timeDifferences?.maxIncidentResolveTime?.years ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResolveTime?.months ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResolveTime?.days ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResolveTime?.hours ?? 0 :
                  dashboardStatistics?.timeDifferences?.maxIncidentResolveTime?.minutes ?? 0
                  }}
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <!-- Radial Chart -->
        <div #chartResolveTime id="chartResolveTime" class="flex-1"></div>

      </div>
    </div>
  </dl>
</div>
