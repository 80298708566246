import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PushNotificationService } from './push-notification.service';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { StateManagementService } from './state-management.service';

const REFRESH_MAX_DELAY = 1000;
const SIGNOUT_MAX_DELAY = 1000;

export interface DummyUser {
  id: number;
  name: string;
  email: string;
  password: string;
  privileges: any;
  token: string;
  tokenExp: Date | null;
}

// interface for JWT token
// Include other standard JWT fields as needed
interface JWT {
  exp: number;
}

// Add this interface above the AuthService class
interface JWTWithRoles extends JWT {
  resource_access?: {
    org?: {
      roles?: string[];
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userDetails: DummyUser | null = null;
  private userDetailSubject = new BehaviorSubject<DummyUser | null>(null);

  private controllerRefresh = new AbortController();
  private controllerLogout = new AbortController();

  constructor(
    private pushService: PushNotificationService,
    private router: Router,
    private stateManagementService: StateManagementService
  ) { }

  getUserDetails(): Observable<DummyUser | null> {
    return this.userDetailSubject.asObservable();
  }
  updateUserDetails(userDetails: DummyUser | null) {
    this.userDetailSubject.next(userDetails);
    this.userDetails = userDetails;
  }

  /**
   * Logs in a user with the provided username and password.
   * @param username - The username of the user.
   * @param password - The password of the user.
   * @param remember - Optional. Indicates whether to remember the user's login session. Default is false.
   * @returns A Promise that resolves to a boolean indicating whether the login was successful.
   */
  async login(username: string, password: string, remember: boolean = false): Promise<boolean> {
    try {
      // call the auth endpoint
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);
      formData.append('remember', remember ? 'true' : 'false');

      const response = await fetch(environment.URL_AUTH_SIGNIN, {
        method: 'POST',
        mode: 'cors',
        //mode: 'no-cors',
        //cache: 'no-cache',
        // FIXME: for production use same-origin
        credentials: 'same-origin',
        // credentials: 'include',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString()
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseJSON = await response.json();

      // Decode the token to extract the expiration date
      const decodedToken: JWTWithRoles = jwtDecode<JWT>(responseJSON.token);
      const tokenExp = new Date(decodedToken.exp * 1000);

      // Extract org roles from the token
      const orgRoles = decodedToken.resource_access?.org?.roles || [];

      this.updateUserDetails({
        id: responseJSON.id,
        name: responseJSON.userName,
        email: responseJSON.email,
        password: '',
        privileges: orgRoles,
        token: responseJSON.token,
        tokenExp: tokenExp
      });

      localStorage.removeItem('soft-logout');
      this.pushService.subscribeToNotifications(responseJSON.token);

      // FIXME: circular dependency
      //this.pinService.resetLock();
      localStorage.removeItem('isLocked');
      this.stateManagementService.setIsLocked(false);


      //this.autoTokenRefresh();

      // user not found. we can do some ui stuff
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  }

  /**
   * Logs in the user using a refresh token.
   * @param useDelay - Whether to introduce an artificial delay for better UI experience. Default is false.
   * @returns A promise that resolves to a boolean indicating whether the login was successful.
   */
  async renewWithRefreshToken(useDelay: boolean = false): Promise<boolean> {
    try {
      const softLogout = localStorage.getItem('soft-logout');
      if (softLogout) {
        return false;
      }

      // Abort any ongoing fetch request
      if (this.controllerRefresh) {
        this.controllerRefresh.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerRefresh = new AbortController();

      // request start time used for artificial delay for better ui experience
      const startTime = Date.now();

      // call the auth endpoint for refreshing the token
      const response = await fetch(environment.URL_AUTH_REFRESH, {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        signal: this.controllerRefresh.signal
      });

      // artificial delay for better ui experience
      if (useDelay) {
        const endTime = Date.now();
        const fetchDuration = endTime - startTime;
        if (fetchDuration < REFRESH_MAX_DELAY) {
          // maximum delay (including fetch duration) is 1000ms
          const delay = REFRESH_MAX_DELAY - fetchDuration;
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }

      // the signup process was not succesful
      if (!response.ok) {
        // error after response - so it is not abort type error
        // isAborted.refresh = false;
        // TODO: handle other types of status codes - 400, 500, 503, ...
        // invalid credentials - status code 404
        if (response.status === 404) {
          let responseJson = await response.json();
          throw new Error(responseJson.message.text);
          // general error
        } else {
          throw new Error('Incorrect refresh');
        }
      }

      // set currentUser values
      const responseJSON = await response.json();
      // Decode the token to extract the expiration date
      const decodedToken: JWT = jwtDecode<JWT>(responseJSON.token);
      const tokenExp = new Date(decodedToken.exp * 1000);
      // FIXME: apiKey is missing in the response
      this.updateUserDetails({
        id: responseJSON.id,
        name: responseJSON.userName,
        email: responseJSON.email,
        password: '',
        privileges: '',
        token: responseJSON.token,
        tokenExp: tokenExp
      });

      localStorage.removeItem('soft-logout');

      // this.autoTokenRefresh();
      // TODO: do wee need this reset values?
      // abortControllers.login = null;
      return true;
    } catch (err) {
      console.error(err);
      this.updateUserDetails({
        id: 0,
        name: '',
        email: '',
        password: '',
        privileges: '',
        token: '',
        tokenExp: null
      });

      // errors.refresh = err.message;
      return false;
    }
  }

  /**
   * Changes the user's password.
   *
   * @param oldPassword - The user's current password.
   * @param newPassword - The new password the user wants to set.
   * @param confirmPassword - Confirmation of the new password (not used in the current implementation).
   * @returns A Promise that resolves to a boolean indicating whether the password change was successful.
   *
   * @remarks
   * This method performs the following steps:
   * 1. Checks if the user is logged in.
   * 2. Verifies that the user's email is available.
   * 3. Sends a PATCH request to the password change endpoint.
   * 4. Handles the response and any potential errors.
   *
   * @throws Will throw an error if the network response is not ok.
   */
  async changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Promise<boolean> {
    try {
      // check if the user is logged in. not necessary required
      if (!this.isLoggedIn()) {
        return false;
      }

      if (!this.userDetails?.email) {
        return false;
      }

      // call the auth endpoint
      const formData = new URLSearchParams();
      formData.append('username', this.userDetails?.email);
      formData.append('password', oldPassword);
      formData.append('passwordNew', newPassword);

      // call the auth endpoint for changing the password
      const response = await fetch(environment.URL_AUTH_CHANGE_PASSWORD, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString()
      });

      // check if the response is ok
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // password changed successfully
      return true;

    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false;
    }
  }

  isLoggedIn(): boolean {
    return this.userDetails != null;
  }

  // TODO: do we want automatic redirect to signin page?
  async logout(): Promise<boolean> {
    try {
      // Abort any ongoing fetch request
      if (this.controllerLogout) {
        this.controllerLogout.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerLogout = new AbortController();
      localStorage.setItem('soft-logout', 'true');

      // FIXME: circular dependency
      //this.pinService.resetLock();
      localStorage.removeItem('isLocked');
      this.stateManagementService.setIsLocked(false);


      // request start time used for artificial delay for better ui experience
      const startTime = Date.now();

      // use logout enpoint to logout user
      let response = await fetch(environment.URL_AUTH_SIGNOUT, {
        method: 'POST',
        mode: 'cors',
        // FIXME: for production use same-origin
        //credentials: 'same-origin',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({}),
        signal: this.controllerLogout.signal
      });

      // artificial delay for better ui experience
      const endTime = Date.now();
      const fetchDuration = endTime - startTime;
      if (fetchDuration < SIGNOUT_MAX_DELAY) {
        // maximum delay (including fetch duration) is 1000ms
        const delay = SIGNOUT_MAX_DELAY - fetchDuration;
        await new Promise((resolve) => setTimeout(resolve, delay));
      }

      // the logout process was not succesful
      if (!response.ok) {
        // error after response - so it is not abort type error
        // isAborted.logout = false;
        // TODO: handle all status types
        // TODO: should we use 404 instead
        // 401 response status is NON throw error. Session token doesnt exist server side, but we remove session token cookie anyways

        // reset currentUser
        // resetCurrentUser();
        this.updateUserDetails({
          id: 0,
          name: '',
          email: '',
          password: '',
          privileges: '',
          token: '',
          tokenExp: null
        });

        if (response.status === 401) {
          localStorage.removeItem('soft-logout');
          return false;
        } else if (response.status === 404) {
          let data = await response.json();
          localStorage.removeItem('soft-logout');
          throw new Error(data.message.text);
          // general error
        } else {
          throw new Error('Could not complete logout');
        }
      }

      // // reset error value again
      // errors.logout = null;
      // // set pending value
      // isPending.logout = false;
      // isAborted.logout = false;
      // // set soft logout value
      // softLogout.value = false;
      localStorage.removeItem('soft-logout');
      // TODO: do wee need this reset values?
      // abortControllers.logout = null;

      // reset currentUser
      // resetCurrentUser();
      this.updateUserDetails({
        id: 0,
        name: '',
        email: '',
        password: '',
        privileges: '',
        token: '',
        tokenExp: null
      });
      return true;
    } catch (err) {
      console.error(err);

      // errors.logout = err.message;
      return true;
    } finally {
      this.router.navigate(['/signin']); // Navigate to home or dashboard page
    }
  }

  /**s
   * Calculates the SHA-512 hash of a given string.
   *
   * @param str - The string to be hashed.
   * @returns A promise that resolves to the hexadecimal representation of the hash.
   */
  async sha512(str: string): Promise<string> {
    // Encode the string as a Uint8Array
    const buffer = new TextEncoder().encode(str);

    // Use the Web Crypto API to hash the data
    const hashBuffer = await crypto.subtle.digest('SHA-512', buffer);

    // Convert the ArrayBuffer to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }


  /**
   * Refreshes the authentication token if it is about to expire.
   * If the token is not present or not about to expire, the method returns early.
   * If the token is about to expire, it calls the `renewWithRefreshToken` method to refresh the token.
   *
   * @returns A Promise that resolves to void.
   */
  async refreshToken(): Promise<void>{
    // we dont have a token to refresh
    if (!this.userDetails?.tokenExp) {
      return;
    }

    const currentTime = Date.now();
    const tokenExpireTime = this.userDetails?.tokenExp?.getTime() || 0;
    const refreshBuffer = 30 * 1000;  // Refresh 30 seconds before token expires

    const refreshTime = tokenExpireTime - currentTime - refreshBuffer;

    if (refreshTime <= 0) {
      await this.renewWithRefreshToken(false);
    }

    return;
  }

  async changePIN(oldPIN: string, newPIN: string, confirmPIN: string): Promise<boolean> {
    try {
      // check if the user is logged in. not necessary required
      if (!this.isLoggedIn()) {
        return false;
      }

      if (!this.userDetails?.email) {
        return false;
      }

      // call the auth endpoint
      const formData = new URLSearchParams();
      formData.append('username', this.userDetails?.email);
      formData.append('pin', oldPIN);
      formData.append('pinNew', newPIN);

      // call the auth endpoint for changing the password
      const response = await fetch(environment.URL_AUTH_CHANGE_PIN, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: formData.toString()
      });

      // check if the response is ok
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // password changed successfully
      return true;

    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false;
    }
  }

  async verifyPIN(pin: string): Promise<boolean> {
    try {
      // check if the user is logged in. not necessary required
      if (!this.isLoggedIn()) {
        return false;
      }

      // call the auth endpoint
      const formData = new URLSearchParams();
      formData.append('pin', pin);

      // call the auth endpoint for changing the password
      const response = await fetch(environment.URL_AUTH_VERIFY_PIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: formData.toString()
      });

      // check if the response is ok
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // password changed successfully
      return true;

    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false;
    }
  }


}
