<div>

  <div class="mb-8 mt-8 relative bg-gray-100 rounded-lg">
    <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase dark:text-gray-400 border-b border-gray-200 dark:border-gray-700">
          <tr>
            <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
              Dátum / Parameter
            </th>
            <th scope="col" class="px-6 py-3">
              Nová hodnota / Pôvodná hodnota
            </th>
          </tr>
        </thead>
        <tbody>
          @if (history.length > 0) {
          @for (item of history; track $index) {
          <tr class="border-b border-gray-200 dark:border-gray-700">
            <th scope="row"
              class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
              {{ item.timestamp | date: 'dd.MM.yyyy HH:mm:ss' }} <br /> {{ item.parameterTranslated }}
            </th>
            <td class="px-2 py-2">
              <div class="mb-1">{{ item.newValue }}</div>
              <div class="mb-1">{{ item.oldValue }}</div>
            </td>
          </tr>
          }
          } @else {
          <tr>
            <td colspan="2" class="px-6 py-3 text-center text-gray-500 dark:text-gray-400">
              @if (historyLoaded) {
              Zariadenie nemá žiadnu históriu zmien
              }
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>

    <div class="p-2 rounded-lg flex flex-wrap">
      <div class="flex-1">&nbsp;</div>
        <button type="button" (click)="showEntityHistory()" class="flex-none
        focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:focus:ring-gray-800 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white
        disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
        <svg class="w-3.5 h-3.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9L0 168c0 13.3 10.7 24 24 24l110.1 0c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24l0 104c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65 0-94.1c0-13.3-10.7-24-24-24z" />
        </svg>
        @if (historyLoaded) {
        Aktualizovať históriu zmien
        } @else {
        Zobraziť históriu zmien
        }
      </button>
    </div>

  </div>
</div>