/**
 * Component for displaying and managing device history changes.
 * This component tracks and displays changes made to device parameters over time,
 * comparing both current state with historical records and consecutive historical records
 * with each other.
 *
 * TODO: this should be refactored to use the new PasportEntityHistory interface.
 * TODO: make visual improvements to show loading state
 */
import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiResponseGetPasportEntityHistory } from '@app/shared/interfaces/pasport-entity-history.interface';
import { FormatSkDatePipe } from '@app/shared/format-sk-date.pipe';
import { StateManagementService } from '@app/services/state-management.service';

@Component({
  selector: 'app-pasport-device-history',
  standalone: true,
  imports: [CommonModule, FormatSkDatePipe],
  templateUrl: './pasport-device-history.component.html',
  styleUrl: './pasport-device-history.component.css'
})
export class PasportDeviceHistoryComponent {
  /** Identifier of the object (objectId) */
  @Input() uuid: string | undefined = '';
  /** Data service */
  @Input() dataService: any;

  /** Flag indicating if history data has been loaded */
  historyLoaded: boolean = false;

  /** Array storing the history of changes with timestamps and values */
  history:{
    timestamp:string,
    parameter:string,
    parameterTranslated:string,
    newValue:string,
    oldValue:string
  }[] = [];

  constructor(
    private stateManagementService: StateManagementService
  ) { }

  /**
   * Lifecycle hook that handles changes to component inputs.
   * Clears history data when UUID changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['uuid'] && !changes['uuid'].firstChange) {
      // Clear previous data
      this.historyLoaded = false;
      this.history = [];
    }
  }

  /**
   * Fetches and processes device history data.
   * Compares current state with historical records and consecutive historical records
   * to build a comprehensive change history.
   */
  async showEntityHistory() {
    // cleanup history
    this.history = [];

    const entityResponse = await this.dataService.fetchApiGetPasportEntityDetails(this.uuid);
    const historyResponse: ApiResponseGetPasportEntityHistory = await this.dataService.fetchApiGetPasportEntityHistory(this.uuid);
    const historyContent = historyResponse.entityHistory.content;

    // Compare current entity with the most recent history record
    if (historyContent.length > 0) {
      const changes = this.findChanges(
        entityResponse.entity.data,
        historyContent[0].data
      );
      for (const [parameter, values] of Object.entries(changes)) {
        this.history.push({
          timestamp: new Date().toISOString(),
          parameter,
          parameterTranslated: this.stateManagementService.getPasportTemplateDictionary()[parameter] || parameter,
          newValue: values.newValue,
          oldValue: values.oldValue
        });
      }
    }

    // Compare consecutive history records
    for (let i = 0; i < historyContent.length - 1; i++) {
      const changes = this.findChanges(
        historyContent[i].data,
        historyContent[i + 1].data
      );
      for (const [parameter, values] of Object.entries(changes)) {
        this.history.push({
          timestamp: historyContent[i].timestamp,
          parameter,
          parameterTranslated: this.stateManagementService.getPasportTemplateDictionary()[parameter] || parameter,
          newValue: values.newValue,
          oldValue: values.oldValue
        });
      }
    }

    this.historyLoaded = true;
  }

  /**
   * Compares two data objects and identifies changes between them.
   * @param newer The newer/current data object
   * @param older The older/historical data object
   * @returns Record of changes with new and old values for each changed parameter
   */
  private findChanges(newer: { [key: string]: string }, older: { [key: string]: string }):
    Record<string, { newValue: string, oldValue: string }> {
    const changes: Record<string, { newValue: string, oldValue: string }> = {};

    // Get all keys from both data objects
    const keys = new Set([...Object.keys(newer), ...Object.keys(older)]);

    for (const key of keys) {
      const newValue = newer[key] ?? '';
      const oldValue = older[key] ?? '';

      // Skip if both values are empty/null/undefined
      if (newValue === '' && oldValue === '') continue;

      // Only record if values are different
      if (newValue !== oldValue) {
        changes[key] = { newValue, oldValue };
      }
    }

    return changes;
  }
}
