import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pasport-device-relations',
  standalone: true,
  imports: [],
  templateUrl: './pasport-device-relations.component.html',
  styleUrl: './pasport-device-relations.component.css'
})
export class PasportDeviceRelationsComponent {
  @Input() relations: any[] = [];
  @Input() dataService: any;

  deletingRelationId: number | null = null;

  async deleteRelation(relationId: number) {
    const result = await this.dataService.fetchApiDeletePasportRelation(relationId);
    if (result) {
      const index = this.relations.findIndex(relation => relation.id === relationId);
      if (index !== -1) {
        this.relations.splice(index, 1);
      }
    }
  }

  toggleDeleteConfirmation(relationId: string) {
    this.deletingRelationId = this.deletingRelationId === Number(relationId) ? null : Number(relationId);
  }

  cancelDelete() {
    this.deletingRelationId = null;
  }

  confirmDelete(relationId: number) {
    // Implement the actual deletion logic here
    this.deleteRelation(relationId);
    this.deletingRelationId = null;
  }
}
