import { Routes } from '@angular/router';
import { AuthGuard } from '@app/guards/auth.guard';
import { IncidentsComponent } from './features/incidents/incidents.component';
import { SettingsComponent } from './settings/settings.component';
import { SigninComponent } from './signin/signin.component';

export const routes: Routes = [
  //{ path: '', component: HomeComponent },
  { path: '', component: IncidentsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: 'signin', component: SigninComponent, canActivate: [AuthGuard] },
  { path: 'incidents', component: IncidentsComponent, canActivate: [AuthGuard]},
  { path: 'settings', component: SettingsComponent }
];
