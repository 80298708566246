<!-- drawer component -->
<div id="drawer-bottom-qr-code-scanner"
  class="
  fixed top-0 right-0 z-100 lg:z-60 h-screen overflow-hidden transition-transform translate-x-full bg-white w-full lg:w-96 dark:bg-gray-800
  duration-500"
  tabindex="-1" aria-labelledby="drawer-bottom-qr-code-scanner-label" data-drawer-backdrop="false">

  <div class="z-70 absolute top-0 w-full px-3 pt-4 flex flex-col h-screen-s">
    <div class="flex items-center mb-6">
      <div class="flex-none">
      </div>
      <div class="flex-1 flex items-center justify-center">
        <div class="flex text-white items-center justify-center mx-3 font-bold">
          <span
          class="whitespace-nowrap inline-flex items-center bg-gray-200 text-gray-800 text-base font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
          QR SKENOVANIE</span>
        </div>
      </div>
      <div class="flex-none">
        <!-- close button -->
        <button type="button" id="button-close-drawer-bottom-qr-code-scanner" data-drawer-hide="drawer-bottom-qr-code-scanner" aria-controls="drawer-bottom-qr-code-scanner"
          class="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
          &nbsp;
          <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          &nbsp;
          <span class="sr-only">Close menu</span>
        </button>
      </div>
    </div>
    <div class="text-white flex flex-grow h-auto overflow-auto justify-center">
      <svg class="w-2/3 h-auto opacity-50" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 32 32">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <title>expand</title>
          <path
            d="M12 29.25h-9.25v-9.25c0-0.414-0.336-0.75-0.75-0.75s-0.75 0.336-0.75 0.75v0 10c0 0.414 0.336 0.75 0.75 0.75h10c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM30 19.25c-0.414 0-0.75 0.336-0.75 0.75v0 9.25h-9.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h10c0.414-0 0.75-0.336 0.75-0.75v0-10c-0-0.414-0.336-0.75-0.75-0.75v0zM12 1.25h-10c-0.414 0-0.75 0.336-0.75 0.75v0 10c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-9.25h9.25c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM30 1.25h-10c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h9.25v9.25c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-10c-0-0.414-0.336-0.75-0.75-0.75v0z">
          </path>
        </g>
      </svg>
    </div>
    <div class="flex flex-row justify-center mb-1">
      <div>
        <span
          [class.bg-green-200]="qrCodeVerified === QrCodeVerificationStatus.Match"
          [class.dark:bg-green-700]="qrCodeVerified === QrCodeVerificationStatus.Match"
          [class.bg-red-200]="qrCodeVerified === QrCodeVerificationStatus.NoMatch"
          [class.dark:bg-red-700]="qrCodeVerified === QrCodeVerificationStatus.NoMatch"
          [class.bg-gray-200]="qrCodeVerified === QrCodeVerificationStatus.NotTested"
          [class.dark:bg-gray-700]="qrCodeVerified === QrCodeVerificationStatus.NotTested"
          class="whitespace-nowrap inline-flex items-center mb-2 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:text-gray-300">
          <b>Zariadenie:&nbsp;</b>
          {{ qrCodeObjectName }}</span>
        <!-- <input [(ngModel)]="qrCodeDevice" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" /> -->
      </div>
      <div>
        <span
          [class.bg-green-200]="qrCodeVerified === QrCodeVerificationStatus.Match"
          [class.dark:bg-green-700]="qrCodeVerified === QrCodeVerificationStatus.Match"
          [class.bg-red-200]="qrCodeVerified === QrCodeVerificationStatus.NoMatch"
          [class.dark:bg-red-700]="qrCodeVerified === QrCodeVerificationStatus.NoMatch"
          [class.bg-gray-200]="qrCodeVerified === QrCodeVerificationStatus.NotTested"
          [class.dark:bg-gray-700]="qrCodeVerified === QrCodeVerificationStatus.NotTested"
          class="whitespace-nowrap inline-flex items-center mb-2 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:text-gray-300">
          <b>Skenovanie:&nbsp;</b>
          {{ qrCode }}</span>
        <!-- <input [(ngModel)]="qrCode" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" /> -->
      </div>
    </div>
    <div class="flex flex-row justify-center mb-4">
      <button type="button" (click)="newScan()"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        Nové skenovanie</button>
      @if (qrCodeScanned) {
      <button type="button" (click)="showPasportDetail()"
        class="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        Detail</button>
      }
    </div>
    <div>

    </div>
  </div>

  <!-- input pre qr kod -->
  <!-- <div class="z-70 absolute bottom-10 px-10">
    <div class="max-w-sm mx-auto">
      <div class="mb-5">
        <input [(ngModel)]="qrCode" id="test-qr-code" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
      </div>
    </div>
  </div> -->
  <!-- video overlay pre qr kod -->
  <div>
    <video id="video" class="z-60 absolute w-full h-screen-s object-cover top-0 left-0" autoplay></video>
  </div>

</div>