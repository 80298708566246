@if (!newEntity) {
<!-- Control parent for the device -->
<div class="p-2 rounded-lg mb-2 bg-gray-50 dark:bg-gray-800">
  <div class="">
    <select [(ngModel)]="selectedTemplateId" (ngModelChange)="onTemplateChange($event)"
      class="disabled:cursor-not-allowed text-sm rounded-lg border  block w-full p-2.5
          bg-white dark:bg-gray-700 text-gray-900 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
      <option [ngValue]="null" disabled>Zvoľte typ zariadenia</option>
      @for (item of templates; track item.id; let i = $index) {
      <option [ngValue]="item.id">{{ dataService.formatEntityType(item.name) }}</option>
      }
    </select>
  </div>
</div>

<!-- TODO: make this a separate component -->
<div class="mb-8 bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
  <table class="w-full text-sm text-left rtl:text-right">
    <thead class="text-xs uppercase bg-gray-50 border-b border-gray-200 ">
      <tr>
        <th scope="col" class="px-2 py-3">
          Parametre
        </th>
        <th scope="col" class="px-2 py-3">
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody>
      @for (item of objectEntries(selectedTemplate?.data?.params); track item[0]) {
      <tr class="bg-gray-50 border-b border-gray-200">
        <th scope="row" class="px-2 py-2 font-medium">
          {{ item[1].translations[0].translation }}
        </th>
        <td class="px-2 py-2">
          <ng-container [ngSwitch]="item[1].valueType">
            <ng-container *ngSwitchCase="'text'">
              <input type="text" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
              bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <input type="number" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
              bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchCase="'Boolean'">
              <input type="checkbox" [(ngModel)]="paramValues[item[0]]"
                class="rounded bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchCase="'timestamp'">
              <input type="datetime-local" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
                bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input type="text" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
              bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
          </ng-container>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>

<div class="flex justify-center mt-8 mb-4">
  <button [disabled]="(!isValid)" type="button" (click)="createDevice()" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
    disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed">
    Vytvoriť zariadenie - Krok 1/2</button>
</div>
}

@if (newEntity) {
<div class="p-2 rounded-lg mb-2 bg-gray-50 dark:bg-gray-800 flex flex-col">
  <div><b>Typ zariadenia:</b> {{ dataService.formatEntityType(newEntity.entityType.type) }}</div>
  <div><b>Názov:</b> {{ newEntity.data['name'] }}</div>
  <div><b>UUID:</b> {{ newEntity.uuid }}</div>
</div>

<app-pasport-device-relations
  [relations]="relations"
  [dataService]="dataService">
</app-pasport-device-relations>

<app-pasport-device-new-relation
  [pasportEntityUuid]="newEntity.uuid"
  (onNewRelation)="handleNewRelation()">
</app-pasport-device-new-relation>
}

<!-- Bottom of the page to add some space for bottom navigation -->
<div>
  <br /><br /><br /><br /><br /><br /><br />
</div>