import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSkDate',
  standalone: true
})
export class FormatSkDatePipe implements PipeTransform {

  /**
   * Transforms a date string into a formatted Slovak date representation.
   *
   * @param value - The input date string to be formatted
   * @returns A formatted string representing the date in Slovak format
   *
   * @description
   * This method takes a date string as input and formats it into a Slovak date representation.
   * The output format is "dd.mm.yyyy hh:mm" where:
   * - dd: day of the month (two digits, zero-padded)
   * - mm: month (two digits, zero-padded)
   * - yyyy: full year (four digits)
   * - hh: hours in 24-hour format (two digits, zero-padded)
   * - mm: minutes (two digits, zero-padded)
   * If the input is empty or invalid, an empty string is returned.
   *
   * @example
   * // returns "01.05.2023 14:30"
   * transform("2023-05-01T14:30:00")
   *
   * @example
   * // returns "31.12.2023 23:59"
   * transform("2023-12-31T23:59:59")
   *
   * @example
   * // returns ""
   * transform("")
   */
  transform(value: string): string {
    if (!value) return '';

    const date = new Date(value);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
}
