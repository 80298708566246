<div class="p-4 bg-gray-50">
  <div class="relative overflow-hidden rounded-lg bg-white shadow p-4
    flex flex-col lg:flex-row items-center space-y-3 md:space-y-0 md:space-x-4">

    <div class="w-full lg:w-auto flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-4">
      <select id="selectedYear" [(ngModel)]="selectedYear"
        class="w-full lg:w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option [ngValue]="null" disabled>Zvoľte rok</option>
        @for (year of years; track year; let i = $index) {
          <option [ngValue]="year">{{ year }}</option>
        }
      </select>

      <select id="selectedMonth" [(ngModel)]="selectedMonth"
        class="w-full lg:w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option [ngValue]="'all'">Všetky mesiace</option>
        <option [ngValue]="1">Január</option>
        <option [ngValue]="2">Február</option>
        <option [ngValue]="3">Marec</option>
        <option [ngValue]="4">Apríl</option>
        <option [ngValue]="5">Máj</option>
        <option [ngValue]="6">Jún</option>
        <option [ngValue]="7">Júl</option>
        <option [ngValue]="8">August</option>
        <option [ngValue]="9">September</option>
        <option [ngValue]="10">Október</option>
        <option [ngValue]="11">November</option>
        <option [ngValue]="12">December</option>
      </select>

      <button type="button" (click)="search()"
        class="w-full lg:w-auto flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"
          class="w-3.5 h-3.5">
          <path
            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
        </svg>
        &nbsp;&nbsp;Zobraziť
      </button>

      <button type="button" (click)="export()"
        class="w-full lg:w-auto flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 384 512">
        <path
          d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM216 232l0 102.1 31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31L168 232c0-13.3 10.7-24 24-24s24 10.7 24 24z" />
      </svg>&nbsp;&nbsp;Exportovať
      </button>
    </div>

    <div class="w-full items-center justify-center mt-3 md:mt-0">
      <h5 class="mr-3 font-semibold dark:text-white text-center">Podrobný prehľad za obdobie {{ selectedYearMonthDesc }}</h5>
      <p class="text-gray-500 dark:text-gray-400 text-center">Počet zobrazených záznamov: {{ dashboardIncidents.length }} </p>
    </div>

  </div>

  <div class="relative overflow-hidden rounded-lg bg-white shadow mt-2">
    <table class="w-full rounded-lg text-sm text-left rtl:text-right">
      <thead class="text-xs uppercase border-b border-gray-200">
        <tr>
          <th scope="col" class="px-2 py-3 bg-white">
            #
          </th>
          <th scope="col" class="pr-2 py-3">
            Rok-mesiac
          </th>
          <th scope="col" class="pr-2 py-3">
            Kód
          </th>
          <th scope="col" class="pr-2 py-3">
            Vytvorené
          </th>
          <th scope="col" class="pr-2 py-3">
            Typ
          </th>
          <th scope="col" class="pr-2 py-3">
            Stav
          </th>
          <th scope="col" class="pr-2 py-3">
            Hlásení
          </th>
          <th scope="col" class="pr-2 py-3">
            Agent
          </th>
        </tr>
      </thead>
      <tbody>
        @for (entry of dashboardIncidents; track entry.id; let i = $index) {
          <tr class="even:bg-gray-50">
            <th scope="row" class="px-2 py-2 font-medium">
              {{ i + 1 }}
            </th>
            <td class="pl-4 py-2">{{ entry.evidenceDate.substring(0, 7) }}</td>
            <td class="pl-4 py-2">{{ entry.code }}</td>
            <td class="pl-4 py-2">{{ entry.evidenceDate | formatSkDate }}</td>
            <td class="pl-4 py-2">{{ nameMapping[entry.name] }}</td>
            <td class="pl-4 py-2">{{ dataService.getIncidentStateLabel(entry.state) }}</td>
            <td class="pl-4 py-2">{{ entry.tasks.length }}</td>
            <td class="pl-4 py-2">{{ entry.agent }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>