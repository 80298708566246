import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { NotificationService } from '@services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  /**
   * The public key used for VAPID authentication.
   */

  constructor(
    private swPush: SwPush,
    private notificationService: NotificationService
  ) {
    this.listenForMessages();
  }

  /**
   * Subscribes to push notifications.
   * Requests subscription from the service worker and sends the subscription object to the server.
   * @returns {void}
   */
  async subscribeToNotifications(accessToken: string) {
    let vapidPublicKey: string = '';
    try {
      const vapidResult = await fetch(environment.URL_VAPID_KEY, { headers: { 'Authorization': `Bearer ${accessToken}` } });
      vapidPublicKey = await vapidResult.text();
    } catch (error) {
      console.error('Could not fetch VAPID key', error);
    }

    if (!vapidPublicKey) {
      console.error('Could not fetch VAPID key');
      return;
    }

    this.swPush.requestSubscription({
      serverPublicKey: vapidPublicKey
    })
      .then(subscription => {
        // Send this subscription object to your server
        // as a payload use subscription details from push service
        fetch(environment.URL_SUBSCRIBE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(subscription)
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Bad status code from server.');
            }
            return response.json();
          })
          .then(responseData => {
          })
          .catch(err => console.error('Could not send subscription to server', err));
      })
      .catch(err => console.error('Could not subscribe to notifications', err));
  }

  /**
   * Listens for push messages and displays notifications if supported by the browser.
   */
  private listenForMessages() {
    this.swPush.messages.subscribe((message: any) => {
      let options: NotificationOptions = {
        body: '',
        tag: '',
        //renotify: false,
        icon: "assets/icons/icon-72x72.png",
        badge: "assets/icons/icon-72x72.png",
        //timestamp: Date.now()
      };

      if ((message.tableName === 'incident') && (message.operation === 'INSERT')) {
        options.body = `V systéme bola zaznamenaná nová porucha.`;
        options.tag = 'new-incident-notification';
        //options.renotify = false;
      } else if (message.tableName) {
        options.body = `V systéme bola zaznamenaná zmena.`;
        options.tag = 'other-notification';
        //options.renotify = false;
      } else {
        options.body = `${message}`;
        options.tag = 'maitenance-notification';
        //options.renotify = false;
      }
      this.notificationService.sendNotification('LIA', options);

      // this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      //   console.log('Notification clicked', action, notification);
      //   // Handle notification click event - make app window active
      // });
    });
  }
}

