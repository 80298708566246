/**
 * PinScreenComponent
 *
 * This component manages the PIN entry screen for locking and unlocking the application.
 * It interacts with the PinService to handle the lock state and PIN validation.
 *
 * Features:
 * - Displays a PIN entry interface when the application is locked
 * - Handles PIN input through on-screen buttons and keyboard events
 * - Provides visual feedback for PIN entry
 * - Attempts to unlock the application when the correct PIN is entered
 * - Hides the PIN screen when the application is unlocked
 *
 * Usage:
 * Include <app-pin-screen></app-pin-screen> in your main app component template.
 * The component will automatically show when the application is locked and hide when unlocked.
 */

import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PinService } from '@services/pin.service';
import { Subscription } from 'rxjs';
import { StateManagementService } from '@services/state-management.service';

@Component({
  selector: 'app-pin-screen',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pin-screen.component.html',
  styleUrl: './pin-screen.component.css'
})
export class PinScreenComponent implements OnInit, OnDestroy {
  // Whether the PIN screen is locked or not
  isLocked: boolean = false;
  private subscription: Subscription = new Subscription();

  // The current PIN being entered
  pin: string = '';
  // The length of the current PIN
  pinLength: number = 0;

  // Property to control the bounce animation
  bounceAnimation: string = '';

  constructor(
    public pinService: PinService,
    private stateManagementService: StateManagementService
  ) { }

  ngOnInit() {
    this.stateManagementService.getIsLocked().subscribe((isLocked) => {
      this.isLocked = isLocked;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Handles keyboard events for PIN entry.
   * @param event The KeyboardEvent object.
   */
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.isLocked === false) {
      return;
    }

    const key = event.key;
    if (/^[0-9]$/.test(key)) {
      this.enterDigit(parseInt(key, 10));
    } else if (key === 'Backspace') {
      this.backspace();
    } else if (key === 'Enter') {
      this.pinService.unlock(this.pin);
    }
  }

  /**
   * Enters a digit into the PIN.
   * @param digit The digit to enter (0-9).
   */
  enterDigit(digit: number) {
    if (this.isLocked === false) {
      return;
    }

    this.pin += digit.toString();
    this.pinLength = this.pin.length;
    if (this.pinLength === 4) {
      // TODO: this might not be necessary if we use backend to validate PIN. there will be some traffic delay.
      setTimeout(() => {
        this.pinService.unlock(this.pin);
        this.pin = '';
        this.pinLength = 0;

        if (this.isLocked) {
          this.triggerBounceEffect();
        }
      }, 250);
    }
  }

  /**
   * Removes the last entered digit from the PIN.
   */
  backspace() {
    if (this.isLocked === false) {
      return;
    }

    if (this.pinLength > 0) {
      this.pin = this.pin.slice(0, -1);
      this.pinLength = this.pin.length;
    }
  }

  /**
   * Triggers the bounce effect animation
   */
  triggerBounceEffect() {
    this.bounceAnimation = 'bounce 0.2s 2'; // 2 bounces in 0.4 seconds
    setTimeout(() => {
      this.bounceAnimation = '';
    }, 1000); // Clear the animation after it completes
  }
}
