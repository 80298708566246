<!-- tab component -->
<div>
  <div class="inline-flex rounded-lg border text-gray-500 border-gray-50 bg-gray-50 p-2 mb-8 w-full">
    @for (tab of tabs; track tab.id) {
    <button (click)="selectTab(tab)" [class]="tab.id === selectedTab.id ?
        'inline-block rounded-md px-2 py-1.5 shadow focus:relative mr-1 bg-white' :
        'inline-block rounded-md px-2 py-1.5 hover:bg-gray-100 hover:shadow focus:relative mr-1'">
      {{ tab.label }}
    </button>
    }
  </div>
</div>

@if (selectedTab.id === 'details') {
<div class="mb-2">
  <label for="pasport-details-entity-name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Názov
    zariadenia</label>
  <input [(ngModel)]="pasportEntityName" type="text" id="pasport-details-entity-name" readonly
    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
</div>

<div class="mb-2">
  <label for="pasport-details-entity-type-sk" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Typ
    zariadenia</label>
  <input [(ngModel)]="pasportEntityTypeSk" type="text" id="pasport-details-entity-type-sk" readonly
    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
</div>

<div class="mb-8">
  <label for="pasport-details-entity-uuid"
    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Identifikátor
    UUID</label>
  <input [(ngModel)]="pasportEntityUuid" type="text" id="pasport-details-entity-uuid" readonly
    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
</div>

<app-device-hierarchy (emitDeviceHierarchyShowPasportDetails)="onDetailsPasportClicked($event)" [uuid]="pasportEntityUuid" [deviceTypeName]="pasportEntityType" [objectName]="pasportEntityName"
  [address]="'adresa'"></app-device-hierarchy>

<div class="mb-2"></div>
<!-- <div class="relative overflow-x-auto shadow-md sm:rounded-lg mb-4"> -->
<!-- <div class="mb-8 mt-8 bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden"> -->
<div class="mb-8 mt-8 relative bg-gray-100 rounded-lg">
  @if (paramsEditFormVisible === false) {
  <div class="rounded-lg overflow-hidden shadow-md ">
    <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
      <thead class="text-xs text-white uppercase bg-blue-600 border-b border-blue-400 dark:text-white">
        <tr>
          <th scope="col" class="px-2 py-3 bg-blue-500">
            Parametre
          </th>
          <th scope="col" class="px-2 py-3">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        @for (item of objectEntries(selectedTemplate?.data?.params); track item[0]) {
        <!-- TODO: hardcoded types. use constants -->
        @if (item[0] !== 'uuid' && item[0] !== 'importUUID' && item[0] !== 'pasportType' && item[0] !== 'entityType') {
        <tr class="bg-blue-600 border-b border-blue-400">
          <th scope="row" class="px-2 py-2 font-medium bg-blue-500 text-blue-50 dark:text-blue-100">
            {{ item[1].translations[0].translation }}
          </th>
          <td class="px-2 py-2">
            @if (item[1].valueType === 'timestamp') {
            {{ formatEpochForInput(paramValues[item[0]]) | formatSkDate }}
            } @else if (item[1].valueType === 'boolean') {
              @if (paramValues[item[0]] == true) {
              {{ 'áno'}}
              }
              @if (paramValues[item[0]] == false) {
              {{ 'nie' }}
              }
            } @else {
            {{ paramValues[item[0]] }}
            }
          </td>
        </tr>
        }
        }
      </tbody>
    </table>
  </div>
  }



  <!-- TODO: make this a separate component -->
  @if (paramsEditFormVisible) {
  <div class="bg-white dark:bg-gray-800 relative shadow-md rounded-lg overflow-hidden">
    <table class="w-full text-sm text-left rtl:text-right">
      <thead class="text-xs uppercase bg-gray-50 border-b border-gray-200 ">
        <tr>
          <th scope="col" class="px-2 py-3">
            Parametre
          </th>
          <th scope="col" class="px-2 py-3">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        @for (item of objectEntries(selectedTemplate?.data?.params); track item[0]) {
        <!-- TODO: hardcoded types. use constants -->
        @if (item[0] !== 'uuid' && item[0] !== 'importUUID' && item[0] !== 'pasportType' && item[0] !== 'entityType') {
        <tr class="bg-gray-50 border-b border-gray-200">
          <th scope="row" class="px-2 py-2 font-medium">
            {{ item[1].translations[0].translation }}
          </th>
          <td class="px-2 py-2">
            <ng-container [ngSwitch]="item[1].valueType">
              <ng-container *ngSwitchCase="'text'">
                <input type="text" [(ngModel)]="paramValues[item[0]]"
                  class="px-2 py-1 rounded
                bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
              </ng-container>
              <ng-container *ngSwitchCase="'number'">
                <input type="number" [(ngModel)]="paramValues[item[0]]"
                  class="px-2 py-1 rounded
                bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
              </ng-container>
              <ng-container *ngSwitchCase="'boolean'">
                <input type="checkbox" [(ngModel)]="paramValues[item[0]]"
                  class="rounded bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
              </ng-container>
              <ng-container *ngSwitchCase="'timestamp'">
                <input type="datetime-local" [ngModel]="formatEpochForInput(paramValues[item[0]])"
                  (ngModelChange)="updateEpochValue(item[0], $event)"
                  class="px-2 py-1 rounded bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input type="text" [(ngModel)]="paramValues[item[0]]"
                  class="px-2 py-1 rounded
                bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
              </ng-container>
            </ng-container>
          </td>
        </tr>
        }
        }
      </tbody>
    </table>
  </div>
  }

  <div class="p-2 rounded-lg flex flex-wrap">
    @if (paramsEditFormVisible === false) {
    <div class="flex-1">&nbsp;</div>
    <button type="button" (click)="showParamsEditForm()" class="flex-none
      focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:focus:ring-gray-800 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white
      disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      <svg class="w-3.5 h-3.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 512 512">
        <path
          d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z" />
      </svg>
      Upraviť parametre zariadenia
    </button>
    }
    @if (paramsEditFormVisible) {
    <button type="button" (click)="hideParamsEditForm()" class="flex-1 mr-1 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800
      disabled:bg-red-400 disabled:dark:bg-red-500 disabled:cursor-not-allowed ">
      Zrušiť
    </button>
    <button type="button" (click)="saveEntityParams()" class="flex-1 ml-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
      disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      Uložiť
    </button>
    }
  </div>
</div>

<app-pasport-device-history [uuid]="pasportEntityUuid" [dataService]="dataService"></app-pasport-device-history>
}

@if (selectedTab.id === 'control') {
<app-device-control [uuid]="pasportEntityUuid" [deviceTypeName]="pasportEntityType"
[objectName]="pasportEntityName"></app-device-control>
}

@if (selectedTab.id === 'hierarchy') {
<app-pasport-device-relations [relations]="relations" [dataService]="dataService">
</app-pasport-device-relations>

<app-pasport-device-new-relation [pasportEntityUuid]="pasportEntityUuid" (onNewRelation)="handleNewRelation()"></app-pasport-device-new-relation>
}

@if (selectedTab.id === 'faults') {
<div class="mb-8 bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase dark:text-gray-400 border-b border-gray-200 dark:border-gray-700">
      <tr>
        <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
          Dátum / Kód
        </th>
        <th scope="col" class="px-6 py-3">
          Porucha / Stav
        </th>
      </tr>
    </thead>
    <tbody>
      @if (relatedIncidents.length > 0) {
      @for (item of relatedIncidents; track item.id) {
      <tr class="border-b border-gray-200 dark:border-gray-700">
        <th scope="row"
          class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
          {{ item.evidenceDate | date: 'dd.MM.yyyy HH:mm:ss' }} <br /> {{ item.code }}
        </th>
        <td class="px-2 py-2">
          <div class="mb-1">{{ nameMapping[item.name] }}</div>
          @if (item.state === 'inprogress') {
          <span
            class="whitespace-nowrap inline-flex items-center bg-yellow-100 text-yellow-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
            <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 512 512">
              <path
                d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
            </svg>
            Aktívne</span>
          } @else if (item.state === 'new') {
          <span
            class="whitespace-nowrap inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300">
            <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M192 32c17.7 0 32 14.3 32 32V199.5l111.5-66.9c15.2-9.1 34.8-4.2 43.9 11s4.2 34.8-11 43.9L254.2 256l114.3 68.6c15.2 9.1 20.1 28.7 11 43.9s-28.7 20.1-43.9 11L224 312.5V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V312.5L48.5 379.4c-15.2 9.1-34.8 4.2-43.9-11s-4.2-34.8 11-43.9L129.8 256 15.5 187.4c-15.2-9.1-20.1-28.7-11-43.9s28.7-20.1 43.9-11L160 199.5V64c0-17.7 14.3-32 32-32z" />
            </svg>
            Nové</span>
          } @else if (item.state === 'solved') {
          <span
            class="whitespace-nowrap inline-flex items-center bg-green-100 text-green-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
            <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 448 512">
              <path
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
            Vyriešené</span>
          } @else if (item.state === 'unresolved') {
          <span
            class="whitespace-nowrap inline-flex items-center bg-orange-100 text-orange-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-orange-900 dark:text-orange-300">
            <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 512 512">
              <path
                d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
            </svg>
            Uzatvorené bez riešenia</span>
          } @else if (item.state === 'terminated') {
          <span
            class="whitespace-nowrap inline-flex items-center bg-red-100 text-red-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-red-900 dark:text-red-300">
            <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 448 512">
              <path
                d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
            </svg>
            Zrušené</span>
          } @else if (item.state === 'pending') {
          <span
            class="whitespace-nowrap inline-flex items-center bg-purple-100 text-purple-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-purple-900 dark:text-purple-300">
            <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9H112z" />
            </svg>
            Pozastavené</span>
          }
        </td>
      </tr>
      }
      } @else {
      <tr>
        <td colspan="2" class="px-6 py-3 text-center text-gray-500 dark:text-gray-400">
          Žiadne poruchy
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>

<div class="flex items-center justify-center mt-10">
  <button type="button" (click)="showNewIncidentForm(pasportEntityUuid)"
    class="px-3 py-2.5 text-base font-medium text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
    Vytvoriť novú poruchu
  </button>
</div>
}

@if (selectedTab.id === 'documents') {
<div class="flex flex-col">
  <div class="mt-4 mb-2 text-center ">
    Použite kameru telefónu na zhotovenie fotodokumentácie alebo pridajte súbory priamo z Vášho zariadenia.
  </div>
</div>

<div class="flex items-center justify-center mt-2">
  <input id="maintenance-file-input-camera" class="hidden" type="file" accept="image/*" capture="camera"
    (change)="onCameraImageCapture($event)">
  <label for="maintenance-file-input-camera" class="cursor-pointer">
    <div
      class="px-3 py-2.5 text-base font-medium text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
      <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 512 512">
        <path
          d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
      </svg>
      &nbsp;&nbsp;Odfotiť
    </div>
  </label>
  &nbsp;&nbsp;
  <input id="maintenance-file-input-storage" class="hidden" type="file" accept="*"
    (change)="onStorageFileSelect($event)">
  <label for="maintenance-file-input-storage" class="cursor-pointer">
    <div
      class="px-3 py-2.5 text-base font-medium text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
      <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 384 512">
        <path
          d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
      </svg>
      &nbsp;&nbsp;Pridať súbor
    </div>
  </label>
</div>

<div class="mt-3">
  @for (document of maintenanceDocuments; track document.id) {
  <div class="flex mb-2">
    <div class="w-24">
      @if (document.type === 'image') {
      <img [src]="document.safeUrl" (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 cursor-pointer object-cover rounded-lg"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}" alt="">
      } @else if (document.type === 'pdf') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          PDF súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'xml') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          XML súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'json') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          JSON súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'xls') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          XLS súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'txt') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          Textový súbor<br />{{ document.name }}
        </p>
      </div>
      } @else {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          Súbor<br />{{ document.name }}
        </p>
      </div>
      }
    </div>
    <div class="pl-2 flex-1">
      <b>Vytvorené:</b> {{ document.creationDate ? (document.creationDate | date: 'dd.MM.yyyy HH:mm') : 'Neuvedené'
      }}<br />
      <b>Servis:</b> {{ (document?.tags['incidentId'] || document?.tags['taskId']) ? 'Áno' : 'Nie' }}<br />
      <b>Komentár:</b> {{ document?.tags['comment'] }}
    </div>
  </div>
  }

  @for (document of maintenanceDocumentsNew; track document.id) {
  <div class="flex mb-2">
    <div class="w-24">
      @if (document.type === 'image') {
      <img [src]="document.url" (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 object-cover rounded-lg"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}" alt="">
      } @else if (document.type === 'pdf') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          PDF súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'xml') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          XML súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'json') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          JSON súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'xls') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          XLS súbor<br />{{ document.name }}
        </p>
      </div>
      } @else if (document.type === 'txt') {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          Textový súbor<br />{{ document.name }}
        </p>
      </div>
      } @else {
      <div (click)="toggleDocumentHighlight(document)"
        class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
        [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
          Súbor<br />{{ document.name }}
        </p>
      </div>
      }
    </div>
    <div class="ml-2 flex-1">
      <textarea [(ngModel)]="document.tags['comment']" rows="4"
        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Zadajte komentár ..."></textarea>
    </div>
  </div>
  }
</div>

<div *ngIf="(maintenanceDocuments.length <= 0) && (maintenanceDocumentsNew.length <=0)"
  class="flex items-center justify-center my-8">
  <div class="flex flex-col items-center space-y-2">
    <svg class="w-10 h-10 text-gray-300 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
      fill="currentColor" viewBox="0 0 384 512">
      <path
        d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
    </svg>
    <span class="text-lg font-medium text-gray-300 dark:text-gray-600">Žiadne súbory</span>
  </div>
</div>

<div class="bg-gray-100 p-2 mt-6 rounded-lg flex flex-wrap">
  <div class="flex justify-center w-full">
    <div *ngIf="maintenanceDocumentsHighlightedCount <= 0"
      class="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      Nových súborov: {{ maintenanceDocumentsNew.length }}
    </div>

    @if (maintenanceDocumentsHighlightedCount > 0) {
    <button type="button" (click)="showImagePreview(maintenanceDocumentHighlighted?.safeUrl)"
      [disabled]="( (!(maintenanceDocumentsHighlightedCount > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-1 flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
          disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      @if (isProcessingDocumentsRequest) {
      <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor" />
      </svg>
      }
      {{ isProcessingDocumentsRequest ? '' : 'Náhľad' }}
    </button>
    <a [href]="maintenanceDocumentHighlighted?.safeUrl" [download]="maintenanceDocumentHighlighted?.name"
      target="_blank">
      <button type="button"
        [disabled]="( (!(maintenanceDocumentsHighlightedCount > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-1 flex items-center justify-center ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
                disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
        @if (isProcessingDocumentsRequest) {
        <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor" />
        </svg>
        }
        {{ isProcessingDocumentsRequest ? '' : 'Stiahnuť' }}
      </button></a>
    <button type="button" (click)="deleteDocuments()"
      [disabled]="( (!(maintenanceDocumentsHighlightedCount > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-1 flex items-center justify-center ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
              disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      @if (isProcessingDocumentsRequest) {
      <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor" />
      </svg>
      }
      {{ isProcessingDocumentsRequest ? '' : 'Zmazať' }}</button>
    } @else {
    <button type="button" (click)="uploadDocuments()"
      [disabled]="( (!(maintenanceDocumentsNew.length > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-none flex items-center ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
              disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      @if (isProcessingDocumentsRequest) {
      <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor" />
      </svg>
      }
      {{ isProcessingDocumentsRequest ? '' : 'Uložiť' }}
    </button>
    }
  </div>
</div>
}






<!-- Bottom of the page to add some space for bottom navigation -->
<div>
  <br /><br /><br /><br /><br /><br /><br />
</div>

<div class="relative isolate" #imagePreviewContainer></div>