import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataService, Incident, nameMapping, PasportHierarchyEntity, PasportItemFile } from '@services/data.service';
import { DeviceControlComponent } from '@app/features/incidents/device-control/device-control.component';
import { DeviceHierarchyComponent } from '@app/features/incidents/device-hierarchy/device-hierarchy.component';
import { PasportEntity, PasportEntityDetails, PasportEntityRelation, PasportTemplateEntity } from '@app/shared/interfaces/pasport-template.interface';
import { FormatSkDatePipe } from '@app/shared/format-sk-date.pipe';
import { SafeUrl } from '@angular/platform-browser';
import { ImagePreviewComponent } from '@app/features/incidents/image-preview/image-preview.component';
import { environment } from '@environments/environment';
import { PasportDeviceRelationsComponent } from '@app/features/incidents/pasport-device-relations/pasport-device-relations.component';
import { PasportDeviceNewRelationComponent } from "../pasport-device-new-relation/pasport-device-new-relation.component";
import { PasportDeviceHistoryComponent } from '../pasport-device-history/pasport-device-history.component';

interface MaintenanceDocument {
  id: string;
  entityUuid: string;
  name: string;
  url: string;
  file: any;
  type: string;
  isSelected: boolean;
  safeUrl: SafeUrl | null;
  creationDate: Date | null;
  tags: any;
}

interface Tab {
  id: string;
  label: string;
}

@Component({
  selector: 'app-pasport-details',
  standalone: true,
  imports: [CommonModule, FormsModule, DeviceControlComponent, DeviceHierarchyComponent, FormatSkDatePipe, ImagePreviewComponent, PasportDeviceRelationsComponent, PasportDeviceNewRelationComponent, PasportDeviceHistoryComponent],
  templateUrl: './pasport-details.component.html',
  styleUrl: './pasport-details.component.css'
})
export class PasportDetailsComponent {
  nameMapping = nameMapping;

  pasportEntity: PasportHierarchyEntity | null = null;
  pasportEntityName: string = '';
  pasportEntityType: string = '';
  pasportEntityTypeSk: string = '';
  pasportEntityId: number | undefined;
  pasportEntityUuid: string = '';
  pasportEntityLatitude: number = 0;
  pasportEntityLongitude: number = 0;
  passportEntityData: any[] = [];
  relatedIncidents: Incident[] = [];
  relations: PasportEntityRelation[] = [];

  paramsEditFormVisible: boolean = false;
  entityDetails: PasportEntityDetails | null = null;
  selectedTemplate: PasportTemplateEntity | null = null;
  paramValues: { [key: string]: any } = {};

  // documents already assigned to the task / incident / device
  maintenanceDocuments: MaintenanceDocument[] = [];
  // documents selected from device and ready to be uploaded to the server
  maintenanceDocumentsNew: MaintenanceDocument[] = [];
  // number of documents that are highlighted (selected) for deletion
  maintenanceDocumentsHighlightedCount: number = 0;
  // highlighted document
  maintenanceDocumentHighlighted: MaintenanceDocument | null = null;
  // indicator for processing the delete documents request
  isProcessingDocumentsRequest: boolean = false;
  public imageUrl: SafeUrl | null = null;  // Store as SafeUrl
  @ViewChild('imagePreviewContainer', { read: ViewContainerRef }) container: ViewContainerRef | undefined;

  tabs: Tab[] = [
    { id: 'details', label: 'Detaily' },
    { id: 'control', label: 'Riadenie' },
    { id: 'hierarchy', label: 'Väzby' },
    { id: 'faults', label: 'Poruchy' },
    { id: 'documents', label: 'Dokumenty' }
  ];
  selectedTab: Tab = this.tabs[0];

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.getPasportEntity().subscribe(async (data: PasportHierarchyEntity | null) => {
      this.init();
      this.hideParamsEditForm();
      this.pasportEntity = data;

      try {
        this.pasportEntityName = this.pasportEntity?.name || '';
        this.pasportEntityTypeSk = this.dataService.formatEntityType(this.pasportEntity?.type) || '';
        this.pasportEntityType = this.pasportEntity?.type || '';
        this.pasportEntityUuid = this.pasportEntity?.uuid || '';
        this.pasportEntityLatitude = this.pasportEntity?.coordinates.latitude || 0;
        this.pasportEntityLongitude = this.pasportEntity?.coordinates.longitude || 0;

        const data = this.pasportEntity?.data || {};
        delete data.name;
        delete data.uuid;
        delete data.reactivePowerCompensationType;
        delete data.entityType;
        delete data.importUUID;
        // FIXME: why is this not removed from the template?
        //delete data.pasportType;

        this.passportEntityData = Object.entries(data);
        this.getRelatedIncidents(this.pasportEntityUuid);
        await this.getParams();
        // TODO: this.pasportEntityId exists only after getParams()
        if (this.pasportEntityId) {
          this.getRelations(this.pasportEntityId);
        }
        this.refreshDocuments();
      } catch (error) {
        console.error('Error loading pasport entity', error);
      }
    });
  }

  @Output() onClickNewIncident: EventEmitter<string> = new EventEmitter();
  showNewIncidentForm(uuid: string) {
    this.onClickNewIncident.emit(uuid);
  }

  async init() {
    // init all data to default values
    this.pasportEntity = null;
    this.pasportEntityName = '';
    this.pasportEntityType = '';
    this.pasportEntityTypeSk = '';
    this.pasportEntityId = undefined;
    this.pasportEntityUuid = '';
    this.pasportEntityLatitude = 0;
    this.pasportEntityLongitude = 0;
    this.passportEntityData = [];
    this.relatedIncidents = [];
    this.relations = [];
    this.maintenanceDocuments = [];
    this.maintenanceDocumentsNew = [];
    this.maintenanceDocumentsHighlightedCount = 0;
    this.maintenanceDocumentHighlighted = null;
    this.isProcessingDocumentsRequest = false;
    this.imageUrl = null;
  }

  // FIXME: this will stop working after server side filtering will be implemented?
  async getRelatedIncidents(uuid: string) {
    try {
      this.relatedIncidents = [];
      const incdidents: Incident[] = await this.dataService.fetchApiGetObject(uuid);
      this.relatedIncidents = incdidents;

      // for (const incident of incdidents) {
      //   if (incident.objectId == uuid) {
      //     this.relatedIncidents.push(incident);
      //   } else {
      //     for (const task of incident.tasks) {
      //       if (task.objectId == uuid) {
      //         this.relatedIncidents.push(incident);
      //         break;
      //       }
      //     }
      //   }
      // }

    } catch (e) { }
  }

  async getParams() {
    // call api to get current params and specific template for the entity
    this.entityDetails = await this.dataService.fetchApiGetPasportEntityDetails(this.pasportEntityUuid);
    this.pasportEntityId = this.entityDetails?.entity?.id;
    // if (entityDetails?.entity?.data) {
    //   this.removeAttributes(entityDetails?.entity?.data);
    // }
    // get template from the entity details
    this.selectedTemplate = this.entityDetails?.template ?? null;
    // remove attributes that data.params["key"].access does not contain "R"
    if (this.selectedTemplate?.data?.params) {
      for (const key in this.selectedTemplate.data.params) {
        if (!((this.selectedTemplate.data.params[key] as any).access?.includes('R'))) {
          delete this.selectedTemplate.data.params[key];
        }
      }
    }

    this.resetParams();
    // remove attributes from the template, so they won't be shown in the form
    // if (this.selectedTemplate?.data?.params) {
    //   this.removeAttributes(this.selectedTemplate.data.params);
    // }
    // map existing entityDetails into paramValues
    if (this.entityDetails?.entity?.data) {
      for (const key in this.entityDetails.entity.data) {
        this.paramValues[key] = this.entityDetails.entity.data[key];
      }
    }
  }

  async showParamsEditForm() {
    this.getParams();
    // show params in editable form
    this.paramsEditFormVisible = true;
  }

  hideParamsEditForm() {
    this.resetParams();
    // map existing entityDetails into paramValues
    if (this.entityDetails?.entity?.data) {
      for (const key in this.entityDetails.entity.data) {
        this.paramValues[key] = this.entityDetails.entity.data[key];
      }
    }
    this.paramsEditFormVisible = false;
  }

  private resetParams() {
    this.paramValues = {};
    if (this.selectedTemplate?.data?.params) {
      for (const key in this.selectedTemplate.data.params) {
        this.paramValues[key] = null;
      }
    }
  }

  /**
   * Fetches and processes the relations (ancestors and descendants) for a given Pasport entity.
   *
   * @param id - The ID of the Pasport entity to fetch relations for.
   */
  async getRelations(id: number) {
    // Fetch both ancestor and descendant relations concurrently
    const [resultAncestors, resultDescendants] = await Promise.all([
      this.dataService.fetchApiGetPasportRelationsAncestors(id),
      this.dataService.fetchApiGetPasportRelationsDescendants(id)
    ]);

    // Process ancestor relations
    resultAncestors.relations.forEach(relation => {
      // Replace numeric ancestor reference with full ancestor object
      if (typeof (relation.ancestor as any) === 'number') {
        const ancestorId = relation.ancestor as unknown as number;
        const ancestor = resultAncestors.ancestors.find(a => a.id === ancestorId);
        if (ancestor) {
          relation.ancestor = ancestor;
        }
      }
      // Set descendant to current Pasport entity if available
      if (this.pasportEntity) {
        relation.descendant = {
          id: resultAncestors.id,
          uuid: resultAncestors.uuid,
          data: resultAncestors.data,
          entityType: resultAncestors.entityType,
          ancestors: [],
          descendants: []
        }
      }
    });

    // Process descendant relations
    resultDescendants.relations.forEach(relation => {
      // Replace numeric descendant reference with full descendant object
      if (typeof (relation.descendant as any) === 'number') {
        const descendantId = relation.descendant as unknown as number;
        const descendant = resultDescendants.descendants.find(d => d.id === descendantId);
        if (descendant) {
          relation.descendant = descendant;
        }
      }
      // Set ancestor to current Pasport entity if available
      if (this.pasportEntity) {
        relation.ancestor = {
          id: resultDescendants.id,
          uuid: resultDescendants.uuid,
          data: resultDescendants.data,
          entityType: resultDescendants.entityType,
          ancestors: [],
          descendants: []
        }
      }
    });

    // Combine ancestor and descendant relations
    this.relations = [...resultAncestors.relations, ...resultDescendants.relations];
  }

  // private removeAttributes(params: { [key: string]: any }) {
  //   // TODO: hardcoded types. use constants
  //   delete params['entityType'];
  //   delete params['uuid'];
  //   delete params['importUUID'];
  //   delete params['pasportType'];
  // }

  async saveEntityParams() {
    // call api to save params
    const response = await this.dataService.fetchApiPatchPasportEntityParams(this.pasportEntityUuid, this.paramValues);
    await this.dataService.fetchApiGetPasportEntity(this.pasportEntityUuid);
    this.hideParamsEditForm();
  }

  objectEntries(obj: any): [string, any][] {
    return obj ? Object.entries(obj) : [];
  }

  formatEpochForInput(epochMilliseconds: number): string {
    if (!epochMilliseconds) return '';
    const date = new Date(epochMilliseconds);
    return date.getFullYear() + '-' +
           ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
           ('0' + date.getDate()).slice(-2) + 'T' +
           ('0' + date.getHours()).slice(-2) + ':' +
           ('0' + date.getMinutes()).slice(-2);
  }

  updateEpochValue(key: string, event: string) {
    if (event) {
      const [datePart, timePart] = event.split('T');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hour, minute] = timePart.split(':').map(Number);
      const date = new Date(year, month - 1, day, hour, minute);
      this.paramValues[key] = date.getTime();
    } else {
      this.paramValues[key] = null;
    }
  }



  /**
   * Toggles the highlight status of a maintenance document.
   *
   * @param document - The maintenance document to toggle the highlight status for.
   */
  public toggleDocumentHighlight(document: MaintenanceDocument) {
    document.isSelected = !document.isSelected;
    // deselect all other documents
    this.maintenanceDocuments.forEach(doc => {
      if (doc !== document) {
        doc.isSelected = false;
      }
    });
    // deselect all new documents
    this.maintenanceDocumentsNew.forEach(doc => {
      if (doc !== document) {
        doc.isSelected = false;
      }
    });

    if (document.isSelected) {
      this.maintenanceDocumentHighlighted = document;
    } else {
      this.maintenanceDocumentHighlighted = null;
    }

    // update the number of highlighted documents - consists of documents and also new documents
    this.maintenanceDocumentsHighlightedCount = this.maintenanceDocuments.length > 0 ? this.maintenanceDocuments.filter(doc => doc.isSelected).length + this.maintenanceDocumentsNew.filter(doc => doc.isSelected).length : 0;
  }

  /**
   * Displays an image preview by creating a dynamic component and setting the image source.
   * @param src - The source of the image to be displayed.
   */
  public showImagePreview(src: SafeUrl | undefined | null) {
    if (!src) {
      return;
    }

    if (this.container) {
      const componentRef = this.container.createComponent(ImagePreviewComponent);
      componentRef.instance.imgSrc = src;

      // Subscribe to the close event
      componentRef.instance.close.subscribe(() => {
        // Destroy the component
        componentRef.destroy();
      });
    }
  }

  /**
   * Deletes the selected documents.
   * - Removes unsaved documents that are not selected.
   * - Removes all saved documents that are selected.
   * - Validates the responses from the API.
   * - Refreshes the list of documents.
   */
  async deleteDocuments() {
    this.maintenanceDocuments.map(doc => {
    });

    // show processing indicator
    this.isProcessingDocumentsRequest = true;
    // Artificial delay for UI while processing requests
    await new Promise(resolve => setTimeout(resolve, environment.UI_PROCESSING_DELAY));
    // remove unsaved documents
    this.maintenanceDocumentsNew = this.maintenanceDocumentsNew.filter(doc => !doc.isSelected);

    // remove all saved documents that are highlighted
    const responses = await Promise.all(
      this.maintenanceDocuments
      .filter(doc => doc.isSelected)
      .map(doc => this.dataService.fetchApiDeletePasportEntityFile(doc.entityUuid, doc.id))
    );
    // TODO: validate responses

    this.isProcessingDocumentsRequest = false;
    // refresh list of documnets
    await this.refreshDocuments();
  }

  /**
   * Refreshes the list of maintenance documents.
   * If the task object ID is available, it fetches the list of PasportItemFile objects
   * using the data service and updates the maintenanceDocuments array accordingly.
   * It also updates the maintenanceDocumentsHighlightedCount based on the selected documents.
   */
  private async refreshDocuments() {

    let result: PasportItemFile[] = await this.dataService.fetchApiGetPasportEntityFileList(this.pasportEntityUuid);
    this.maintenanceDocumentsNew = [];
    this.maintenanceDocuments = [];

    for (let i = 0; i < result.length; i++) {
      this.maintenanceDocuments.push({
        id: result[i].id,
        entityUuid: result[i].entityUuid,
        name: result[i].name,
        // TODO: ??? we can use result[i].entityUuid for pasport entity description, but this.task?.objectId should also be fine
        url: `${environment.URL_PASPORT_FILES.replace('{id}', this.pasportEntityUuid) }/${result[i].id}`,
        file: null,
        type: 'image',
        isSelected: false,
        safeUrl: await this.dataService.fetchApiGetPasportEntityFile(this.pasportEntityUuid || '', result[i].id),
        creationDate: ( result[i].creationDate ? new Date(result[i].creationDate) : null ),
        tags: result[i].tags || {}
      });
    }

    // set the number of highlighted documents to 0, we could just set to 0, but we want to make sure that the number is correct
    this.maintenanceDocumentsHighlightedCount = this.maintenanceDocuments.length > 0 ? this.maintenanceDocuments.filter(doc => doc.isSelected).length + this.maintenanceDocumentsNew.filter(doc => doc.isSelected).length : 0;

  }


  /**
   * Uploads the maintenance documents.
   *
   * @remarks
   * This method shows a validation message if the task object ID is not available.
   * It then validates the responses and uploads the documents using the data service.
   * Finally, it refreshes the documents.
   *
   * @returns A Promise that resolves when the documents are uploaded.
   */
  async uploadDocuments() {
    // TODO: show validation message
    if (!this.pasportEntityUuid) {
      return;
    }
    // show processing indicator
    this.isProcessingDocumentsRequest = true;
    // Artificial delay for UI while processing requests
    await new Promise(resolve => setTimeout(resolve, environment.UI_PROCESSING_DELAY));
    const responses = await Promise.all(
      this.maintenanceDocumentsNew.map(
        (doc) => {
          let tags = `source:pasport`;
          // if (this.task?.id) {
          //   tags += `taskId:${this.task.id}`;
          // }
          // if (this.task?.incidentId) {
          //   tags += (tags.length > 0 ? `,`:``) + `incidentId:${this.task.incidentId}`;
          // }
          if (doc.tags['comment']) {
            tags += (tags.length > 0 ? `,`:``) + `comment:${doc.tags['comment']}`;
          }
          this.dataService.fetchApiPostPasportEntityFile(this.pasportEntityUuid ?? '', tags, doc.file)
        }
      )
    );
    // TODO: validate the responses

    this.isProcessingDocumentsRequest = false;
    // refresh list of documnets
    this.maintenanceDocumentsNew = [];
    this.maintenanceDocuments = [];
    await new Promise(resolve => setTimeout(resolve, environment.UI_PROCESSING_DELAY));
    this.refreshDocuments();
  }

  /**
   * Handles the event when a camera image is captured.
   * @param event - The event object containing the captured image file.
   */
  onCameraImageCapture(event: any) {
    const file = event.target.files[0];
    const fileType = file.type;
    let documentType = 'other';

    if (fileType.startsWith('image/')) {
      documentType = 'image';
    } else if (fileType === 'application/pdf') {
      documentType = 'pdf';
    } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      documentType = 'xls';
    } else if (fileType === 'application/xml' || fileType === 'text/xml') {
      documentType = 'xml';
    } else if (fileType === 'application/json') {
      documentType = 'json';
    } else if (fileType === 'text/plain') {
      documentType = 'txt';
    }

    // TODO: make UI warning
    if (documentType !== 'image') {
      console.error('The selected file is not an image.');
      return;
    }

    const imageURL = URL.createObjectURL(file);
    this.maintenanceDocumentsNew.push({
      id: '',
      entityUuid: '',
      name: file.name,
      url: imageURL,
      file: file,
      type: 'image',
      isSelected: false,
      safeUrl: null,
      creationDate: null,
      tags: {}
    });
  }

  /**
   * Handles the selection of a storage file.
   *
   * @param event - The event object containing information about the selected file.
   */
  onStorageFileSelect(event: any) {
    const file = event.target.files[0];
    const fileType = file.type;
    let documentType = 'other';

    if (fileType.startsWith('image/')) {
      documentType = 'image';
    } else if (fileType === 'application/pdf') {
      documentType = 'pdf';
    } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      documentType = 'xls';
    } else if (fileType === 'application/xml' || fileType === 'text/xml') {
      documentType = 'xml';
    } else if (fileType === 'application/json') {
      documentType = 'json';
    } else if (fileType === 'text/plain') {
      documentType = 'txt';
    }

    const imageURL = URL.createObjectURL(file);
    this.maintenanceDocumentsNew.push({
      id: '',
      entityUuid: '',
      name: file.name,
      url: imageURL,
      file: file,
      type: documentType,
      isSelected: false,
      safeUrl: null,
      creationDate: null,
      tags: {}
    });
  }

  selectTab(tab: Tab): void {
    this.selectedTab = tab;
  }

  /**
   * Handles the creation of a new relation.
   *
   * This method clears the existing relations array and fetches updated relations
   * for the current pasport entity if its ID is available.
   * Used for example after new relation is created in pasport-device-new-relation component.
   */
  handleNewRelation(): void {
    this.relations = [];
    if (this.pasportEntityId) {
      this.getRelations(this.pasportEntityId);
    }
  }

  /** Event emitter that fires when a pasport detail is clicked, emitting the UUID string */
  @Output() emitPasportDetailsShowPasportDetails: EventEmitter<string> = new EventEmitter();

  /**
   * Handles when a passport detail is clicked in the device hierarchy
   * @param uuid The unique identifier of the passport entity that was clicked
   * @emits emitPasportDetailsShowPasportDetails - Emits the uuid of the passport entity to show details for
   */
  onDetailsPasportClicked(uuid: string) {
    this.emitPasportDetailsShowPasportDetails.emit(uuid);
  }

}
