import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDhmTime',
  standalone: true
})
export class FormatDhmTimePipe implements PipeTransform {

  /**
   * Transforms time components into a formatted string representation.
   *
   * @param refreshTrigger - An optional parameter to trigger refresh (not used in the function logic)
   * @param years - The number of years
   * @param months - The number of months
   * @param days - The number of days
   * @param hours - The number of hours
   * @param minutes - The number of minutes
   * @returns A formatted string representing the time duration
   *
   * @description
   * This method takes time components (years, months, days, hours, minutes) and formats them into a string.
   * It uses the following abbreviations: 'r' for years, 'm' for months, 'd' for days, 'h' for hours, 'm' for minutes.
   * The function omits any zero values at the start of the duration, but includes all values after the first non-zero value.
   * If all values are zero, it returns '1m' (1 minute) as the minimum duration.
   *
   * @example
   * // returns "2r 3m 5d 1h 30m"
   * transform(null, 2, 3, 5, 1, 30)
   *
   * @example
   * // returns "5d 1h 30m"
   * transform(null, 0, 0, 5, 1, 30)
   *
   * @example
   * // returns "1m"
   * transform(null, 0, 0, 0, 0, 0)
   */
  transform(refreshTrigger: any, years: number, months: number, days: number, hours: number, minutes: number): string {
    const parts = [
      { value: years, unit: 'r' },
      { value: months, unit: 'm' },
      { value: days, unit: 'd' },
      { value: hours, unit: 'h' },
      { value: minutes, unit: 'm' }
    ];

    let result = '';
    let hasNonZero = false;

    for (const part of parts) {
      if (part.value !== 0 || hasNonZero) {
        result += `${part.value}${part.unit} `;
        hasNonZero = true;
      }
    }

    result = result.trim();
    return result === '' ? '1m' : result;
  }
}
