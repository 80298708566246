import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataService, QrCodeVerificationStatus } from '@services/data.service';
import jsQR from 'jsqr';

@Component({
  selector: 'app-qr-code-scanner',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './qr-code-scanner.component.html',
  styleUrl: './qr-code-scanner.component.css'
})
export class QrCodeScannerComponent {
  videoElement!: HTMLVideoElement;
  canvasElement!: HTMLCanvasElement;
  canvasContext!: CanvasRenderingContext2D;
  qrCodeScanStarted: boolean = false;
  qrCodeScanned: boolean = false;
  QrCodeVerificationStatus = QrCodeVerificationStatus; // make the enum available in the template
  qrCodeVerified: QrCodeVerificationStatus = QrCodeVerificationStatus.NotTested;
  // TODO: use constant
  qrCode: string = 'neskenované';
  qrCodeObjectName: string = '';

  constructor(private dataService: DataService) {}

  ngOnInit() {
    //this.initQrCodeScanner();
    this.dataService.getQrCodeVerified().subscribe((data: QrCodeVerificationStatus) => {
      this.qrCodeVerified = data;
    });
    this.dataService.getQrCodeObjectName().subscribe((data: string) => {
      this.qrCodeObjectName = data;
    });
  }

  private initQrCodeScanner() {
    this.videoElement = document.getElementById('video') as HTMLVideoElement;
    this.canvasElement = document.createElement('canvas');
    const context = this.canvasElement.getContext('2d');
    if (!context) {
      throw new Error('Unable to get 2D context');
    }
    this.canvasContext = context;
    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then(stream => {
        this.videoElement.srcObject = stream;
        this.videoElement.play();
        requestAnimationFrame(this.tick.bind(this));
      });
  }

  ngOnDestroy() {
    // Stop video stream
    if (this.videoElement && this.videoElement.srcObject) {
      (this.videoElement.srcObject as MediaStream).getTracks().forEach(track => track.stop());
    }
  }

  newScan(): void {
    // FIXME: temporary workaround to stop the camera
    if ((this.qrCodeScanned === false) && (this.qrCodeScanStarted === true)) {
      // finish using the camera
      this.qrCodeScanned = true;
      this.videoElement.pause();
      if (this.videoElement.srcObject) {
        (this.videoElement.srcObject as MediaStream).getTracks().forEach(track => track.stop());
      }
      return;
    }

    this.qrCodeScanStarted = true;
    this.qrCodeScanned = false;
    this.qrCode = 'not scanned yet';
    //this.qrCodeVerified = QrCodeVerificationStatus.NotTested;
    this.dataService.updateQrCodeVerified(QrCodeVerificationStatus.NotTested);
    this.initQrCodeScanner();
  }

  tick() {
    if (this.qrCodeScanned) {
      return;
    }

    if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      this.canvasElement.height = this.videoElement.videoHeight;
      this.canvasElement.width = this.videoElement.videoWidth;
      this.canvasContext.drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
      var imageData = this.canvasContext.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
      var code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert',
      });
      if (code) {
        try {
          const url = new URL(code.data);
          const id = url.searchParams.get('id');
          this.qrCode = id || 'not found';

          // check if we are going to scan for the device details only == no object name specified
          if (this.qrCodeObjectName === '') {
            if (this.qrCode) {
              this.dataService.updateQrCodeObjectNameScanned(this.qrCode);
            }
          } else {
            if (this.qrCode === this.qrCodeObjectName) {
              //this.qrCodeVerified = QrCodeVerificationStatus.Match;
              this.dataService.updateQrCodeVerified(QrCodeVerificationStatus.Match);
            } else {
              //this.qrCodeVerified = QrCodeVerificationStatus.NoMatch;
              this.dataService.updateQrCodeVerified(QrCodeVerificationStatus.NoMatch);
            }
          }

          // finish using the camera
          this.qrCodeScanned = true;
          this.videoElement.pause();
          if (this.videoElement.srcObject) {
            (this.videoElement.srcObject as MediaStream).getTracks().forEach(track => track.stop());
          }
        } catch (error) {
          console.error(error);
        }
        // Process the QR code data
      }
    }
    if (!this.qrCodeScanned) {
      requestAnimationFrame(this.tick.bind(this));
    }
  }

  @Output() onClickShowPasportDetail: EventEmitter<string> = new EventEmitter();
  async showPasportDetail() {
    // get uuid from qrcode
    const result = await this.dataService.fetchApiGetPasportEntityByParams('', this.qrCode, '');
    // emit event to show pasport details
    if (result && (result.length > 0)) {
      // Emulate click on close button
      const closeButton = document.getElementById('button-close-drawer-bottom-qr-code-scanner') as HTMLElement;
      if (closeButton) {
        closeButton.click();
      }
      // TODO: hardcoded first result item. do we want to change this? possible to show some select box
      this.onClickShowPasportDetail.emit(result[0].uuid);
    }
  }
}
