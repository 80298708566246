import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { initFlowbite } from 'flowbite';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { StateManagementService } from '@services/state-management.service';
import { Subscription } from 'rxjs';
import { DataService, PasportHierarchyEntity } from '@app/services/data.service';
import { PasportEntity } from '@app/shared/interfaces/pasport-template.interface';

interface filterItemLocation {
  uuid: string;
  name: string;
  isChecked: boolean;
  formControl: FormControl;
  rvos: filterItemRvo[]
};

interface filterItemRvo {
  uuid: string;
  name: string;
  isChecked: boolean;
  formControl: FormControl;
};

@Component({
  selector: 'app-filter-pasport-rvo',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './filter-pasport-rvo.component.html',
  styleUrl: './filter-pasport-rvo.component.css'
})
export class FilterPasportRvoComponent {
  @Input() name: string = '';

  data: filterItemLocation[] = [];
  private subscriptions = new Subscription();
  inputSearchFilter: FormControl = new FormControl('');

  uniqueId: string = '';
  checkedRvos: string[] = [];

  @ViewChildren('rvoCheckbox') rvoCheckboxes!: QueryList<ElementRef>;

  constructor(
    private stateManagementService: StateManagementService,
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.uniqueId = this.name + '-' + Math.random().toString(36).substr(2, 9);
    setTimeout(() => initFlowbite(), 0); // Ensures DOM is fully loaded

    // subscribe to getPasportEntities
    this.subscriptions.add(
      this.dataService.getPasportEntities().subscribe((pasportEntities: PasportEntity[] | null) => {
        // get list of items with the type location and its children of the type rvo
        if (!pasportEntities) {
          return;
        }

        // TODO: is this enough? this might break if we switch to server side filtering
        // if data has already been set, do not set it again
        if (this.data.length > 0) {
          return;
        }

        const locationMap = new Map<string, any>();

        // FIXME: this stopped working. need to fix after we have the new pasport structure
        // for (const item of pasportEntities) {
        //   if (item.entityType.type === 'rvo' && item.entityType.parentType === 'location') {
        //     const locationUuid = item.entityType.parentUuid;
        //     if (!locationMap.has(locationUuid)) {
        //       locationMap.set(locationUuid, {
        //         uuid: locationUuid,
        //         name: item.parentData.name,
        //         isChecked: false,
        //         formControl: new FormControl(false),
        //         rvos: []
        //       });
        //     }
        //     locationMap.get(locationUuid).rvos.push({
        //       uuid: item.uuid,
        //       name: item.name,
        //       isChecked: false,
        //       formControl: new FormControl(false)
        //     });
        //   }
        // }
        // FIXME: this stopped working. need to fix after we have the new pasport structure

        this.data = Array.from(locationMap.values());
      })
    );

    // subscribe to getPasportFilterRvos - listening for changes across all components in the application
    this.subscriptions.add(
      this.stateManagementService.getPasportFilterRvos().subscribe((checkedRvos: string[]) => {
        this.checkedRvos = checkedRvos;
        // set the checked state of the RVOs
        this.data.forEach(location => {
          location.rvos.forEach(rvo => {
            rvo.formControl.setValue(checkedRvos.includes(rvo.uuid), { emitEvent: false });
          });
        });

        // handle parent location checked state. if all RVOs are checked, make sure the parent location is also checked. if at lest one RVO is unchecked, make sure the parent location is unchecked
        this.data.forEach(location => {
          const allRvosChecked = location.rvos.every(rvo => rvo.formControl.value);
          location.formControl.setValue(allRvosChecked, { emitEvent: false });
        });
      })
    );

    this.subscriptions.add(
      this.inputSearchFilter.valueChanges.subscribe((searchValue: string) => {
        // if search value is empty, deselect all checkboxes
        if (!searchValue) {
          this.data.forEach(location => {
            location.rvos.forEach(rvo => {
              rvo.formControl.setValue(false, { emitEvent: false });
            });
          });
          this.onCheckboxRvoChange();
          return;
        }

        let firstMatchFound = false;
        this.data.forEach(location => {
          location.rvos.forEach(rvo => {
            if (rvo.name.toLowerCase().includes(searchValue.toLowerCase())) {
              rvo.formControl.setValue(true, { emitEvent: false });
              if (!firstMatchFound) {
                firstMatchFound = true;
                setTimeout(() => this.scrollToFirstMatch(), 0);
              }
            } else {
              rvo.formControl.setValue(false, { emitEvent: false });
            }
          });
        });

        this.onCheckboxRvoChange();
      })
    );
  }

  ngAfterViewInit() {
    initFlowbite();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Handles the change event of a location checkbox.
   * Sets all child RVO checkboxes to the same state as the location checkbox.
   * Calls the method to handle the RVO checkboxes.
   *
   * @param event - The change event object.
   * @param location - The filterItemLocation object representing the location checkbox.
   */
  onCheckboxLocationChange(event: Event, location: filterItemLocation) {
    const isChecked = location.formControl.value; // Determine if the location checkbox is checked
    location.rvos.forEach(rvo => {
      rvo.formControl.setValue(isChecked, { emitEvent: false }); // Set all child RVO checkboxes to the same state
    });
    this.onCheckboxRvoChange(); // Call the method to handle the RVO checkboxes
  }

  /**
   * Handles the change event of the RVO checkbox.
   * Updates the parent location checked state based on the checked state of RVOs.
   * Collects the UUIDs of checked RVOs and sets them in the state management service.
   * @param event - The change event object.
   */
  onCheckboxRvoChange() {
    // first handle parent location checked state. if all RVOs are checked, make sure the parent location is also checked. if at lest one RVO is unchecked, make sure the parent location is unchecked
    this.data.forEach(location => {
      const allRvosChecked = location.rvos.every(rvo => rvo.formControl.value);
      location.formControl.setValue(allRvosChecked, { emitEvent: false });
    });

    // TODO: possible performance issues. the dataset of the filter will not be large, so it might be ok
    // get list of checked RVOs
    let checkedRvos: string[] = [];

    this.data.forEach(location => {
      location.rvos.forEach(rvo => {
        if (rvo.formControl.value) { // Checking if the FormControl associated with the RVO is checked
          checkedRvos.push(rvo.uuid); // Collecting UUIDs of checked RVOs
        }
      });
    });

    this.stateManagementService.setPasportFilterRvos(checkedRvos); // Set the list of checked RVOs in the state management service
  }


  private scrollToFirstMatch() {
    const firstMatch = this.rvoCheckboxes.find(el => el.nativeElement.checked);
    if (firstMatch) {
      firstMatch.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }


}
