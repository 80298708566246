import { Component } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService, NotificationItem, Incident } from '@services/data.service';
import { FormatElapsedTimePipe } from '@app/shared/format-elapsed-time.pipe';
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [CommonModule, FormatElapsedTimePipe],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.css'
})
export class MessagesComponent {
  notificationItems: NotificationItem[] = [];
  // refresh interval for the pipe date formatting
  refreshTrigger = interval(30000).pipe(startWith(0));

  @Output() detailClicked: EventEmitter<Incident> = new EventEmitter();
  @Output() scrollToClicked: EventEmitter<Incident> = new EventEmitter();

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.getNotificationItems().subscribe((data: NotificationItem[]) => {
      this.notificationItems = data;
    });
  }

  /**
   * Shows the details of a notification item.
   * @param item - The notification item to show the details for.
   */
  showDetails(item: NotificationItem): void {
    if (item.incident) {
      this.detailClicked.emit(item.incident);
    }
  }

  /**
   * Scrolls to the specified item.
   * @param item - The item to scroll to.
   */
  scrollTo(item: NotificationItem): void {
    if (item.incident) {
      this.scrollToClicked.emit(item.incident);
    }
  }

}
