import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MarkerAttributes, StateManagementService } from '@services/state-management.service';
import { Subscription } from 'rxjs';
import { DataService, nameMapping } from '@app/services/data.service';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css'
})
export class FilterComponent implements OnInit, OnDestroy {
  // Define an EventEmitter to emit the filter change event
  @Output() onFilterChange = new EventEmitter<void>();
  @Output() onFilterWithInitChange = new EventEmitter<boolean>();
  // TODO: we can use another EventEmitter to emit the filter change event with a payload
  // @Output() onFilterChange = new EventEmitter<{ [key: string]: boolean }>();

  // default state. will be overwritten by state management service
  mapFilterSelectOrder = new FormControl('priority_asc');
  mapFilterCheckboxLast24h = new FormControl(false);
  mapFilterCheckboxHighImportance = new FormControl(false);
  mapFilterCheckboxActiveReports = new FormControl(true);
  mapFilterCheckboxCompletedReports = new FormControl(true);
  mapFilterCheckboxStatusNew = new FormControl(true);
  mapFilterCheckboxStatusInprogress = new FormControl(true);
  mapFilterCheckboxStatusPending = new FormControl(true);
  mapFilterCheckboxStatusSolved = new FormControl(true);
  mapFilterCheckboxStatusUnresolved = new FormControl(true);
  mapFilterCheckboxStatusTerminated = new FormControl(true);
  mapFilterCheckboxSourceDvo = new FormControl(true);
  mapFilterCheckboxsourcePublic = new FormControl(true);
  mapFilterCheckboxsourceOperator = new FormControl(true);
  mapFilterCheckboxMyIncidentsAndTasks = new FormControl(true);
  // filters for pasport view
  mapFilterCheckboxLocation = new FormControl(true);
  mapFilterCheckboxRvo = new FormControl(true);
  mapFilterCheckboxRvoc = new FormControl(true);
  mapFilterCheckboxPhase = new FormControl(true);
  mapFilterCheckboxOutput = new FormControl(true);
  mapFilterCheckboxLightline = new FormControl(true);
  mapFilterCheckboxLightplace = new FormControl(true);
  mapFilterCheckboxLightpoint = new FormControl(true);
  mapFilterCheckboxTrafficCounter = new FormControl(true);
  mapFilterCheckboxSocket = new FormControl(true);
  mapFilterCheckboxSmartButton = new FormControl(true);
  mapFilterCheckboxSurfaceTemperatureSensor = new FormControl(true);
  mapFilterCheckboxSpeedometer = new FormControl(true);
  mapFilterCheckboxMotionSensor = new FormControl(true);
  mapFilterCheckboxFloodSensor = new FormControl(true);
  mapFilterCheckboxSensorModule = new FormControl(true);
  mapFilterCheckboxAgSensor = new FormControl(true);
  mapFilterCheckboxShmu = new FormControl(true);
  mapFilterCheckboxUniza = new FormControl(true);

  // filter for incident category
  mapFilterCheckboxPowerSupplyFailure = new FormControl(true);
  mapFilterCheckboxLightingFailure = new FormControl(true);
  mapFilterCheckboxDimmingFailure = new FormControl(true);
  mapFilterCheckboxCommunicationFailure = new FormControl(true);
  mapFilterCheckboxDeviceFailure = new FormControl(true);
  mapFilterCheckboxConfigurationFailure = new FormControl(true);
  mapFilterCheckboxOperationalIssue = new FormControl(true);
  mapFilterCheckboxAppearanceIssue = new FormControl(true);
  mapFilterCheckboxOtherIssue = new FormControl(true);
  // filter for device type
  mapFilterCheckboxDevicetypeLocation = new FormControl(true);
  mapFilterCheckboxDevicetypeRvo = new FormControl(true);
  mapFilterCheckboxDevicetypePhase = new FormControl(true);
  mapFilterCheckboxDevicetypeLightline = new FormControl(true);
  mapFilterCheckboxDevicetypeLightplace = new FormControl(true);
  mapFilterCheckboxDevicetypeLightpoint = new FormControl(true);
  mapFilterCheckboxDevicetypeTrafficCounter = new FormControl(true);

  mapFilterCheckboxSmartDevices = new FormControl(false);

  mapFilterFulltext = '';
  searchFulltextInName = new FormControl(false);
  searchFulltextInUuid = new FormControl(false);
  searchFulltextInData = new FormControl(false);
  searchFulltextInAll = new FormControl(false);

  nameMapping = nameMapping;

  private subscription: Subscription = new Subscription();
  private subscriptionOrder: Subscription = new Subscription();

  markersAttributes: Record<string, MarkerAttributes> = {};
  markerPasportEntityType: string = 'location';
  iconColor: string = '#2563eb';  // Default color
  iconSize: number = 10;  // Default size, adjust as needed
  markersClustering: boolean = true;
  isMarkerSettingsVisible: boolean = false;

  activeViewType: string = 'incidents';

  constructor(
    private stateManagementService: StateManagementService,
    public dataService: DataService
  ) {}

  ngOnInit() {
    this.markersAttributes = this.stateManagementService.getMarkersAttributes();
    // initialize values from state management service
    this.subscription = this.stateManagementService.getMapFilterCheckboxStates().subscribe(states => {
      this.mapFilterCheckboxLast24h.setValue(states.last24h, { emitEvent: false });
      this.mapFilterCheckboxHighImportance.setValue(states.highImportance, { emitEvent: false });
      this.mapFilterCheckboxActiveReports.setValue(states.activeReports, { emitEvent: false });
      this.mapFilterCheckboxCompletedReports.setValue(states.completedReports, { emitEvent: false });
      this.mapFilterCheckboxSourceDvo.setValue(states.sourceDvo, { emitEvent: false });
      this.mapFilterCheckboxsourcePublic.setValue(states.sourcePublic, { emitEvent: false });
      this.mapFilterCheckboxsourceOperator.setValue(states.sourceOperator, { emitEvent: false });
      this.mapFilterCheckboxMyIncidentsAndTasks.setValue(states.myIncidentsAndTasks, { emitEvent: false });

      this.mapFilterCheckboxStatusNew.setValue(states.statusNew, { emitEvent: false });
      this.mapFilterCheckboxStatusInprogress.setValue(states.statusInprogress, { emitEvent: false });
      this.mapFilterCheckboxStatusPending.setValue(states.statusPending, { emitEvent: false });
      this.mapFilterCheckboxStatusSolved.setValue(states.statusSolved, { emitEvent: false });
      this.mapFilterCheckboxStatusUnresolved.setValue(states.statusUnresolved, { emitEvent: false });
      this.mapFilterCheckboxStatusTerminated.setValue(states.statusTerminated, { emitEvent: false });
      // pasport view filters
      this.mapFilterCheckboxLocation.setValue(states.location, { emitEvent: false });
      this.mapFilterCheckboxRvo.setValue(states.rvo, { emitEvent: false });
      this.mapFilterCheckboxRvoc.setValue(states.rvoc, { emitEvent: false });
      this.mapFilterCheckboxPhase.setValue(states.phase, { emitEvent: false });
      this.mapFilterCheckboxOutput.setValue(states.output, { emitEvent: false });
      this.mapFilterCheckboxLightline.setValue(states.lightline, { emitEvent: false });
      this.mapFilterCheckboxLightplace.setValue(states.lightplace, { emitEvent: false });
      this.mapFilterCheckboxLightpoint.setValue(states.lightpoint, { emitEvent: false });
      this.mapFilterCheckboxTrafficCounter.setValue(states.trafficCounter, { emitEvent: false });
      this.mapFilterCheckboxSocket.setValue(states.socket, { emitEvent: false });
      this.mapFilterCheckboxSmartButton.setValue(states.smartButton, { emitEvent: false });
      this.mapFilterCheckboxSurfaceTemperatureSensor.setValue(states.surfaceTemperatureSensor, { emitEvent: false });
      this.mapFilterCheckboxSpeedometer.setValue(states.speedometer, { emitEvent: false });
      this.mapFilterCheckboxMotionSensor.setValue(states.motionSensor, { emitEvent: false });
      this.mapFilterCheckboxFloodSensor.setValue(states.floodSensor, { emitEvent: false });
      this.mapFilterCheckboxSensorModule.setValue(states.sensorModule, { emitEvent: false });
      this.mapFilterCheckboxAgSensor.setValue(states.agSensor, { emitEvent: false });
      this.mapFilterCheckboxShmu.setValue(states.shmu, { emitEvent: false });
      this.mapFilterCheckboxUniza.setValue(states.uniza, { emitEvent: false });

      // filter for incident category
      this.mapFilterCheckboxPowerSupplyFailure.setValue(states.powerSupplyFailure, { emitEvent: false });
      this.mapFilterCheckboxLightingFailure.setValue(states.lightingFailure, { emitEvent: false });
      this.mapFilterCheckboxDimmingFailure.setValue(states.dimmingFailure, { emitEvent: false });
      this.mapFilterCheckboxCommunicationFailure.setValue(states.communicationFailure, { emitEvent: false });
      this.mapFilterCheckboxDeviceFailure.setValue(states.deviceFailure, { emitEvent: false });
      this.mapFilterCheckboxConfigurationFailure.setValue(states.configurationFailure, { emitEvent: false });
      this.mapFilterCheckboxOperationalIssue.setValue(states.operationalIssue, { emitEvent: false });
      this.mapFilterCheckboxAppearanceIssue.setValue(states.appearanceIssue, { emitEvent: false });
      this.mapFilterCheckboxOtherIssue.setValue(states.otherIssue, { emitEvent: false });
      // filter for device type
      this.mapFilterCheckboxDevicetypeLocation.setValue(states.devicetypeLocation, { emitEvent: false });
      this.mapFilterCheckboxDevicetypeRvo.setValue(states.devicetypeRvo, { emitEvent: false });
      this.mapFilterCheckboxDevicetypePhase.setValue(states.devicetypePhase, { emitEvent: false });
      this.mapFilterCheckboxDevicetypeLightline.setValue(states.devicetypeLightline, { emitEvent: false });
      this.mapFilterCheckboxDevicetypeLightplace.setValue(states.devicetypeLightplace, { emitEvent: false });
      this.mapFilterCheckboxDevicetypeLightpoint.setValue(states.devicetypeLightpoint, { emitEvent: false });
      this.mapFilterCheckboxDevicetypeTrafficCounter.setValue(states.devicetypeTrafficCounter, { emitEvent: false });

      this.searchFulltextInName.setValue(states.searchFulltextInName, { emitEvent: false });
      this.searchFulltextInUuid.setValue(states.searchFulltextInUuid, { emitEvent: false });
      this.searchFulltextInData.setValue(states.searchFulltextInData, { emitEvent: false });
    });

    this.subscriptionOrder = this.stateManagementService.getMapFilterOrderState().subscribe(orderState => {
      this.mapFilterSelectOrder.setValue(`${orderState.orderField}_${orderState.orderDirection}`, { emitEvent: false });
    });

    // FIXME: use subscription variable, so we can unsubscribe
    this.stateManagementService.getActiveViewType().subscribe(activeViewType => {
      this.activeViewType = activeViewType;
    });

    this.stateManagementService.getMapFilterFulltextState().subscribe(fulltextState => {
      this.mapFilterFulltext = fulltextState;
    });

    // Subscribe to value changes and update the state
    this.subscribeToCheckboxChanges();
    this.subscribeToOrderChanges();
    this.updateSmartDevicesCheckbox();
    this.updateSearchFulltextInAllCheckbox();
  }

  private subscribeToCheckboxChanges() {
    this.mapFilterCheckboxLast24h.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('last24h', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxHighImportance.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('highImportance', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxActiveReports.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('activeReports', value ?? false);
      this.onFilterChange.emit();
      // TODO: some alternative
      // this.onFilterChange.emit(this.stateManagementService.getMapFilterCheckboxStates().getValue());
    });
    this.mapFilterCheckboxCompletedReports.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('completedReports', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxStatusNew.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('statusNew', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxStatusInprogress.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('statusInprogress', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxStatusPending.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('statusPending', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxStatusSolved.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('statusSolved', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxStatusUnresolved.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('statusUnresolved', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxStatusTerminated.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('statusTerminated', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxSourceDvo.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('sourceDvo', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxsourcePublic.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('sourcePublic', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxsourceOperator.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('sourceOperator', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxMyIncidentsAndTasks.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('myIncidentsAndTasks', value ?? false);
      this.onFilterChange.emit();
    });
    // pasport view filters
    this.mapFilterCheckboxLocation.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('location', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxRvo.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('rvo', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxRvoc.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('rvoc', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxPhase.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('phase', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxOutput.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('output', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxLightline.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('lightline', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxLightplace.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('lightplace', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxLightpoint.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('lightpoint', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxTrafficCounter.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('trafficCounter', value ?? false);
      this.onFilterChange.emit();
      this.updateSmartDevicesCheckbox();
    });
    this.mapFilterCheckboxSmartButton.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('smartButton', value ?? false);
      this.onFilterChange.emit();
      this.updateSmartDevicesCheckbox();
    });
    this.mapFilterCheckboxSurfaceTemperatureSensor.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('surfaceTemperatureSensor', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxSpeedometer.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('speedometer', value ?? false);
      this.onFilterChange.emit();
      this.updateSmartDevicesCheckbox();
    });
    this.mapFilterCheckboxMotionSensor.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('motionSensor', value ?? false);
      this.onFilterChange.emit();
      this.updateSmartDevicesCheckbox();
    });
    this.mapFilterCheckboxFloodSensor.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('floodSensor', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxSensorModule.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('sensorModule', value ?? false);
      this.onFilterChange.emit();
      this.updateSmartDevicesCheckbox();
    });
    this.mapFilterCheckboxAgSensor.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('agSensor', value ?? false);
      this.onFilterChange.emit();
      this.updateSmartDevicesCheckbox();
    });
    this.mapFilterCheckboxShmu.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('shmu', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxUniza.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('uniza', value ?? false);
      this.onFilterChange.emit();
    });

    // filter for incident category
    this.mapFilterCheckboxPowerSupplyFailure.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('powerSupplyFailure', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxLightingFailure.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('lightingFailure', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDimmingFailure.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('dimmingFailure', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxCommunicationFailure.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('communicationFailure', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDeviceFailure.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('deviceFailure', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxConfigurationFailure.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('configurationFailure', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxOperationalIssue.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('operationalIssue', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxAppearanceIssue.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('appearanceIssue', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxOtherIssue.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('otherIssue', value ?? false);
      this.onFilterChange.emit();
    });
    // filter for device type
    this.mapFilterCheckboxDevicetypeLocation.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('devicetypeLocation', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDevicetypeRvo.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('devicetypeRvo', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDevicetypePhase.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('devicetypePhase', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDevicetypeLightline.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('devicetypeLightline', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDevicetypeLightplace.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('devicetypeLightplace', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDevicetypeLightpoint.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('devicetypeLightpoint', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxDevicetypeTrafficCounter.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('devicetypeTrafficCounter', value ?? false);
      this.onFilterChange.emit();
    });
    this.mapFilterCheckboxSocket.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('socket', value ?? false);
      this.onFilterChange.emit();
      this.updateSmartDevicesCheckbox();
    });

    // search fulltext in data, name, uuid
    this.searchFulltextInData.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInData', value ?? false);
      if (this.mapFilterFulltext && this.mapFilterFulltext.length > 0) {
        this.onFilterChange.emit();
      }
      this.updateSearchFulltextInAllCheckbox();
    });
    this.searchFulltextInName.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInName', value ?? false);
      if (this.mapFilterFulltext && this.mapFilterFulltext.length > 0) {
        this.onFilterChange.emit();
      }
      this.updateSearchFulltextInAllCheckbox();
    });
    this.searchFulltextInUuid.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInUuid', value ?? false);
      if (this.mapFilterFulltext && this.mapFilterFulltext.length > 0) {
        this.onFilterChange.emit();
      }
      this.updateSearchFulltextInAllCheckbox();
    });
  }

  private subscribeToOrderChanges() {
    this.mapFilterSelectOrder.valueChanges.subscribe(value => {
      const [orderField, orderDirection] = value?.split('_') ?? ['', ''];
      this.stateManagementService.setMapFilterOrderState(orderField as 'name' | 'evidenceDate' | 'lastUpdateDate' | 'state', orderDirection as 'asc' | 'desc');
      this.onFilterChange.emit();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionOrder.unsubscribe();
  }

  /**
   * Toggles the state of smart devices checkboxes based on the event.
   * If the event indicates that the smart devices should be checked, all related checkboxes will be set to true.
   * Otherwise, all related checkboxes will be set to false.
   *
   * @param event - The event triggered by the toggle action.
   */
  toggleSmartDevices(event: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked === true) {
      this.mapFilterCheckboxTrafficCounter.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('trafficCounter', true);
      this.mapFilterCheckboxSpeedometer.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('speedometer', true);
      this.mapFilterCheckboxSocket.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('socket', true);
      this.mapFilterCheckboxSmartButton.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('smartButton', true);
      this.mapFilterCheckboxSensorModule.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('sensorModule', true);
      this.mapFilterCheckboxMotionSensor.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('motionSensor', true);
      this.mapFilterCheckboxAgSensor.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('agSensor', true);

      this.mapFilterCheckboxShmu.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('shmu', true);
      this.mapFilterCheckboxUniza.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('uniza', true);
    } else {
      this.mapFilterCheckboxTrafficCounter.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('trafficCounter', false);
      this.mapFilterCheckboxSpeedometer.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('speedometer', false);
      this.mapFilterCheckboxSocket.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('socket', false);
      this.mapFilterCheckboxSmartButton.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('smartButton', false);
      this.mapFilterCheckboxSensorModule.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('sensorModule', false);
      this.mapFilterCheckboxMotionSensor.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('motionSensor', false);
      this.mapFilterCheckboxAgSensor.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('agSensor', false);

      this.mapFilterCheckboxShmu.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('shmu', false);
      this.mapFilterCheckboxUniza.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('uniza', false);
    }
    this.onFilterChange.emit();
  }

  /**
   * Updates the state of the Smart Devices checkbox based on the individual smart device checkboxes.
   * If all individual smart device checkboxes are checked, the Smart Devices checkbox will be set to true.
   * Otherwise, it will be set to false.
   */
  private updateSmartDevicesCheckbox() {
    const allChecked = this.mapFilterCheckboxTrafficCounter.value &&
                       this.mapFilterCheckboxSpeedometer.value &&
                       this.mapFilterCheckboxSocket.value &&
                       this.mapFilterCheckboxSmartButton.value &&
                       this.mapFilterCheckboxSensorModule.value &&
                       this.mapFilterCheckboxMotionSensor.value &&
                       this.mapFilterCheckboxAgSensor.value;

    this.mapFilterCheckboxSmartDevices.setValue(allChecked, { emitEvent: false });
  }

  /**
   * Toggles all fulltext search filters based on the "Search All" checkbox state.
   * When checked, enables searching in data, name and UUID fields.
   * When unchecked, disables searching in all fields.
   * @param event The checkbox change event
   */
  toggleSearchFulltextInAll(event: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked === true) {
      this.searchFulltextInData.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInData', true);
      this.searchFulltextInName.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInName', true);
      this.searchFulltextInUuid.setValue(true, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInUuid', true);
    } else {
      this.searchFulltextInData.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInData', false);
      this.searchFulltextInName.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInName', false);
      this.searchFulltextInUuid.setValue(false, { emitEvent: false });
      this.stateManagementService.setMapFilterCheckboxState('searchFulltextInUuid', false);
    }
  }

  /**
   * Updates the state of the "Search All" checkbox based on individual search field checkboxes.
   * Sets "Search All" to checked only if all individual search fields (data, name, UUID) are checked.
   */
  updateSearchFulltextInAllCheckbox() {
    const allChecked = this.searchFulltextInData.value &&
                       this.searchFulltextInName.value &&
                       this.searchFulltextInUuid.value;
    this.searchFulltextInAll.setValue(allChecked, { emitEvent: false });
  }

  onColorClick(event: MouseEvent, entityType: string): void {
    event.preventDefault();
    event.stopPropagation();

    this.markerPasportEntityType = entityType;
    this.markersAttributes = this.stateManagementService.getMarkersAttributes();
    this.iconColor = this.markersAttributes[this.markerPasportEntityType].color;
    this.iconSize = this.markersAttributes[this.markerPasportEntityType].iconSize;
    this.markersClustering = this.stateManagementService.getMarkersClustering();

    this.isMarkerSettingsVisible = true;
  }

  updatePasportMarkerStyles(): void {
    this.stateManagementService.setMarkersAttributes(this.markerPasportEntityType, { color: this.iconColor, iconSize: this.iconSize });
    this.markersAttributes = this.stateManagementService.getMarkersAttributes();
    // emit event to update markers
    this.onFilterChange.emit();
  }

  updatePasportMarkersClustering(): void {
    console.log('updatePasportMarkersClustering', this.markersClustering);
    this.stateManagementService.setMarkersClustering(this.markersClustering);
    this.markersClustering = this.stateManagementService.getMarkersClustering();
    // emit event to update markers
    this.onFilterWithInitChange.emit();
  }
}
