import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';

interface ImportanceChangeEvent {
  value: number;
}

@Component({
  selector: 'app-importance-select',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './importance-select.component.html',
  styleUrl: './importance-select.component.css'
})
export class ImportanceSelectComponent {
  @Input() importance: number | undefined = 0;
  @Output() importanceChange: EventEmitter<ImportanceChangeEvent> = new EventEmitter<ImportanceChangeEvent>();

  constructor(
  ) { }

  ngOnInit() {
    if (!this.importance) {
      this.importance = 0;
    }
  }

  /**
   * Handles the change event of the importance select component.
   * @param newAgentValue - The new value selected for the importance.
   */
  onImportanceChange(newAgentValue: number) {
    // Create the importance change event object
    const importanceChangeEvent: ImportanceChangeEvent = {
      value: newAgentValue
    };

    // Emit the new agent value
    this.importanceChange.emit(importanceChangeEvent);
  }

}
