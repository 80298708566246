<div class="z-100 fixed w-full h-full left-0 top-0 bottom-0 right-0 bg-white isolate">
  <img [src]="imgSrc" class="w-full h-full object-contain" alt="Náhľad obrázka">

  <button type="button" (click)="closeImagePreview()"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white
    md:top-2.5">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>
</div>