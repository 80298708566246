import { environment } from '@environments/environment';
import { Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataService, QrCodeVerificationStatus, Task, Agent, Incident, MetricsTemp, MetricsGraphItem, MetricsRvo, MetricsGraphRvoItem, PasportItemFile, PasportItem } from '@services/data.service';
import { AgentSelectComponent } from '@app/features/incidents/agent-select/agent-select.component';
import { ImagePreviewComponent } from '@app/features/incidents/image-preview/image-preview.component';
import { ImportanceSelectComponent } from '@app/features/incidents/importance-select/importance-select.component';
import { DeviceControlComponent } from '@app/features/incidents/device-control/device-control.component';
import { DeviceHierarchyComponent } from '@app/features/incidents/device-hierarchy/device-hierarchy.component';
import ApexCharts from 'apexcharts';
import { FormatElapsedTimePipe } from '@app/shared/format-elapsed-time.pipe';
import { Subscription } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

interface ApexChartSeriesItem {
  x: number; // Timestamp for ApexCharts
  y: number; // Power value for ApexCharts
}

// TODO: this might go to a shared file, as it is used in multiple components details.component.ts
interface AgentChangeEvent {
  type: string;
  id: number;
  value: string;
}

interface ImportanceChangeEvent {
  value: number;
}

interface MaintenanceDocument {
  id: string;
  entityUuid: string;
  name: string;
  url: string;
  file: any;
  type: string;
  isSelected: boolean;
  safeUrl: SafeUrl | null;
  creationDate: Date | null;
  tags: any;
}

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [CommonModule, FormsModule, AgentSelectComponent, FormatElapsedTimePipe, ImagePreviewComponent, ImportanceSelectComponent, DeviceControlComponent, HttpClientModule, DeviceHierarchyComponent],
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.css'
})
export class MaintenanceComponent {
  private subscriptions = new Subscription();

  task: Task | null = null;
  QrCodeVerificationStatus = QrCodeVerificationStatus; // make the enum available in the template
  qrCodeVerified: QrCodeVerificationStatus = QrCodeVerificationStatus.NotTested;
  metricsTemp: MetricsTemp | null = null;
  metricsRvo: MetricsRvo | null = null;
  metricsGraph: MetricsGraphItem[] = [];
  metricsGraphRvo: MetricsGraphRvoItem[] = [];
  poolingMetricsCounter: number = 0;
  state: string; // Property bound to the select element
  comment: string = '';
  agents: Agent[] = [];
  newTaskAgent: string = '';
  newTaskAgentId: number = -1;
  newTaskAgentEmail: string = '';
  newTaskImportance: number = -1;

  chart: ApexCharts | null = null;

  // TODO: make this configurable / dynamic
  seriesLCPower: ApexChartSeriesItem[] = [];
  seriesLCCosPhy: ApexChartSeriesItem[] = [];
  seriesLCCurrent: ApexChartSeriesItem[] = [];
  seriesLCDimming: ApexChartSeriesItem[] = [];
  seriesLCVoltage: ApexChartSeriesItem[] = [];
  seriesLCTemperature: ApexChartSeriesItem[] = [];

  seriesRvoVCC: ApexChartSeriesItem[] = [];
  seriesRvoVBAT: ApexChartSeriesItem[] = [];

  // documents already assigned to the task / incident / device
  maintenanceDocuments: MaintenanceDocument[] = [];
  // documents selected from device and ready to be uploaded to the server
  maintenanceDocumentsNew: MaintenanceDocument[] = [];
  // number of documents that are highlighted (selected) for deletion
  maintenanceDocumentsHighlightedCount: number = 0;
  // highlighted document
  maintenanceDocumentHighlighted: MaintenanceDocument | null = null;
  // indicator for processing the delete documents request
  isProcessingDocumentsRequest: boolean = false;
  // pasport entity hierarchy
  pasportEntityParents: PasportItem[] = [];
  pasportEntityChildren: PasportItem[] = [];

  public imageUrl: SafeUrl | null = null;  // Store as SafeUrl

  @ViewChild('imagePreviewContainer', { read: ViewContainerRef }) container: ViewContainerRef | undefined;

  constructor(
    public dataService: DataService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.state = this.dataService.taskStates[0].value;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.getTask().subscribe(async (data: Task | null) => {
        this.task = data;
        this.state = this.task?.state || this.dataService.taskStates[0].value;
        this.comment = this.task?.comment || '';
        this.newTaskAgent = this.task?.agent || '';
        this.newTaskImportance = this.task?.importance ?? 0;
        this.refreshDocuments();
      })
    );
    this.subscriptions.add(
      this.dataService.getQrCodeVerified().subscribe((data: QrCodeVerificationStatus) => {
        this.qrCodeVerified = data;
      })
    );
    this.subscriptions.add(
      this.dataService.getMetricsTemp().subscribe((data: MetricsTemp | null) => {
        this.metricsTemp = data;
      })
    );
    this.subscriptions.add(
      this.dataService.getMetricsRvo().subscribe((data: MetricsRvo | null) => {
        this.metricsRvo = data;
      })
    );
    this.subscriptions.add(
      this.dataService.getMetricsGraph().subscribe((data: MetricsGraphItem[]) => {
        this.metricsGraph = data;
        this.showMetricsTemp();
      })
    );
    this.subscriptions.add(
      this.dataService.getMetricsGraphRvo().subscribe((data: MetricsGraphRvoItem[]) => {
        this.metricsGraphRvo = data;
        this.showMetricsTemp();
      })
    );
    this.subscriptions.add(
      this.dataService.getPoolingMetricsCounter().subscribe((data: number) => {
        this.poolingMetricsCounter = data;
      })
    );
    this.subscriptions.add(
      this.dataService.getAgents().subscribe((data: Agent[]) => {
        this.agents = data;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    // TODO: not needed
    setTimeout(() => {
      this.showMetricsTemp();
    }, 500);
  }

  @Output() qrCodeScannerClicked: EventEmitter<Task> = new EventEmitter();
  showQrCodeScanner(task: Task) {
    this.qrCodeScannerClicked.emit(task);
  }

  async saveTask(): Promise<void> {
    await this.dataService.fetchApiPatchTask(this.task!, this.state, this.comment);
  }

  async refreshMetrics(): Promise<void> {
    await this.dataService.fetchApiGetMetricsTemp(this.task!);
  }

  async refreshMetricsGraph(): Promise<void> {
    await this.dataService.fetchApiGetMetricsGraph(this.task!);
  }

  showMetricsTemp(): boolean {
    if (!this.metricsGraphRvo || !this.metricsGraphRvo.length) {
      return false;
    }

    let series = [];

    // Clear the series arrays
    this.seriesLCPower = [];
    this.seriesLCCosPhy = [];
    this.seriesLCCurrent = [];
    this.seriesLCDimming = [];
    this.seriesLCVoltage = [];
    this.seriesLCTemperature = [];
    this.seriesRvoVCC = [];
    this.seriesRvoVBAT = [];
    // graph data for type LC
    if (this.task?.deviceTypeName?.toLowerCase() === 'lightpoint') {
      for (let i = 0; i < this.metricsGraph.length; i++) {
        this.seriesLCPower.push({
          x: this.metricsGraph[i].data.ts,
          y: this.metricsGraph[i].data.data.power
        });
        this.seriesLCCosPhy.push({
          x: this.metricsGraph[i].data.ts,
          y: this.metricsGraph[i].data.data.cosPhy
        });
        this.seriesLCCurrent.push({
          x: this.metricsGraph[i].data.ts,
          y: this.metricsGraph[i].data.data.current
        });
        this.seriesLCDimming.push({
          x: this.metricsGraph[i].data.ts,
          y: this.metricsGraph[i].data.data.dimming
        });
        this.seriesLCVoltage.push({
          x: this.metricsGraph[i].data.ts,
          y: this.metricsGraph[i].data.data.voltage
        });

        this.seriesLCTemperature.push({
          x: this.metricsGraph[i].data.ts,
          y: this.metricsGraph[i].data.data.temperature
        });
      }

      series.push({ name: 'Spotreba', data: this.seriesLCPower, color: "#3B82F6" });
    }

    if (this.task?.deviceTypeName?.toLowerCase() === 'rvo') {
      for (let i = 0; i < this.metricsGraphRvo.length; i++) {
        this.seriesRvoVCC.push({
          x: this.metricsGraphRvo[i].data.ts,
          y: this.metricsGraphRvo[i].data.data.VCC
        });
        this.seriesRvoVBAT.push({
          x: this.metricsGraphRvo[i].data.ts,
          y: this.metricsGraphRvo[i].data.data.VBAT
        });
      }
      series.push({ name: 'Napájacie napätie (VCC)', data: this.seriesRvoVCC, color: "#3B82F6" });
    }

    let optionsChart = {
      chart: {
        height: "100%",
        maxWidth: "100%",
        type: "line",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
          format: 'dd.MM.yyyy HH:mm'
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        curve: 'smooth'
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -26
        },
      },
      series: series,
      legend: {
        show: false
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
          }
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        // labels: {
        //   show: true,
        //   style: {
        //     fontFamily: "Inter, sans-serif",
        //     cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
        //   }
        // }
      },
      noData: {
        text: 'Pripravujem dáta...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400',
          fontFamily: "Inter, sans-serif",
        }
      },
    }

    if (this.chart) {
      this.chart.destroy();
      this.chart = new ApexCharts(document.getElementById("line-chart"), optionsChart);
      this.chart.render();
    } else {
      if (document.getElementById("line-chart") && typeof ApexCharts !== 'undefined') {
        this.chart = new ApexCharts(document.getElementById("line-chart"), optionsChart);
        this.chart.render();
      }
    }
    return true;
  }

  onRadioChange(event: Event) {
    let selectedValue = (event.target as HTMLInputElement).value;
    switch (selectedValue) {
      case 'power':
        this.chart?.updateSeries([{ name: 'Spotreba', data: this.seriesLCPower, color: "#3B82F6" }]);
        break;
      case 'cosPhy':
        this.chart?.updateSeries([{ name: 'cosPhy', data: this.seriesLCCosPhy, color: "#008000" }]);
        break;
      case 'current':
        this.chart?.updateSeries([{ name: 'Prúd', data: this.seriesLCCurrent, color: "#8c65b9" }]);
        break;
      case 'dimming':
        this.chart?.updateSeries([{ name: 'Stmievanie', data: this.seriesLCDimming, color: "#808080" }]);
        break;
      case 'voltage':
        this.chart?.updateSeries([{ name: 'Napätie', data: this.seriesLCVoltage, color: "#FCD34D" }]);
        break;
      case 'temperature':
        this.chart?.updateSeries([{ name: 'Teplota', data: this.seriesLCTemperature, color: "#FFA500" }]);
        break;
      case 'VCC':
        this.chart?.updateSeries([{ name: 'Napájacie napätie (VCC)', data: this.seriesRvoVCC, color: "#3B82F6" }]);
        break;
      case 'VBAT':
        this.chart?.updateSeries([{ name: 'Napätie batérie (VBAT)', data: this.seriesRvoVBAT, color: "#008000" }]);
        break;
      default:
        break;
    }
  }

  async handleTaskAgentChange(event: AgentChangeEvent) {
    const agent = this.agents.find(item => item.email === event.value);
    this.newTaskAgent = agent?.email || '';
    this.newTaskAgentId = agent?.id || -1;
    this.newTaskAgentEmail = agent?.email || '';
  }

  async handleImportanceChange(event: ImportanceChangeEvent) {
    this.newTaskImportance = event.value;
  }

  async saveTaskAgent() {
    if (this.task && this.newTaskAgentEmail) {
      //await this.dataService.fetchApiPatchTaskAgent(this.task.id, this.newTaskAgentId);
      await this.dataService.fetchApiPatchTaskAgent(this.task.id, this.newTaskAgentEmail);
      await this.dataService.fetchApiGetIncidentTasks(this.task.id);
    }
  }

  async saveTaskImportance() {
    if (this.task && this.newTaskImportance >= 0) {
      await this.dataService.fetchApiPatchTaskImportance(this.task.id, this.newTaskImportance);
    }
  }

  /**
   * Handles the event when a camera image is captured.
   * @param event - The event object containing the captured image file.
   */
  onCameraImageCapture(event: any) {
    const file = event.target.files[0];
    const fileType = file.type;
    let documentType = 'other';

    if (fileType.startsWith('image/')) {
      documentType = 'image';
    } else if (fileType === 'application/pdf') {
      documentType = 'pdf';
    } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      documentType = 'xls';
    } else if (fileType === 'application/xml' || fileType === 'text/xml') {
      documentType = 'xml';
    } else if (fileType === 'application/json') {
      documentType = 'json';
    } else if (fileType === 'text/plain') {
      documentType = 'txt';
    }

    // TODO: make UI warning
    if (documentType !== 'image') {
      console.error('The selected file is not an image.');
      return;
    }

    const imageURL = URL.createObjectURL(file);
    this.maintenanceDocumentsNew.push({
      id: '',
      entityUuid: '',
      name: file.name,
      url: imageURL,
      file: file,
      type: 'image',
      isSelected: false,
      safeUrl: null,
      creationDate: null,
      tags: {}
    });
  }

  /**
   * Handles the selection of a storage file.
   *
   * @param event - The event object containing information about the selected file.
   */
  onStorageFileSelect(event: any) {
    const file = event.target.files[0];
    const fileType = file.type;
    let documentType = 'other';

    if (fileType.startsWith('image/')) {
      documentType = 'image';
    } else if (fileType === 'application/pdf') {
      documentType = 'pdf';
    } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      documentType = 'xls';
    } else if (fileType === 'application/xml' || fileType === 'text/xml') {
      documentType = 'xml';
    } else if (fileType === 'application/json') {
      documentType = 'json';
    } else if (fileType === 'text/plain') {
      documentType = 'txt';
    }

    const imageURL = URL.createObjectURL(file);
    this.maintenanceDocumentsNew.push({
      id: '',
      entityUuid: '',
      name: file.name,
      url: imageURL,
      file: file,
      type: documentType,
      isSelected: false,
      safeUrl: null,
      creationDate: null,
      tags: {}
    });
  }

  /**
   * Uploads the maintenance documents.
   *
   * @remarks
   * This method shows a validation message if the task object ID is not available.
   * It then validates the responses and uploads the documents using the data service.
   * Finally, it refreshes the documents.
   *
   * @returns A Promise that resolves when the documents are uploaded.
   */
  async uploadDocuments() {
    // TODO: show validation message
    if (!this.task?.objectId) {
      return;
    }
    // show processing indicator
    this.isProcessingDocumentsRequest = true;
    // Artificial delay for UI while processing requests
    await new Promise(resolve => setTimeout(resolve, environment.UI_PROCESSING_DELAY));
    const responses = await Promise.all(
      this.maintenanceDocumentsNew.map(
        (doc) => {
          let tags = ``;
          if (this.task?.id) {
            tags += `taskId:${this.task.id}`;
          }
          if (this.task?.incidentId) {
            tags += (tags.length > 0 ? `,`:``) + `incidentId:${this.task.incidentId}`;
          }
          if (doc.tags['comment']) {
            tags += (tags.length > 0 ? `,`:``) + `comment:${doc.tags['comment']}`;
          }
          this.dataService.fetchApiPostPasportEntityFile(this.task?.objectId ?? '', tags, doc.file)
        }
      )
    );
    // TODO: validate the responses

    this.isProcessingDocumentsRequest = false;
    // refresh list of documnets
    this.refreshDocuments();
  }

  /**
   * Deletes the selected documents.
   * - Removes unsaved documents that are not selected.
   * - Removes all saved documents that are selected.
   * - Validates the responses from the API.
   * - Refreshes the list of documents.
   */
  async deleteDocuments() {
    this.maintenanceDocuments.map(doc => {
    });

    // show processing indicator
    this.isProcessingDocumentsRequest = true;
    // Artificial delay for UI while processing requests
    await new Promise(resolve => setTimeout(resolve, environment.UI_PROCESSING_DELAY));
    // remove unsaved documents
    this.maintenanceDocumentsNew = this.maintenanceDocumentsNew.filter(doc => !doc.isSelected);

    // remove all saved documents that are highlighted
    const responses = await Promise.all(
      this.maintenanceDocuments
      .filter(doc => doc.isSelected)
      .map(doc => this.dataService.fetchApiDeletePasportEntityFile(doc.entityUuid, doc.id))
    );
    // TODO: validate responses

    this.isProcessingDocumentsRequest = false;
    // refresh list of documnets
    await this.refreshDocuments();
  }

  /**
   * Refreshes the list of maintenance documents.
   * If the task object ID is available, it fetches the list of PasportItemFile objects
   * using the data service and updates the maintenanceDocuments array accordingly.
   * It also updates the maintenanceDocumentsHighlightedCount based on the selected documents.
   */
  private async refreshDocuments() {
    if (this.task?.objectId) {
      let result: PasportItemFile[] = await this.dataService.fetchApiGetPasportEntityFileList(this.task.objectId);
      this.maintenanceDocumentsNew = [];
      this.maintenanceDocuments = [];

      for (let i = 0; i < result.length; i++) {
        this.maintenanceDocuments.push({
          id: result[i].id,
          entityUuid: result[i].entityUuid,
          name: result[i].name,
          // TODO: ??? we can use result[i].entityUuid for pasport entity description, but this.task?.objectId should also be fine
          url: `${environment.URL_PASPORT_FILES.replace('{id}', this.task?.objectId) }/${result[i].id}`,
          file: null,
          type: 'image',
          isSelected: false,
          safeUrl: await this.dataService.fetchApiGetPasportEntityFile(this.task?.objectId || '', result[i].id),
          creationDate: ( result[i].creationDate ? new Date(result[i].creationDate) : null ),
          tags: result[i].tags || {}
        });
      }

      // set the number of highlighted documents to 0, we could just set to 0, but we want to make sure that the number is correct
      this.maintenanceDocumentsHighlightedCount = this.maintenanceDocuments.length > 0 ? this.maintenanceDocuments.filter(doc => doc.isSelected).length + this.maintenanceDocumentsNew.filter(doc => doc.isSelected).length : 0;
    }
  }

  /**
   * Toggles the highlight status of a maintenance document.
   *
   * @param document - The maintenance document to toggle the highlight status for.
   */
  public toggleDocumentHighlight(document: MaintenanceDocument) {
    document.isSelected = !document.isSelected;
    // deselect all other documents
    this.maintenanceDocuments.forEach(doc => {
      if (doc !== document) {
        doc.isSelected = false;
      }
    });
    // deselect all new documents
    this.maintenanceDocumentsNew.forEach(doc => {
      if (doc !== document) {
        doc.isSelected = false;
      }
    });

    if (document.isSelected) {
      this.maintenanceDocumentHighlighted = document;
    } else {
      this.maintenanceDocumentHighlighted = null;
    }

    // update the number of highlighted documents - consists of documents and also new documents
    this.maintenanceDocumentsHighlightedCount = this.maintenanceDocuments.length > 0 ? this.maintenanceDocuments.filter(doc => doc.isSelected).length + this.maintenanceDocumentsNew.filter(doc => doc.isSelected).length : 0;
  }

  /**
   * Displays an image preview by creating a dynamic component and setting the image source.
   * @param src - The source of the image to be displayed.
   */
  public showImagePreview(src: SafeUrl | undefined | null) {
    if (!src) {
      return;
    }

    if (this.container) {
      const componentRef = this.container.createComponent(ImagePreviewComponent);
      componentRef.instance.imgSrc = src;

      // Subscribe to the close event
      componentRef.instance.close.subscribe(() => {
        // Destroy the component
        componentRef.destroy();
      });
    }
  }


  // loadImage(url: string) {
  //   //const url = 'https://liafe.cloudmv.online/pasportbeng/api/v1/entities/fd73fb2a-0a57-43d6-b11c-9d69dfddb0e8/files/a5352858-b2d2-4b4b-8a7f-1cab3871e157';
  //   const token = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpaWx1Vjh0dUtFeTRqbEQxNGVrb09IVWdVNW51SjJPRTBneW5xVEh4dEFZIn0.eyJleHAiOjE3MTk5MjcwODQsImlhdCI6MTcxOTkyNjE4NCwianRpIjoiODM1ZmJlMTgtZWFjNC00NWQ0LWE1NmQtZDA0NDdkMzM4Y2MwIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLXNlcnZpY2UvcmVhbG1zL2lzLXJlYWxtIiwiYXVkIjpbImxpYSIsInBhc3BvcnQiLCJhY2NvdW50Il0sInN1YiI6ImY3NjU4ZjM3LTAyYTAtNDNmZS04MzA4LWQxYmQ4ZmRiZWYxNyIsInR5cCI6IkJlYXJlciIsImF6cCI6ImF1dGgiLCJzZXNzaW9uX3N0YXRlIjoiNTQ2OTY2OWMtOTZkMy00ZTE2LTliYjAtMzc0NDI1NjAyYmMxIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIvKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1pcy1yZWFsbSIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJsaWEiOnsicm9sZXMiOlsiYWdlbnQiXX0sInBhc3BvcnQiOnsicm9sZXMiOlsiYWdlbnQiXX0sImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsInNpZCI6IjU0Njk2NjljLTk2ZDMtNGUxNi05YmIwLTM3NDQyNTYwMmJjMSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiVGVzdHVzZXIgUG9ydWNoeSIsInByZWZlcnJlZF91c2VybmFtZSI6InRlc3R1c2VyLnBvcnVjaHlAaXNzay5zayIsImdpdmVuX25hbWUiOiJUZXN0dXNlciIsImZhbWlseV9uYW1lIjoiUG9ydWNoeSIsImVtYWlsIjoidGVzdC51c2VyLnBvcnVjaHlAaXNzay5zayJ9.U_OGu-rhPO6qKhZt_CweocptHwXvf_ngI2ofNzoKvMNYtuEyzvy00zz-J68mJnPB4hUWbkQ8juRAREImhVQIwcDqrUqx-ALr7Cavc5MJYuFIUygt9bWF8VhZu6ItM8cAnpj9PkIupybcRZdMxeIAYfNQSxuRGpCVRCqYOptv4-8hpFSZFoSGVkN0fLhdqTClKU_ll6kD-8uSSLe-GI09BIq_TPGEZAA73FS9AgyCZrx5D8XWnfOojImbUpHgfOHKIt4oTetCo_5c2W_QxTAuSpMVvJiJRDCm9w6Shv91g-yPdnEXAWgv_qtGpRxCA8GdTnXk9vTM3RYjV_WP2LRa3A';
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   this.http.get(url, { responseType: 'blob', headers: headers })
  //     .subscribe(blob => {
  //       const objectURL = URL.createObjectURL(blob);
  //       this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);  // Keep as SafeUrl
  //     }, error => {
  //       console.error('Failed to load image:', error);
  //     });
  // }

}
