<div class="bg-gray-100 p-2 rounded-lg">
  Zvolené zariadenie:<br />
  <b>{{ dataService.formatEntityType(task?.deviceTypeName) || 'Neznámy typ' }}
    - {{ task?.objectName || 'Neznáme označenie' }}</b><br />
  {{ task?.objectId }}<br />
  {{ task?.address || 'Neznáma adresa' }}<br />
</div>

<div class="flex items-center justify-center mt-2">
  <button type="button" (click)="showQrCodeScanner(task!)"
    class="px-3 py-2.5 text-base font-medium text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
    <svg class="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 448 512">
      <path
        d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM64 96v64h64V96H64zM0 336c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V336zm64 16v64h64V352H64zM304 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48zm80 64H320v64h64V96zM256 304c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16v64c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V304zM368 480a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm64 0a16 16 0 1 1 0-32 16 16 0 1 1 0 32z" />
    </svg>
    &nbsp;&nbsp;Overiť cez QR kód
  </button>
  &nbsp;&nbsp;
  @if (qrCodeVerified === QrCodeVerificationStatus.Match) {
  <button type="button"
    class="px-3 py-2.5 text-base font-medium inline-flex items-center focus:ring-4 focus:outline-none rounded-lg text-center dark:bg-green-600 dark:hover:bg-green-700  text-white bg-green-400 hover:bg-green-500 focus:ring-green-300 dark:focus:ring-green-900">
    <svg class="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 448 512">
      <path
        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
    </svg>
  </button>
  } @else if (qrCodeVerified === QrCodeVerificationStatus.NoMatch) {
  <button type="button"
    class="px-3 py-2.5 text-base font-medium inline-flex items-center focus:ring-4 focus:outline-none rounded-lg text-center dark:bg-red-600 dark:hover:bg-red-700  text-white bg-red-400 hover:bg-red-500 focus:ring-red-300 dark:focus:ring-red-900">
    <svg class="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 512 512">
      <path
        d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
    </svg>
  </button>
  } @else {
  <button type="button"
    class="px-3 py-2.5 text-base font-medium inline-flex items-center focus:ring-4 focus:outline-none rounded-lg text-center dark:bg-yellow-600 dark:hover:bg-yellow-700  text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 dark:focus:ring-yellow-900">
    <svg class="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 320 512">
      <path
        d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
    </svg>
  </button>
  }

</div>

<!-- FIXME: remove after testing -->
<!-- Display device hierarchy -->
<!-- <div class="bg-gray-100 p-2 rounded-lg mt-4">
  <h2 class="mb-2">Hierarchia zariadení:</h2>
  <div class="max-h-64 overflow-auto">
    <ol class="relative ml-2 mt-4 mb-4 text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
      @for (item of pasportEntityParents; track item.uuid) {
      <li class="mb-3 ms-6">
        <span
          class="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -start-1.5 ring-2 ring-white dark:ring-gray-900 dark:bg-gray-700">
        </span>
        <h3 class="font-medium leading-tight">{{ dataService.formatEntityType(item?.type) }} {{ item?.name }}</h3>
        <p class="text-sm">{{ item.address || item?.data?.address }}</p>
      </li>
      }

      <li class="mb-3 ms-6">
        <span
          class="absolute flex items-center justify-center w-3 h-3 bg-green-200 rounded-full -start-1.5 ring-2 ring-white dark:ring-gray-900 dark:bg-green-900">
        </span>
        <h3 class="font-medium leading-tight">{{ dataService.formatEntityType(task?.deviceTypeName) }} {{
          task?.objectName }}</h3>
        <p class="text-sm">{{ task?.address }}</p>
      </li>

      @for (item of pasportEntityChildren; track item.uuid) {
      <li class="mb-3 ms-6">
        <span
          class="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -start-1.5 ring-2 ring-white dark:ring-gray-900 dark:bg-gray-700">
        </span>
        <h3 class="font-medium leading-tight">{{ dataService.formatEntityType(item?.type) }} {{ item?.name }}</h3>
        <p class="text-sm">{{ item.address || item?.data?.address }}</p>
      </li>
      }
    </ol>
  </div>
</div> -->

<app-device-hierarchy [uuid]="task?.objectId" [deviceTypeName]="task?.deviceTypeName" [objectName]="task?.objectName"
  [address]="task?.address"></app-device-hierarchy>

@if (task?.deviceTypeName?.toLowerCase() === 'lightpoint') {
<!-- Metrics values -->
<div class="flex mt-12">

  <!-- TODO: get lc status from backend -->
  @if (metricsTemp) {
  <div>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
      <b>Stav LC:</b>&nbsp;Aktívny</span>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
      <b>Spotreba:&nbsp;</b>
      {{ metricsTemp.power }}&nbsp;W</span>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
      <b>cosPhy:&nbsp;</b>
      {{ metricsTemp.cosPhy }}</span>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
      <b>Prúd:&nbsp;</b>
      {{ metricsTemp.current }}&nbsp;A</span>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
      <b>Stmievanie:&nbsp;</b>
      {{ metricsTemp.dimming }}&nbsp;%</span>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
      <b>Napätie:&nbsp;</b>
      {{ metricsTemp.voltage }}&nbsp;V</span>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
      <b>Teplota:&nbsp;</b>
      {{ metricsTemp.temperature }}&nbsp;&deg;C</span>
    <span
      class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
      <b>Aktualizované:&nbsp;</b>
      {{ metricsTemp.dataTs | date: 'dd.MM.yyyy HH:mm:ss' || '' }}</span>
  </div>
  } @else {
  <div
    class="flex items-center w-full p-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
    role="alert">
    <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
      fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
    <span class="sr-only">Info</span>
    <div>
      Dáta pre aktuálny stav nie sú k dispozícii.
    </div>
  </div>
  }
</div>
<div class="mt-2 flex justify-center items-center">
  <button type="button" (click)="refreshMetrics()"
    class="inline-flex items-center text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"
      class="w-3.5 h-3.5">
      <path
        d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z">
      </path>
    </svg>&nbsp;Aktualizovať&nbsp;({{ poolingMetricsCounter }}s)</button>
</div>

<!-- Metrics chart Lightpoint -->
@if (metricsTemp) {
<div class="w-full mt-6 bg-gray-50 rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
  <div class="flex flex-wrap justify-center gap-5 mb-4">
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" checked id="red-radio" type="radio" value="power" name="colored-radio"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="red-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Spotreba</label>
    </div>
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" id="green-radio" type="radio" value="cosPhy" name="colored-radio"
        class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="green-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">cosPhy</label>
    </div>
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" id="purple-radio" type="radio" value="current" name="colored-radio"
        class="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="purple-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Prúd</label>
    </div>
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" id="teal-radio" type="radio" value="dimming" name="colored-radio"
        class="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="teal-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Stmievanie</label>
    </div>
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" id="yellow-radio" type="radio" value="voltage" name="colored-radio"
        class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="yellow-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Napätie</label>
    </div>
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" id="orange-radio" type="radio" value="temperature" name="colored-radio"
        class="w-4 h-4 text-orange-500 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="orange-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Teplota</label>
    </div>
  </div>
  <div id="line-chart"></div>
</div>
<div class="mt-2 flex justify-center items-center">
  <button type="button" (click)="refreshMetricsGraph()"
    class="inline-flex items-center text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"
      class="w-3.5 h-3.5">
      <path _ngcontent-ng-c3219640693=""
        d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z">
      </path>
    </svg>&nbsp;Aktualizovať</button>
</div>
} @else {
<div
  class="mt-6 flex items-center w-full p-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
  role="alert">
  <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
    fill="currentColor" viewBox="0 0 20 20">
    <path
      d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
  </svg>
  <span class="sr-only">Info</span>
  <div>
    Dáta pre graf nie sú k dispozícii.
  </div>
</div>
}

} @else if (task?.deviceTypeName?.toLowerCase() === 'rvo') {
<!-- Metrics values -->
<div class="flex mt-12 flex-row flex-wrap">
  @if (metricsRvo) {
  <!-- TODO: get lc status from backend -->
  <span
    class="whitespace-nowrap inline-flex items-center mb-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
    <b>Stav RVO:</b>&nbsp;Aktívny</span>
  <br />
  <div class="
        w-full
        grid grid-cols-8 grid-rows-4 gap-1 justify-items-center items-center
        whitespace-nowrap mb-2 bg-gray-200 text-gray-800 text-sm font-bold px-2.5 py-2 rounded-lg dark:bg-gray-700 dark:text-gray-300
        ">
    <!-- First row -->
    <span>DIN1</span>
    <span>DIN2</span>
    <span>DIN3</span>
    <span>DIN4</span>
    <span>DIN5</span>
    <span>DIN6</span>
    <span>DIN7</span>
    <span>DIN8</span>

    <span [class.bg-green-500]="metricsRvo.DIN1 === 1" [class.bg-red-500]="metricsRvo.DIN1 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DIN2 === 1" [class.bg-red-500]="metricsRvo.DIN2 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DIN3 === 1" [class.bg-red-500]="metricsRvo.DIN3 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DIN4 === 1" [class.bg-red-500]="metricsRvo.DIN4 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DIN5 === 1" [class.bg-red-500]="metricsRvo.DIN5 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DIN6 === 1" [class.bg-red-500]="metricsRvo.DIN6 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DIN7 === 1" [class.bg-red-500]="metricsRvo.DIN7 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DIN8 === 1" [class.bg-red-500]="metricsRvo.DIN8 !== 1"
      class="flex w-3 h-3 rounded-full"></span>

    <span [class.bg-green-500]="metricsRvo.DO1 === 1" [class.bg-red-500]="metricsRvo.DO1 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DO2 === 1" [class.bg-red-500]="metricsRvo.DO2 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DO3 === 1" [class.bg-red-500]="metricsRvo.DO3 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DO4 === 1" [class.bg-red-500]="metricsRvo.DO4 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DO5 === 1" [class.bg-red-500]="metricsRvo.DO5 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DO6 === 1" [class.bg-red-500]="metricsRvo.DO6 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DO7 === 1" [class.bg-red-500]="metricsRvo.DO7 !== 1"
      class="flex w-3 h-3 rounded-full"></span>
    <span [class.bg-green-500]="metricsRvo.DO8 === 1" [class.bg-red-500]="metricsRvo.DO8 !== 1"
      class="flex w-3 h-3 rounded-full"></span>

    <span>DO1</span>
    <span>DO2</span>
    <span>DO3</span>
    <span>DO4</span>
    <span>DO5</span>
    <span>DO6</span>
    <span>DO7</span>
    <span>DO8</span>
  </div>

  <span
    class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
    <b>Napájacie napätie (VCC):&nbsp;</b>
    {{ metricsRvo.VCC }}&nbsp;V</span>
  <span
    class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
    <b>Napätie batérie (VBAT):&nbsp;</b>
    {{ metricsRvo.VBAT }}&nbsp;V</span>
  <span
    class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
    <b>Aktualizované:&nbsp;</b>
    {{ metricsRvo.dataTs | date: 'dd.MM.yyyy HH:mm:ss' || '' }}</span>
  <br />
  } @else {
  <div
    class="flex items-center w-full p-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
    role="alert">
    <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
      fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
    <span class="sr-only">Info</span>
    <div>
      Dáta pre aktuálny stav nie sú k dispozícii.
    </div>
  </div>
  }
</div>
<div class="mt-2 flex justify-center items-center">
  <button type="button" (click)="refreshMetrics()"
    class="inline-flex items-center text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"
      class="w-3.5 h-3.5">
      <path
        d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z">
      </path>
    </svg>&nbsp;Aktualizovať&nbsp;({{ poolingMetricsCounter }}s)</button>
</div>

<!-- Metrics chart RVO -->
@if (metricsGraphRvo && metricsGraphRvo.length > 0) {
<div class="w-full mt-6 bg-gray-50 rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
  <div class="flex flex-wrap justify-center gap-5 mb-4">
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" checked id="red-radio" type="radio" value="VCC" name="colored-radio"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="red-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Napájacie napätie
        (VCC)</label>
    </div>
    <div class="flex items-center">
      <input (change)="onRadioChange($event)" id="green-radio" type="radio" value="VBAT" name="colored-radio"
        class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="green-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Napätie batérie
        (VBAT)</label>
    </div>
  </div>
  <div id="line-chart"></div>
</div>
} @else {
<div
  class="mt-6 flex items-center w-full p-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
  role="alert">
  <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
    fill="currentColor" viewBox="0 0 20 20">
    <path
      d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
  </svg>
  <span class="sr-only">Info</span>
  <div>
    Dáta pre graf nie sú k dispozícii.
  </div>
</div>
}
<div class="mt-2 flex justify-center items-center">
  <button type="button" (click)="refreshMetricsGraph()"
    class="inline-flex items-center text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"
      class="w-3.5 h-3.5">
      <path
        d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z">
      </path>
    </svg>&nbsp;Aktualizovať</button>
</div>
} @else {
<div
  class="flex items-center p-4 mt-8 mb-8 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
  role="alert">
  <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
    fill="currentColor" viewBox="0 0 20 20">
    <path
      d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
  </svg>
  <span class="sr-only">Info</span>
  <div>
    <span class="font-medium">Upozornenie!</span> Pre zariadenie typu <b>{{ task?.deviceTypeName }}</b> nie sú k
    dispozícii žiadne metriky.
  </div>
</div>
}

<app-device-control [uuid]="task?.objectId" [deviceTypeName]="task?.deviceTypeName"
  [objectName]="task?.objectName"></app-device-control>

<div class="mt-6">
  <div class="bg-gray-100 p-2 rounded-lg">
    <b>Evidované hlásenie</b><br />
    <span class="font-bold text-lg">Typ: {{ task?.name }}</span><br />
    @if (task?.source === 'PUBLIC') {
    <div class="my-2">
      <span class="font-bold text-base">Kontaktné informácie pre hlásenie:</span><br />
      <span class="text-base text-gray-500 dark:text-gray-400"><b>Meno: </b>{{ task?.contactName || 'Neuvedené'
        }}</span><br />
      <span class="text-base text-gray-500 dark:text-gray-400"><b>Tel.: </b>{{ task?.contactPhone || 'Neuvedené'
        }}</span><br />
      <span class="text-base text-gray-500 dark:text-gray-400"><b>Email: </b>{{ task?.contactEmail || 'Neuvedené'
        }}</span><br />
    </div>
    }
    <span class="text-sm text-gray-500 dark:text-gray-400">
      Pri riešení poruchy zadajte poznámky, pridajte fotodokumentáciu, záznamy z meraní a podobne.
      Následne potvrďte vyriešenie poruchy.
    </span>
  </div>

  <!-- Importance selection -->
  <div class="bg-gray-100 p-2 mt-2 rounded-lg flex flex-wrap w-full">
    <app-importance-select (importanceChange)="handleImportanceChange($event)" [importance]="task?.importance"
      class="flex-1"></app-importance-select>

    <button type="button" (click)="saveTaskImportance()"
      [disabled]=" (!(task?.importance) && newTaskImportance <= 0) || (task?.importance === newTaskImportance)"
      [class.disabled]="task?.agent === newTaskAgent" class="flex-none ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
      disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      Uložiť
    </button>
  </div>

  <!-- Incident agent selection -->
  <div class="bg-gray-100 p-2 mt-2 rounded-lg flex flex-wrap w-full">
    <app-agent-select (agentChange)="handleTaskAgentChange($event)" [data]="task" dataType="task" [agent]="task?.agent"
      class="flex-1"></app-agent-select>

    <button type="button" (click)="saveTaskAgent()" [disabled]="task?.agent === newTaskAgent"
      [class.disabled]="task?.agent === newTaskAgent" class="flex-none ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
      disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      Uložiť
    </button>
  </div>

  <div class="mt-2 mb-4 pt-2">
    <div class="mt-2">
      @if (task?.state === 'inprogress') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
        </svg>
        Aktívne</span>
      } @else if (task?.state === 'new') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M192 32c17.7 0 32 14.3 32 32V199.5l111.5-66.9c15.2-9.1 34.8-4.2 43.9 11s4.2 34.8-11 43.9L254.2 256l114.3 68.6c15.2 9.1 20.1 28.7 11 43.9s-28.7 20.1-43.9 11L224 312.5V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V312.5L48.5 379.4c-15.2 9.1-34.8 4.2-43.9-11s-4.2-34.8 11-43.9L129.8 256 15.5 187.4c-15.2-9.1-20.1-28.7-11-43.9s28.7-20.1 43.9-11L160 199.5V64c0-17.7 14.3-32 32-32z" />
        </svg>
        Nové</span>
      } @else if (task?.state === 'solved') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 448 512">
          <path
            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
        </svg>
        Vyriešené</span>
      } @else if (task?.state === 'unresolved') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-orange-100 text-orange-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-orange-900 dark:text-orange-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
        </svg>
        Uzatvorené bez riešenia</span>
      } @else if (task?.state === 'terminated') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-red-900 dark:text-red-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 448 512">
          <path
            d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
        </svg>
        Zrušené</span>
      } @else if (task?.state === 'pending') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-purple-900 dark:text-purple-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9H112z" />
        </svg>
        Pozastavené</span>
      }
      @if (task?.source === 'LIGHTNET') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 576 512">
          <path
            d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V288H64V64H512z" />
        </svg>
        {{ 'Lightnet' }} </span>
      } @else if (task?.source === 'PUBLIC') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
        </svg>
        {{ 'Verejné' }} </span>
      } @else if (task?.source === 'OPERATOR') {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
        </svg>
        {{ 'Operátor' }} </span>
      } @else {
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 320 512">
          <path
            d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
        </svg>
        {{ task?.source }} </span>
      }
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
        </svg>
        {{ null | formatElapsedTime: task?.evidenceDate }}</span>
    </div>

    <div class="px-4 py-2">
      <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li class="mb-3 ms-6">
          <span
            class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
            <svg class="w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 5.917 5.724 10.5 15 1.5" />
            </svg>
          </span>
          <h3 class="font-medium leading-tight">Manuálna diagnostika zariadenia</h3>
          <p class="text-sm">Preverte zariadenie príslušnými meracími zariadeniami a vykonajte vizuálnu kontrolu</p>
        </li>
        <li class="mb-3 ms-6">
          <span
            class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <svg class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
              <path
                d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
            </svg>
          </span>
          <h3 class="font-medium leading-tight">Oprava zariadenia</h3>
          <p class="text-sm">Pri tomto type poruchy bude pravdepodobne nutná výmena vadnej komponenty.</p>
        </li>
        <li class="mb-3 ms-6">
          <span
            class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <svg class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
              <path
                d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
            </svg>
          </span>
          <h3 class="font-medium leading-tight">Preverenie</h3>
          <p class="text-sm">Po odstránení poruchy preverte správnu funkčnosť a následne zariadenie zabezpečte</p>
        </li>
        <li class="ms-6">
          <span
            class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <svg class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
              <path
                d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z" />
            </svg>
          </span>
          <h3 class="font-medium leading-tight">Potvrdenie</h3>
          <p class="text-sm">Zapíšte výsledky kontroly a vykonaných opráv, zhotovte fotodokumentáciu a uložte stav.
          </p>
        </li>
      </ol>
    </div>

    <div class="flex flex-col">
      <div class="mt-4 mb-2 text-center ">
        Použite kameru telefónu na zhotovenie fotodokumentácie alebo pridajte súbory priamo z Vášho zariadenia.
        <!-- <span class="text-sm text-gray-500 dark:text-gray-400">Ak chcete odstrániť obrázok z galérie, označte ho a
          stlačte tlačítko zmazať.</span> -->
      </div>
    </div>

    <div class="flex items-center justify-center mt-2">
      <input id="maintenance-file-input-camera" class="hidden" type="file" accept="image/*" capture="camera"
        (change)="onCameraImageCapture($event)">
      <label for="maintenance-file-input-camera" class="cursor-pointer">
        <div
          class="px-3 py-2.5 text-base font-medium text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 512 512">
            <path
              d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
          </svg>
          &nbsp;&nbsp;Odfotiť
        </div>
      </label>
      &nbsp;&nbsp;
      <input id="maintenance-file-input-storage" class="hidden" type="file" accept="*"
        (change)="onStorageFileSelect($event)">
      <label for="maintenance-file-input-storage" class="cursor-pointer">
        <div
          class="px-3 py-2.5 text-base font-medium text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 384 512">
            <path
              d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
          </svg>
          &nbsp;&nbsp;Pridať súbor
        </div>
      </label>
    </div>

    <div class="mt-3">
      @for (document of maintenanceDocuments; track document.id) {
      <div class="flex mb-2">
        <div class="w-24">
          @if (document.type === 'image') {
          <img [src]="document.safeUrl" (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 cursor-pointer object-cover rounded-lg"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}" alt="">
          } @else if (document.type === 'pdf') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              PDF súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'xml') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              XML súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'json') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              JSON súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'xls') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              XLS súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'txt') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              Textový súbor<br />{{ document.name }}
            </p>
          </div>
          } @else {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              Súbor<br />{{ document.name }}
            </p>
          </div>
          }
        </div>
        <div class="pl-2 flex-1">
          <b>Vytvorené:</b> {{ document.creationDate ? (document.creationDate | date: 'dd.MM.yyyy HH:mm') : 'Neuvedené'
          }}<br />
          <b>Servis:</b> {{ (document?.tags['incidentId'] || document?.tags['taskId']) ? 'Áno' : 'Nie' }}<br />
          <b>Komentár:</b> {{ document?.tags['comment'] }}
        </div>
      </div>
      }

      @for (document of maintenanceDocumentsNew; track document.id) {
      <div class="flex mb-2">
        <div class="w-24">
          @if (document.type === 'image') {
          <img [src]="document.url" (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 object-cover rounded-lg"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}" alt="">
          } @else if (document.type === 'pdf') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              PDF súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'xml') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              XML súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'json') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              JSON súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'xls') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              XLS súbor<br />{{ document.name }}
            </p>
          </div>
          } @else if (document.type === 'txt') {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              Textový súbor<br />{{ document.name }}
            </p>
          </div>
          } @else {
          <div (click)="toggleDocumentHighlight(document)"
            class="w-full h-24 rounded-lg p-2 bg-gray-100 flex items-center justify-center flex-col text-xs text-gray-600"
            [ngClass]="{'ring-4 focus:outline-none focus:ring-gray-300': document.isSelected}">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
            <p class="text-center mt-2 text-xs font-semibold w-full text-ellipsis whitespace-nowrap overflow-hidden">
              Súbor<br />{{ document.name }}
            </p>
          </div>
          }
        </div>
        <div class="ml-2 flex-1">
          <textarea [(ngModel)]="document.tags['comment']" rows="4"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Zadajte komentár ..."></textarea>
        </div>
      </div>
      }
    </div>

    @if ((maintenanceDocuments.length <= 0) && (maintenanceDocumentsNew.length <=0)){
    <div class="flex items-center justify-center my-8">
      <div class="flex flex-col items-center space-y-2">
        <svg class="w-10 h-10 text-gray-300 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="currentColor" viewBox="0 0 384 512">
          <path
            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
        </svg>
        <span class="text-lg font-medium text-gray-300 dark:text-gray-600">Žiadne súbory</span>
      </div>
    </div>
    }

    <div class="bg-gray-100 p-2 mt-6 rounded-lg flex flex-wrap">
      <div class="flex justify-center w-full">
        @if (maintenanceDocumentsHighlightedCount <= 0) { <div
          class="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          Nových súborov: {{ maintenanceDocumentsNew.length }}
      </div>
      }
      @if (maintenanceDocumentsHighlightedCount > 0) {
      <button type="button" (click)="showImagePreview(maintenanceDocumentHighlighted?.safeUrl)"
        [disabled]="( (!(maintenanceDocumentsHighlightedCount > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-1 flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
          disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
        @if (isProcessingDocumentsRequest) {
        <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor" />
        </svg>
        }
        {{ isProcessingDocumentsRequest ? '' : 'Náhľad' }}
      </button>
      <a [href]="maintenanceDocumentHighlighted?.safeUrl" [download]="maintenanceDocumentHighlighted?.name"
        target="_blank">
        <button type="button"
          [disabled]="( (!(maintenanceDocumentsHighlightedCount > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-1 flex items-center justify-center ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
              disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
          @if (isProcessingDocumentsRequest) {
          <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor" />
          </svg>
          }
          {{ isProcessingDocumentsRequest ? '' : 'Stiahnuť' }}
        </button>
      </a>
      <button type="button" (click)="deleteDocuments()"
        [disabled]="( (!(maintenanceDocumentsHighlightedCount > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-1 flex items-center justify-center ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
        @if (isProcessingDocumentsRequest) {
        <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor" />
        </svg>
        }
        {{ isProcessingDocumentsRequest ? '' : 'Zmazať' }}
      </button>
      } @else {
      <button type="button" (click)="uploadDocuments()"
        [disabled]="( (!(maintenanceDocumentsNew.length > 0)) || (isProcessingDocumentsRequest === true) )" class="flex-none flex items-center ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
        @if (isProcessingDocumentsRequest) {
        <svg aria-hidden="true" role="status" class="inline w-4 h-4 text-white animate-spin" viewBox="0 0 100 101"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor" />
        </svg>
        }
        {{ isProcessingDocumentsRequest ? '' : 'Uložiť' }}
      </button>
      }
    </div>
</div>

<!-- Incident comment and status -->
<div class="bg-gray-100 p-2 mt-6 rounded-lg flex flex-wrap">
  <div class="w-full mb-2">
    <textarea [(ngModel)]="comment" rows="6"
      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="Zadajte komentár k hláseniu servisu ..."></textarea>
  </div>

  <div class="flex justify-center w-full">
    <select
      class="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      [(ngModel)]="state">
      <option value="" disabled selected>Nastavenie statusu hlásenia</option>
      @for (state of dataService.taskStates; track state.value) {
      <option [value]="state.value">{{ state.label }}</option>
      }
    </select>
    <button type="button" (click)="saveTask()"
      [disabled]="((!task?.comment && comment === '') || (task?.comment === comment)) && (task?.state === state)" class="flex-none ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
          disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      Uložiť
    </button>
  </div>
</div>

<div #imagePreviewContainer></div>