import { Component, Input, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ControlDeviceDetails, DataService, Incident, IntensityControlDeviceDetails, ModeControlDeviceDetails } from '@services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-control',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './device-control.component.html',
  styleUrl: './device-control.component.css'
})
export class DeviceControlComponent {
  private subscriptions = new Subscription();

  // indetifier of the object (objectId)
  @Input() uuid: string | undefined = '';
  // named type of the object (lightpoint)
  @Input() deviceTypeName: string | undefined = '';
  // name of the object (RVO SHOWROOM)
  @Input() objectName: string | undefined = '';

  // TODO: deprecated - not using this anymore
  data: Incident[] = [];

  controlDeviceDetails: ControlDeviceDetails | null = null;
  controlDeviceDetailsMode: string = '';
  controlDeviceDetailsIntensity: number | null = null;
  controlDeviceDetailsNewMode: string = '';
  controlDeviceDetailsNewIntensity: number | null = null;

  constructor(public dataService: DataService) { }

  /**
   * Lifecycle hook that is called when a data-bound property of a directive changes.
   *
   * @param changes - An object containing the changed properties and their current and previous values.
   *
   * This method performs the following actions:
   * 1. Checks if the 'uuid' input property has changed and is not empty.
   * 2. Unsubscribes from any existing subscriptions.
   * 3. Refreshes control device details based on the device type:
   *    - For 'lightpoint' devices, it refreshes intensity details.
   *    - For 'rvo' devices, it refreshes mode details.
   * 4. Sets up a new subscription to monitor control device details:
   *    - Updates component properties with the latest device data.
   *    - Handles both ModeControlDeviceDetails and IntensityControlDeviceDetails types.
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['uuid'] && this.uuid) {
      // Unsubscribe from existing subscriptions
      this.subscriptions.unsubscribe();

      // Create a new Subscription object
      this.subscriptions = new Subscription();

      // TODO: hardcoded device type names could cause issues
      if (this.deviceTypeName?.toLowerCase() === 'lightpoint') {
        await this.refreshControlDeviceDetailsIntensity();
      }
      if (this.deviceTypeName?.toLowerCase() === 'rvo') {
        await this.refreshControlDeviceDetailsMode();
      }

      this.subscriptions.add(
        this.dataService.getControlDeviceDetails(this.uuid).subscribe((data: ControlDeviceDetails | null) => {
          this.controlDeviceDetails = data;
          this.controlDeviceDetailsMode = (this.controlDeviceDetails as ModeControlDeviceDetails)?.mode ?? '';
          this.controlDeviceDetailsIntensity = (this.controlDeviceDetails as IntensityControlDeviceDetails)?.intensity ?? null;
          this.controlDeviceDetailsNewMode = this.controlDeviceDetailsMode;
          this.controlDeviceDetailsNewIntensity = this.controlDeviceDetailsIntensity;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Increments the new intensity value for the control device.
   *
   * This method increases the `controlDeviceDetailsNewIntensity` by 1,
   * ensuring it stays within the valid range of 0 to 100.
   * If the current value is null or undefined, it sets it to 0.
   *
   * The method handles the following cases:
   * - If the current value is null or undefined, it sets it to 0.
   * - If the current value is greater than 99, it sets it to 100.
   * - If the current value is less than 0, it sets it to 0.
   * - Otherwise, it increments the value by 1.
   */
  controlDeviceDetailsNewIntensityIncrement() {
    if ((this.controlDeviceDetailsNewIntensity === null) || (this.controlDeviceDetailsNewIntensity === undefined)) {
      this.controlDeviceDetailsNewIntensity = 0;
      return;
    }
    if (this.controlDeviceDetailsNewIntensity > 99) {
      this.controlDeviceDetailsNewIntensity = 100;
    } else if (this.controlDeviceDetailsNewIntensity < 0) {
      this.controlDeviceDetailsNewIntensity = 0;
    } else {
      this.controlDeviceDetailsNewIntensity += 1;
    }
  }

  /**
   * Decrements the new intensity value for the control device.
   *
   * This method decreases the `controlDeviceDetailsNewIntensity` by 1,
   * ensuring it stays within the valid range of 0 to 100.
   * If the current value is null or undefined, it sets it to 0.
   *
   * The method handles the following cases:
   * - If the current value is less than 1, it sets it to 0.
   * - If the current value is greater than 100, it sets it to 100.
   * - Otherwise, it decrements the value by 1.
   */
  controlDeviceDetailsNewIntensityDecrement() {
    if ((this.controlDeviceDetailsNewIntensity === null) || (this.controlDeviceDetailsNewIntensity === undefined)) {
      this.controlDeviceDetailsNewIntensity = 0;
      return;
    }
    if (this.controlDeviceDetailsNewIntensity < 1) {
      this.controlDeviceDetailsNewIntensity = 0;
    } else if (this.controlDeviceDetailsNewIntensity > 100) {
      this.controlDeviceDetailsNewIntensity = 100;
    } else {
      this.controlDeviceDetailsNewIntensity -= 1;
    }
  }

  /**
   * Refreshes the control device details intensity.
   *
   * This method resets the control device details intensity to null
   * and then fetches the updated intensity from the API if a UUID is available.
   *
   * @returns A Promise that resolves when the refresh operation is complete.
   */
  async refreshControlDeviceDetailsIntensity(): Promise<void> {
    this.controlDeviceDetailsIntensity = null;
    if (this.uuid) {
      this.dataService.fetchApiPatchControlIntensity(this.uuid);
    }
  }

  /**
   * Sets the intensity of the control device.
   *
   * This method attempts to set the intensity of the control device by making an API call.
   * It first checks if a UUID is available, then validates the new intensity value before
   * sending the request. If the intensity is invalid, it logs an error.
   *
   * @returns A Promise that resolves when the operation is complete.
   * @throws Will log an error if the intensity value is invalid.
   */
  async controlSetIntensity(): Promise<void> {
    if (!this.uuid) {
      return;
    }
    const intensity = this.controlDeviceDetailsNewIntensity ?? 0; // Use default value of 0 if null or undefined
    if ((this.controlDeviceDetailsNewIntensity !== null) && (this.controlDeviceDetailsNewIntensity >= 0) && (this.controlDeviceDetailsNewIntensity <= 100)) {
      // calls the data service to update the intensity
      await this.dataService.fetchApiPostControlIntensity(this.uuid, intensity);
    } else {
      // TODO: show info message to the user
      console.error('Invalid intensity value');
    }
  }

  /**
   * Refreshes the control device details mode.
   *
   * This method resets the control device details mode to an empty string
   * and then fetches the updated mode from the API if a UUID is available.
   *
   * @returns A Promise that resolves when the refresh operation is complete.
   */
  async refreshControlDeviceDetailsMode(): Promise<void> {
    this.controlDeviceDetailsMode = '';
    if (this.uuid) {
      this.dataService.fetchApiPatchControlMode(this.uuid);
    }
  }

  /**
   * Sets the control mode for the device.
   *
   * This method is responsible for updating the control mode of a device
   * by making an API call to the data service.
   *
   * @returns A Promise that resolves when the operation is complete.
   * @throws Will throw an error if the API call fails.
   */
  async controlSetMode(): Promise<void> {
    // Check if the UUID is available
    if (!this.uuid) {
      return;
    }

    // Call the data service to update the control mode
    await this.dataService.fetchApiPostControlMode(this.uuid, this.controlDeviceDetailsNewMode);
  }

}
