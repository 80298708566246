import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css'
})
export class ToastComponent {
  @Output() closeToast = new EventEmitter<void>();

  onCloseClick(): void {
    this.closeToast.emit();
  }

}
