<div
  class="max-w-72 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">

  @if ((data.type === 'pasport') && (data.cluster === true)){
  <button (click)="closePopup()" aria-current="true" type="button"
    class="w-full px-4 py-2 font-medium text-left rtl:text-right text-white bg-blue-700 border-b border-gray-200 rounded-t-lg cursor-pointer focus:outline-none dark:bg-gray-800 dark:border-gray-600">
    Pasport zariadenia
  </button>
  <div class="max-h-48 overflow-auto pb-2">
    @for (item of data.data; track item.id) {
    <button (click)="showPasportDetails(item.properties.uuid)" type="button"
      class="w-full px-4 py-2 font-medium text-left rtl:text-right border-b border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
      <b>{{ dataService.formatEntityType(item.properties.type) }}:</b> {{ item.properties.name }}
    </button>
    }
  </div>
  } @else if ((data.type === 'pasport') && (data.cluster === false)){
  <button (click)="closePopup()" aria-current="true" type="button"
    class="w-full px-4 py-2 font-medium text-left rtl:text-right text-white bg-blue-700 border-b border-gray-200 rounded-t-lg cursor-pointer focus:outline-none dark:bg-gray-800 dark:border-gray-600">
    Pasport zariadenie
  </button>
  <div class="max-h-48 overflow-auto pb-2">
    <button (click)="showPasportDetails(data.data.uuid)" type="button"
      class="w-full px-4 py-2 font-medium text-left rtl:text-right border-b border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
      <b>{{ dataService.formatEntityType(data.data.type) }}:</b> {{ data.data.name }}
    </button>
  </div>
  } @else if (data.type === 'incident') {
  <button (click)="closePopup()" aria-current="true" type="button"
    class="w-full px-4 py-2 font-medium text-left rtl:text-right text-white bg-blue-700 border-b border-gray-200 rounded-t-lg cursor-pointer focus:outline-none dark:bg-gray-800 dark:border-gray-600">
    Porucha
  </button>
  <div class="max-h-48 overflow-auto pb-2">
    <button (click)="showDetails(data.data.id)" type="button"
      class="w-full px-4 py-2 font-medium text-left rtl:text-right border-b border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
      <b>{{ data.data.name }}</b><br />
      {{ data.data.address }}<br />
      {{ dataService.formatEntityType(data.data.deviceTypeName) }} - {{ data.data.objectName }}
    </button>
  </div>
  } @else {
  <button (click)="closePopup()" aria-current="true" type="button"
    class="w-full px-4 py-2 font-medium text-left rtl:text-right text-white bg-blue-700 border-b border-gray-200 rounded-t-lg cursor-pointer focus:outline-none dark:bg-gray-800 dark:border-gray-600">
    Poruchy
  </button>
  <div class="max-h-48 overflow-auto pb-2">
    @for (item of data; track item.id) {
      <button (click)="showDetails(item.properties.incidentId)" type="button"
        class="w-full px-4 py-2 font-medium text-left rtl:text-right border-b border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
        <b>{{ item.properties.name }}</b><br />
        {{ item.properties.address }}<br />
        {{ dataService.formatEntityType(item.properties.deviceTypeName) }} - {{ item.properties.objectName }}
      </button>
    }
  </div>
  }

</div>