<div #toastContainer></div>

<!-- drawer component -->
<div id="drawer-new-report-example" class="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-full lg:w-96 dark:bg-gray-800
   duration-500 shadow-left-lg border-l" tabindex="-1" aria-labelledby="drawer-new-report-label"
  data-drawer-backdrop="false">
  <h5 id="drawer-new-report-example-label"
    class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
    <svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 512 512">
      <path
        d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
    </svg>
    Vytvorenie novej poruchy
  </h5>


  <button id="drawer-new-report-button-close" type="button" data-drawer-target="drawer-new-report-example"
    data-drawer-hide="drawer-new-report-example" data-drawer-placement="right" aria-controls="drawer-new-report-example"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>

  <div class="mb-6 p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
    <div class="flex items-center">
      <svg class="flex-shrink-0 w-4 h-4 me-2 dark:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span class="sr-only">Info</span>
      <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Výber zariadenia</h3>
    </div>
    <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
      Zariadenie na ktorom sa porucha vyskytla môže výbrať priamo z mapy, načítaním QR kódu alebo vyplnením detailov
      manuálne.
    </div>
    <!-- <div class="flex">
      <button type="button" (click)="test()"
        class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-800">
        <svg class="me-2 h-3 w-3 dark:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="currentColor" viewBox="0 0 20 14">
          <path
            d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
        </svg>
        Viac detailov
      </button>
      <button type="button"
        class="text-gray-800 bg-transparent border border-gray-700 hover:bg-gray-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:border-gray-600 dark:hover:bg-gray-600 dark:focus:ring-gray-800 dark:text-gray-300 dark:hover:text-white"
        data-dismiss-target="#alert-additional-content-5" aria-label="Close">
        Zavrieť
      </button>
    </div> -->
  </div>

  <!-- Source type selectio - map, qr code and manual from list -->
  <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="new-report-type-tabs"
      data-tabs-toggle="#new-report-type-tabs-content" role="tablist">
      <li class="me-2" role="presentation">
        <button
          class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
          id="new-report-type-tab-map" data-tabs-target="#new-report-type-tab-content-map" type="button" role="tab"
          aria-controls="new-report-type-tab-content-map" aria-selected="false">Mapa</button>
      </li>
      <li class="me-2" role="presentation">
        <button
          class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
          id="new-report-type-tab-qr" data-tabs-target="#new-report-type-tab-content-qr" type="button" role="tab"
          aria-controls="new-report-type-tab-content-qr" aria-selected="false">QR kód</button>
      </li>
      <li class="me-2" role="presentation">
        <button
          class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
          id="new-report-type-tab-list" data-tabs-target="#new-report-type-tab-content-list" type="button" role="tab"
          aria-controls="new-report-type-tab-content-list" aria-selected="false">Zoznam</button>
      </li>
    </ul>
  </div>
  <div id="new-report-type-tabs-content">
    <div class="hidden p-2 rounded-t-lg bg-gray-50 dark:bg-gray-800" id="new-report-type-tab-content-map" role="tabpanel"
      aria-labelledby="new-report-type-tab-map">
      <p class="mb-4 text-sm text-gray-500 dark:text-gray-400">
        Zariadenie zvolíte kliknutím na jeho ikonu na mape v režime pasport zobrazenia.
      </p>

      <!-- <br />

      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Zoznam dostupných zariadení</label>
      <div class="bg-white max-h-64 overflow-auto">
        <ul class="p-1 space-y-1 text-sm text-gray-700 dark:text-gray-200">
          @if (entities && entities.length === 0) {
          <li>
            <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              Na mape nebol zvolený žiadny bod alebo výberu nezodpovedá žiadne zariadenie.
            </div>
          </li>
          } @else {
          @for (entity of entities; track entity.uuid) {
          <li>
            <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input (change)="onRadioChange(entity)" id="new-report-entities-list-entity-{{entity.uuid}}"
                  name="new-report-entities-list" type="radio" value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ms-2 text-sm">
                <label for="new-report-entities-list-entity-{{entity.uuid}}"
                  class="font-medium text-gray-900 dark:text-gray-300">
                  <div>
                    {{ entity || 'Neznámy typ' }} - {{ entity || 'Neznáme označenie' }}&nbsp;
                  </div>
                  <p class="text-xs font-normal text-gray-500 dark:text-gray-300">
                    {{ (entity.uuid ? entity.uuid : 'Neznáma adresa') }}
                  </p>
                </label>
              </div>
            </div>
          </li>
          }
          }
        </ul>
      </div> -->

    </div>
    <div class="hidden p-2 rounded-t-lg bg-gray-50 dark:bg-gray-800" id="new-report-type-tab-content-qr" role="tabpanel"
      aria-labelledby="new-report-type-tab-qr">
      <p class="text-sm text-gray-500 dark:text-gray-400">
        Vyberte zariadenie načítaním QR kódu.
        Niektoré zariadenia môžu mať rovnaký QR kód, preto je potrebné po skenovaní zvoliť konkrétne zariadenie
        manuálne.
      </p>
      <div class="flex items-center justify-center mt-4 mb-4">
        <button type="button" (click)="showQrCodeScanner()"
          class="px-3 py-2.5 text-sm font-medium text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg class="w-4 h-4 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 448 512">
            <path
              d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM64 96v64h64V96H64zM0 336c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V336zm64 16v64h64V352H64zM304 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48zm80 64H320v64h64V96zM256 304c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16v64c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V304zM368 480a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm64 0a16 16 0 1 1 0-32 16 16 0 1 1 0 32z" />
          </svg>
          &nbsp;&nbsp;Spustiť QR skener
        </button>
      </div>

      <!-- <br />

      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Zoznam dostupných zariadení</label>
      <div class="bg-white max-h-64 overflow-auto">
        <ul class="p-1 space-y-1 text-sm text-gray-700 dark:text-gray-200">
          @if (entities && entities.length <= 0) {
          <li>
            <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              QR kód nebol načítaný alebo mu nezodpovedá žiadne zariadenie.
            </div>
          </li>
          } @else {
          @for (entity of entities; track entity.uuid) {
          <li>
            <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input (change)="onRadioChange(entity)" id="new-report-entities-list-entity-{{entity.uuid}}"
                  name="new-report-entities-list" type="radio" value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ms-2 text-sm">
                <label for="new-report-entities-list-entity-{{entity.uuid}}"
                  class="font-medium text-gray-900 dark:text-gray-300">
                  <div>
                    <b>Typ:</b> {{ entity.type || 'Neznámy typ' }} - {{ (entity.name ? entity.name : 'Neznáme označenie') }}
                  </div>
                  <p class="text-xs font-normal text-gray-500 dark:text-gray-300">
                    <b>UUID:</b> {{ (entity.uuid ? entity.uuid : 'Neznáme UUID') }}<br />
                    <b>Adresa:</b> {{ (entity.address ? entity.address : 'Neznáma adresa') }}
                  </p>
                </label>
              </div>
            </div>
          </li>
          }
          }
        </ul>
      </div> -->
    </div>
    <div class="hidden p-2 rounded-t-lg bg-gray-50 dark:bg-gray-800" id="new-report-type-tab-content-list" role="tabpanel"
      aria-labelledby="new-report-type-tab-list">
      <p class="text-sm text-gray-500 dark:text-gray-400">
        Zadajte kritéria pre vyhľadanie zariadenia v zozname.
        Môžete vyhľadať zariadenie podľa typu, identifikátora alebo adresy.
      </p>
      <br />
      <div class="mb-2">
        <select [(ngModel)]="formSelectEntityType" id="new-report-device-type"
          class="mb-2 disabled:cursor-not-allowed text-sm rounded-lg border  block w-full p-2.5
          bg-white dark:bg-gray-700 text-gray-900 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="" selected>Typ zariadenia nerozhoduje</option>
          <option value="rvo">Rozvádzač</option>
          <option value="lightplace">Svetelné miesto</option>
          <option value="lightpoint">Svetelný bod</option>
        </select>
      </div>

      <div class="mb-2">
        <input [(ngModel)]="formInputEntityName" type="text" id="new-report-dedvice-id"
          class="disabled:cursor-not-allowed text-sm rounded-lg block w-full p-2.5
          bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Číslo na štíku atď." required>
        <!-- <div class="text-xs font-semibold text-gray-500 flex justify-center my-1">UUID: {{ (selectedEntityUuid ?
          selectedEntityUuid : 'neznáme' ) }}</div> -->
      </div>

      <div class="mb-2">
        <input [(ngModel)]="formInputEntityAddress" type="text" id="new-report-device-address"
          class="disabled:cursor-not-allowed bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Adresa / poloha zariadení" required>
      </div>

      <div class="flex justify-center mb-2">
        <button type="button" (click)="searchDevicesByParams()"
          class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          Vyhľadať</button>
      </div>

      <!-- <br />

      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Zoznam dostupných zariadení</label>
      <div class="bg-white max-h-64 overflow-auto">
        <ul class="p-1 space-y-1 text-sm text-gray-700 dark:text-gray-200">
          @if (entities.length === 0) {
          <li>
            <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              Zadaným kritériám nezodpovedá žiadne zariadenie.
            </div>
          </li>
          } @else {
          @for (entity of entities; track entity.uuid) {
          <li>
            <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input (change)="onRadioChange(entity)" id="new-report-entities-list-entity-{{entity.uuid}}"
                  name="new-report-entities-list" type="radio" value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ms-2 text-sm">
                <label for="new-report-entities-list-entity-{{entity.uuid}}"
                  class="font-medium text-gray-900 dark:text-gray-300">
                  <div>
                    {{ entity.uuid || 'Neznámy typ' }} - {{ entity.uuid || 'Neznáme označenie' }}&nbsp;
                  </div>
                  <p class="text-xs font-normal text-gray-500 dark:text-gray-300">
                    {{ (entity.uuid ? entity.uuid : 'Neznáma adresa') }}<br />
                  </p>
                </label>
              </div>
            </div>
          </li>
          }
          }
        </ul>
      </div> -->
    </div>
  </div>

  <!-- List of available devices -->
  <div class="p-2 rounded-b-lg bg-gray-50 dark:bg-gray-800">
  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
    Vyberte požadované zariadenie zo zoznamu</label>
  <div class="bg-white max-h-64 overflow-auto">
    <ul class="p-1 space-y-1 text-sm text-gray-700 dark:text-gray-200">
      @if (entities && entities.length <= 0) {
      <li>
        <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600 flex-col">
          <div class="text-center">Zoznam je prázdny.</div>
          <div class="text-center">Vyberte zariadenie z mapy, oskenujte QR kód alebo vyhľadajte zariadenie manuálne.</div>
        </div>
      </li>
      } @else {
      @for (entity of entities; track entity.uuid) {
      <li>
        <div class="flex p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          <div class="flex items-center h-5">
            <input (change)="onRadioChange(entity)" id="new-report-entities-list-entity-{{entity.uuid}}"
              name="new-report-entities-list" type="radio" value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
          </div>
          <div class="ms-2 text-sm">
            <label for="new-report-entities-list-entity-{{entity.uuid}}"
              class="font-medium text-gray-900 dark:text-gray-300">
              <div>
                <b>Typ:</b> {{ entity.type || 'Neznámy typ' }} - {{ (entity.name ? entity.name : 'Neznáme označenie') }}
              </div>
              <p class="text-xs font-normal text-gray-500 dark:text-gray-300">
                <b>UUID:</b> {{ (entity.uuid ? entity.uuid : 'Neznáme UUID') }}<br />
                <b>Adresa:</b> {{ (entity.address ? entity.address : 'Neznáma adresa') }}
              </p>
            </label>
          </div>
        </div>
      </li>
      }
      }
    </ul>
  </div>
  </div>



  <div class="mt-8 mb-2">
    <label for="new-report-incident-type" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      Typ poruchy
    </label>
    @if (selectedEntityType === 'rvo') {
    <select [(ngModel)]="selectedIncidentType" id="new-report-incident-type" [ngClass]="{
        'border-gray-300': (selectedIncidentType && selectedIncidentType.length > 0),
        'focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:border-grey-600': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),

        'border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0)
      }"
      class="mb-2 bg-gray-50 dark:bg-gray-700 text-gray-900 text-sm rounded-lg border  block w-full p-2.5  dark:placeholder-gray-400 dark:text-white">
      <option value="DAMAGED_RVO">Poškodený rozvádzač</option>
      <option value="OTHER_PROBLEM">Iný problém</option>
    </select>
    } @else if (selectedEntityType === 'lightplace') {
    <select [(ngModel)]="selectedIncidentType" id="new-report-incident-type" [ngClass]="{
        'border-gray-300': (selectedIncidentType && selectedIncidentType.length > 0),
        'focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:border-grey-600': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),

        'border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0)
      }"
      class="mb-2 bg-gray-50 dark:bg-gray-700 text-gray-900 text-sm rounded-lg border  block w-full p-2.5  dark:placeholder-gray-400 dark:text-white">
      <option value="LIGHT_PLACE_LIGHTING_PROBLEM">Problém so svetelným miestom</option>
      <option value="LIGHT_PLACES_LIGHTING_PROBLEM">Problém so svetelnými miestami</option>
      <option value="DAMAGED_3P_DEVICE">Poškodené zariadenia 3. strany</option>
      <option value="DAMAGED_LIGHT_PLACE">Poškodené svetelné miesto</option>
      <option value="OTHER_PROBLEM">Iný problém</option>
    </select>
    } @else if (selectedEntityType === 'lightpoint') {
    <select [(ngModel)]="selectedIncidentType" id="new-report-incident-type" [ngClass]="{
          'border-gray-300': (selectedIncidentType && selectedIncidentType.length > 0),
          'focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
          'focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
          'dark:border-grey-600': (selectedIncidentType && selectedIncidentType.length > 0),
          'dark:focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
          'dark:focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),

          'border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
          'focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
          'focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
          'dark:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
          'dark:focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
          'dark:focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0)
        }"
      class="mb-2 bg-gray-50 dark:bg-gray-700 text-gray-900 text-sm rounded-lg border  block w-full p-2.5  dark:placeholder-gray-400 dark:text-white">
      <option value="LIGHT_PLACE_LIGHTING_PROBLEM">Problém s lampou</option>
      <option value="LIGHT_PLACES_LIGHTING_PROBLEM">Problém s osvetlením</option>
      <option value="DAMAGED_3P_DEVICE">Poškodené zariadenia 3. strany</option>
      <option value="DAMAGED_LIGHT_PLACE">Poškodené svetelné miesto</option>
      <option value="OTHER_PROBLEM">Iný problém</option>
    </select>
    } @else {
    <select [(ngModel)]="selectedIncidentType" id="new-report-incident-type" [ngClass]="{
        'border-gray-300': (selectedIncidentType && selectedIncidentType.length > 0),
        'focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:border-grey-600': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:ring-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:border-blue-500': (selectedIncidentType && selectedIncidentType.length > 0),

        'border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:ring-red-500': !(selectedIncidentType && selectedIncidentType.length > 0),
        'dark:focus:border-red-500': !(selectedIncidentType && selectedIncidentType.length > 0)
      }"
      class="mb-2 bg-gray-50 dark:bg-gray-700 text-gray-900 text-sm rounded-lg border  block w-full p-2.5  dark:placeholder-gray-400 dark:text-white">
    </select>
    }
  </div>

  <div class="mb-8">
    <label for="new-report-comment"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Komentár</label>
    <textarea  [(ngModel)]="comment" id="new-report-comment" rows="4"
      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="Zadajte popis problému a dodatočné informácie ..."></textarea>
  </div>

  <!-- <div class="flex justify-center mb-8">
    <button type="button" (click)="resetSelection()"
      class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
      Resetovať výber</button>
  </div> -->

  <div
    class="mb-2 flex items-center p-4 text-sm text-gray-800 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600"
    role="alert">
    <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
      fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
    <span class="sr-only">Info</span>
    <div>
      <span class="font-medium">Zoznam existujúcich porúch pre dané miesto</span>
    </div>
  </div>

  <div class="mb-8 bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase dark:text-gray-400 border-b border-gray-200 dark:border-gray-700">
        <tr>
          <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
            Dátum / Kód
          </th>
          <th scope="col" class="px-6 py-3">
            Porucha / Stav
          </th>
        </tr>
      </thead>
      <tbody>
        @if (relatedIncidents.length > 0) {
        @for (item of relatedIncidents; track item.id) {
          <tr class="border-b border-gray-200 dark:border-gray-700">
            <th scope="row"
              class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
              {{ item.evidenceDate | date: 'dd.MM.yyyy HH:mm:ss' }} <br /> {{ item.code }}
            </th>
            <td class="px-2 py-2">
              <div class="mb-1">{{ nameMapping[item.name] }}</div>
              @if (item.state === 'inprogress') {
                <span
                  class="whitespace-nowrap inline-flex items-center bg-yellow-100 text-yellow-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                  <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    viewBox="0 0 512 512">
                    <path
                      d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                  </svg>
                  Aktívne</span>
                } @else if (item.state === 'new') {
                <span
                  class="whitespace-nowrap inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300">
                  <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    viewBox="0 0 384 512">
                    <path
                      d="M192 32c17.7 0 32 14.3 32 32V199.5l111.5-66.9c15.2-9.1 34.8-4.2 43.9 11s4.2 34.8-11 43.9L254.2 256l114.3 68.6c15.2 9.1 20.1 28.7 11 43.9s-28.7 20.1-43.9 11L224 312.5V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V312.5L48.5 379.4c-15.2 9.1-34.8 4.2-43.9-11s-4.2-34.8 11-43.9L129.8 256 15.5 187.4c-15.2-9.1-20.1-28.7-11-43.9s28.7-20.1 43.9-11L160 199.5V64c0-17.7 14.3-32 32-32z" />
                  </svg>
                  Nové</span>
                } @else if (item.state === 'solved') {
                <span
                  class="whitespace-nowrap inline-flex items-center bg-green-100 text-green-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
                  <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    viewBox="0 0 448 512">
                    <path
                      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                  Vyriešené</span>
                } @else if (item.state === 'unresolved') {
                <span
                  class="whitespace-nowrap inline-flex items-center bg-orange-100 text-orange-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-orange-900 dark:text-orange-300">
                  <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    viewBox="0 0 512 512">
                    <path
                      d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                  </svg>
                  Uzatvorené bez riešenia</span>
                } @else if (item.state === 'terminated') {
                <span
                  class="whitespace-nowrap inline-flex items-center bg-red-100 text-red-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-red-900 dark:text-red-300">
                  <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    viewBox="0 0 448 512">
                    <path
                      d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                  </svg>
                  Zrušené</span>
                } @else if (item.state === 'pending') {
                <span
                class="whitespace-nowrap inline-flex items-center bg-purple-100 text-purple-800 text-sm font-medium px-2.5 py-1 rounded-full dark:bg-purple-900 dark:text-purple-300">
                <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 384 512">
                  <path
                    d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9H112z" />
                </svg>
                Pozastavené</span>
                }
            </td>
          </tr>
        }
        } @else {
        <tr>
          <td colspan="2" class="px-6 py-3 text-center text-gray-500 dark:text-gray-400">
            Žiadne poruchy
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>

  <div
    class="mb-2 flex items-center p-4 text-sm text-gray-800 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600"
    role="alert">
    <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
      fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
    <span class="sr-only">Info</span>
    <div>
      <span class="font-medium">Pasport informácie k danému zariadeniu</span>
    </div>
  </div>

  <div class="flex justify-center mt-8 mb-4">
    <button [disabled]="(!isValid)" type="button" (click)="createReport()" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
      disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed">
      Vytvoriť poruchu</button>
  </div>

  @if (!isValid) {
  <div class="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
    role="alert">
    <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
      fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
    <span class="sr-only">Warning</span>
    <div>
      Pre vytvorenie poruchy je potrebné vyplniť všetky povinné polia.
    </div>
  </div>
  }

  <!-- Bottom of the page to add some space for bottom navigation -->
  <div>
    <br /><br /><br /><br /><br /><br /><br />
  </div>

</div>