import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  requestPermission(): void {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      console.log('Notification permission granted');
    } else if (Notification.permission !== 'denied') {
      console.log('Please allow notification');
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted');
        }
      });
    }
  }

  sendNotification(title: string, options?: NotificationOptions): void {
    // TODO: Not using client side notifications anymore. Notification logic moved to service worker
    return;
    // if (Notification.permission === 'granted') {
    //   console.log('Notification permission granted');
    //   if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    //     console.log('Service worker is active');
    //     navigator.serviceWorker.ready.then(registration => {
    //       // Check if the service worker registration has an active worker
    //       if (registration.active) {
    //         // suppress notifications if the app is in the foreground
    //         if (document.visibilityState === 'visible') {
    //           console.log('App is in the foreground. Not sending notification.');
    //           return;
    //         }
    //         registration.showNotification(title, {
    //           ...options,
    //           actions: [
    //             { action: 'closeNotification', title: 'Zatvoriť' }
    //           ],
    //           data: {
    //             "onActionClick": {
    //               "default": { "operation": "focusLastFocusedOrOpen", "url": "" },
    //               "closeNotification": { "operation": "closeNotification" }
    //             }
    //           }
    //         } as NotificationOptions & { actions: Array<{ action: string, title: string }> });
    //       } else {
    //         // Service worker is registered but not active yet
    //         console.error('Service worker is registered but not active. Notifications cannot be shown.');
    //       }
    //     }).catch(error => {
    //       // Handle cases where the service worker is not ready or registration failed
    //       console.error('Service worker is not ready or registration failed:', error);
    //     });
    //   } else {
    //     console.log('Service worker is not active or not supported, using classic Notification API');
    //     // Fallback to classic Notification if serviceWorker is not active or not supported
    //     new Notification(title, options);
    //   }
    // } else {
    //   console.log('Cannot send notification: permission not granted');
    // }
  }
}