// TODO: update the incident state with separate save button
import { Component } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService, Agent, Task, Incident } from '@services/data.service';
import { FormsModule } from '@angular/forms';
import { AgentSelectComponent } from '@app/features/incidents/agent-select/agent-select.component';
import { MaintenanceComponent } from '@app/features/incidents/maintenance/maintenance.component';
import { Subscription } from 'rxjs';

interface AgentChangeEvent {
  type: string;
  id: number;
  value: string;
}

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [CommonModule, AgentSelectComponent, FormsModule, MaintenanceComponent],
  templateUrl: './details.component.html',
  styleUrl: './details.component.css'
})
export class DetailsComponent {
  private subscriptions = new Subscription();

  incidentOrderNumber: number = 0;
  incidentsCount: number = 0;
  incidents: Incident[] = [];
  incident: Incident | null = null;
  incidentTasks: Task[] = [];
  task: Task | null = null;
  agents: Agent[] = [];
  state: string; // Property bound to the select element
  comment: string = '';

  newIncidentAgent: string = '';
  newIncidentAgentId: number = -1;

  constructor(public dataService: DataService) {
    this.state = this.dataService.incidentStates[0].value;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.getIncident().subscribe((data: Incident | null) => {
        this.incident = data;
        this.updateIncidentOrderNumber();
        this.state = this.incident?.state || this.dataService.incidentStates[0].value;
        this.newIncidentAgent = this.incident?.agent || '';
        this.comment = this.incident?.comment || '';
      })
    );

    this.subscriptions.add(
      this.dataService.getIncidentsFiltered().subscribe((data: Incident[]) => {
        this.incidents = data;
        this.incidentsCount = this.incidents.length;
        this.updateIncidentOrderNumber();
      })
    );

    this.subscriptions.add(
      this.dataService.getIncidentTasks().subscribe((data: Task[]) => {
        this.incidentTasks = data;

        // retrieve unique sources and device types occurances of each incident
        const deviceTypeOccurrences = new Map<string, number>();
        for (const task of this.incidentTasks) {
          const deviceTypeName = task.deviceTypeName || 'Zariadenie';
          deviceTypeOccurrences.set(deviceTypeName, (deviceTypeOccurrences.get(deviceTypeName) || 0) + 1);
        }
        const deviceTypeOccurrencesArray = Array.from(deviceTypeOccurrences.entries()).map(([deviceTypeName, occurrences]) => ({ deviceTypeName, occurrences }));
        if (this.incident) {
          this.incident.deviceTypeNameOccurrences = deviceTypeOccurrencesArray;
        }

        const uniqueSources = new Set();
        for (const task of this.incidentTasks) {
          uniqueSources.add(task.source || 'Neznámy zdroj');
        }
        const uniqueSourcesArray = Array.from(uniqueSources);
        if (this.incident) {
          this.incident.uniqueSources = uniqueSourcesArray;
        }
      })
    );

    this.subscriptions.add(
      this.dataService.getTask().subscribe((data: Task | null) => {
        this.task = data;
      })
    );

    this.subscriptions.add(
      this.dataService.getAgents().subscribe((data: Agent[]) => {
        this.agents = data;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateIncidentOrderNumber(): void {
    if (this.incident && this.incidents) {
      const index = this.incidents.findIndex(incident => incident.id === this.incident?.id);
      if (index !== -1) {
        this.incidentOrderNumber = index + 1;
      }
    }
  }

  showPreviousIncident(): void {
    // check if is not the first record
    if (this.incidentOrderNumber === 1) {
      return;
    }

    // try to find previous incident id
    const previousIncident = this.incidents[this.incidentOrderNumber - 2];

    // refetch data from data.service
    if (previousIncident && previousIncident.id) {
      this.dataService.updateTask(null);
      this.dataService.fetchApigetIncident(previousIncident.id);
      this.dataService.fetchApiGetIncidentTasks(previousIncident.id);
    }
  }

  showNextIncident(): void {
    // check if is not the last record
    if (this.incidentOrderNumber === this.incidentsCount) {
      return;
    }

    // try to find next incident id
    const nextIncident = this.incidents[this.incidentOrderNumber];

    // refetch data from data.service
    if (nextIncident && nextIncident.id) {
      this.dataService.updateTask(null);
      this.dataService.fetchApigetIncident(nextIncident.id);
      this.dataService.fetchApiGetIncidentTasks(nextIncident.id);
    }
  }

  @Output() maintenanceClicked: EventEmitter<Task> = new EventEmitter();
  @Output() qrCodeScannerClicked: EventEmitter<Task> = new EventEmitter();
  showMaintenance(task: Task) {
    this.maintenanceClicked.emit(task);
  }
  // TODO:
  showQrCodeScanner(task: Task) {
    this.qrCodeScannerClicked.emit(task);
  }
  onQrCodeScannerClicked(task: Task) {
    this.showQrCodeScanner(task);
  }

  async saveIncident() {
    if (this.incident) {
      await this.dataService.fetchApiPatchIncidentStateAndComment(this.incident.id, this.state, this.comment);
      await this.dataService.fetchApiGetIncidentTasks(this.incident.id);
    }
  }

  async saveIncidentAgent() {
    if (this.incident && this.newIncidentAgentId) {
      await this.dataService.fetchApiPatchIncidentAgent(this.incident.id, this.newIncidentAgentId);
      await this.dataService.fetchApiGetIncidentTasks(this.incident.id);
    }
  }

  async handleIncidentAgentChange(event: AgentChangeEvent) {
    const agent = this.agents.find(item => item.email === event.value);
    this.newIncidentAgent = agent?.email || '';
    this.newIncidentAgentId = agent?.id || -1;
  }

  // async onStateChange(newStateValue: string) {
  //   // Update the incident state
  //   if (this.incident?.id) {
  //     //await this.dataService.fetchApiPatchIncidentState(this.incident.id, newStateValue);
  //   }
  // }

}
