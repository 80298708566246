export const environment = {
  DEFAULT_PRIMARY_LOCATION_UUID: '69687290-9793-46c8-b688-94791175e0b7',
  // TODO: remove this after keycloak migration
  URL_AUTH: 'https://liafe.cloudmv.online/liabe/api/v1/control/auth',

  URL_AUTH_SIGNIN: 'https://liafe.cloudmv.online/liabe/auth/signin',
  URL_AUTH_SIGNOUT: 'https://liafe.cloudmv.online/liabe/auth/signout',
  URL_AUTH_REFRESH: 'https://liafe.cloudmv.online/liabe/auth/refresh',
  URL_AUTH_CHANGE_PASSWORD: 'https://liafe.cloudmv.online/liabe/auth/change-password',
  URL_AUTH_CHANGE_PIN: 'https://liafe.cloudmv.online/liabe/auth/change-pin',
  URL_AUTH_VERIFY_PIN: 'https://liafe.cloudmv.online/liabe/auth/verify-pin',

  URL_VAPID_KEY: 'https://liafe.cloudmv.online/liabe/api/v1/notification/key',
  URL_SUBSCRIBE: 'https://liafe.cloudmv.online/liabe/api/v1/notification/subscribe',

  URL_INCIDENTS: 'https://liafe.cloudmv.online/liabe/api/v1/incidents',
  URL_INCIDENTS_FILTER : 'https://liafe.cloudmv.online/liabe/api/v1/incidents/filter',
  URL_INCIDENTS_LASTUPDATE: 'https://liafe.cloudmv.online/liabe/api/v1/incidents/lastupdate',
  URL_TASKS: 'https://liafe.cloudmv.online/liabe/api/v1/tasks',
  URL_METRICS_TEMP: 'https://liafe.cloudmv.online/liabe/api/v1/data/lc/{id}/last',
  URL_METRICS_RVO: 'https://liafe.cloudmv.online/liabe/api/v1/data/rvoc/{id}/last',
  URL_METRICS_GRAPH_TEMP: 'https://liafe.cloudmv.online/liabe/api/v1/data/lc/{id}?from={from}&to={to}',
  URL_METRICS_GRAPH_RVO: 'https://liafe.cloudmv.online/liabe/api/v1/data/rvoc/{id}?from={from}&to={to}',
  URL_AGENTS: 'https://liafe.cloudmv.online/liabe/api/v1/agents',
  URL_ENTITIES: 'https://liafe.cloudmv.online/pasportbeng/api/v1/hierarchy?relationType=control',
  URL_PASPORT_HIERARCHYPROJECTION: 'https://liafe.cloudmv.online/pasportbeng/api/v1/hierarchyprojection',
  URL_PASPORT_ENTITY: 'https://liafe.cloudmv.online/pasportbeng/api/v1/hierarchy/{id}/parents?relationType=control',
  URL_PASPORT_ENTITY_BY_NAME: 'https://liafe.cloudmv.online/pasportbeng/api/v1/entities/name/{name}/type/{type}',
  URL_PASPORT_ENTITY_HIERARCHY : 'https://liafe.cloudmv.online/pasportbeng/api/v1/hierarchy/{id}',
  URL_PASPORT_HIERARCHY : 'https://liafe.cloudmv.online/pasportbeng/api/v1/hierarchy/{id}',
  URL_PASPORT_FILES: 'https://liafe.cloudmv.online/pasportbeng/api/v1/entities/{id}/files',
  URL_REPORT: 'https://liafe.cloudmv.online/liabe/api/v1/operator/task',
  URL_EXPORT: 'https://liafe.cloudmv.online/liabe/api/v1/export/',
  URL_CONTROL: 'https://liafe.cloudmv.online/liabe/api/v1/control/{id}/',
  URL_SSE: 'https://liafe.cloudmv.online/liabe/api/v1/sse',
  // get list of incidets for the object?
  URL_OBJECT : 'https://liafe.cloudmv.online/liabe/api/v1/object/{id}',

  URL_STATISTICS_INCIDENTS: 'https://liafe.cloudmv.online/liabe/api/v1/statistics/incidents',
  URL_STATISTICS_GENERAL: 'https://liafe.cloudmv.online/liabe/api/v1/statistics/general',
  URL_STATISTICS_CATEGORY: 'https://liafe.cloudmv.online/liabe/api/v1/statistics/category',

  // pasport templates
  URL_PASPORT_TEMPLATES: 'https://liafe.cloudmv.online/pasportbeng/api/v1/templates',
  // pasport entity
  URL_PASPORT_ENTITIES: 'https://liafe.cloudmv.online/pasportbeng/api/v1/entities',
  URL_PASPORT_RELATIONS: 'https://liafe.cloudmv.online/pasportbeng/api/v1/relations',
  URL_PASPORT_RELATIONS_DESCENDANTS: 'https://liafe.cloudmv.online/pasportbeng/api/v1/relations/descendants',
  URL_PASPORT_RELATIONS_ANCESTORS: 'https://liafe.cloudmv.online/pasportbeng/api/v1/relations/ancestors',
  URL_PASPORT_RELATIONS_ALLOWED: 'https://liafe.cloudmv.online/pasportbeng/api/v1/relations/allowed',
  URL_PASPORT_ENTITY_HISTORY: 'https://liafe.cloudmv.online/pasportbeng/api/v1/entities/{id}/history',
  // endpoint for exporting pasport data - csv and pdf formats
  URL_PASPORT_EXPORT: 'https://liafe.cloudmv.online/pasportbeng/api/v1/export/{type}',
  // artificial delay for UI while processing requests
  UI_PROCESSING_DELAY : 1000
};
