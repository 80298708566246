import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DataService, PasportItem } from '@app/services/data.service';
import { Subscription } from 'rxjs';

/**
 * Component for displaying device hierarchy.
 * This component shows the hierarchical structure of devices, including parents and children.
 */
@Component({
  selector: 'app-device-hierarchy',
  standalone: true,
  imports: [],
  templateUrl: './device-hierarchy.component.html',
  styleUrl: './device-hierarchy.component.css'
})
export class DeviceHierarchyComponent {
  private subscriptions = new Subscription();

  /** Identifier of the object (objectId) */
  @Input() uuid: string | undefined = '';
  /** Named type of the object (e.g., 'lightpoint') */
  @Input() deviceTypeName: string | undefined = '';
  /** Name of the object (e.g., 'RVO SHOWROOM') */
  @Input() objectName: string | undefined = '';
  /** Address of the object */
  @Input() address: string | undefined = '';

  /** Array of parent pasport entities */
  pasportEntityParents: PasportItem[] = [];
  /** Array of child pasport entities */
  pasportEntityChildren: PasportItem[] = [];

  constructor(
    public dataService: DataService
  ) {}

  /**
   * Lifecycle hook that is called when data-bound properties of a directive change.
   * Used to update the component when input properties change.
   * @param changes - Object containing changes to the input properties
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['uuid'] && this.uuid) {
      // Unsubscribe from existing subscriptions
      this.subscriptions.unsubscribe();

      // Create a new Subscription object
      this.subscriptions = new Subscription();

      this.subscriptions.add(
        // this expects the entity to be already loaded?
        this.dataService.getPasportEntity().subscribe(async (entity) => {
          this.pasportEntityParents = [];
          if (entity?.parents && entity.parents.length > 0) {
            let item = entity?.parents[0];
            let canContinue = false;
            if (item) {
              canContinue = true;
            }
            while (canContinue) {
              this.pasportEntityParents.push({
                uuid: item.uuid,
                name: item.name,
                address: item.address,
                type: item.type,
                coordinates: item.coordinates,
                data: item.data,
                parentRvoUuid: item.parentRvoUuid,
                parentData: item.parentData,
                children: undefined,
                parents: undefined,
                listOrderNumber: item.listOrderNumber
              });
              if (item?.children && item.children.length > 0 && (item.children[0].uuid !== this.uuid) ) {
                item = item?.children[0];
                canContinue = true;
              } else {
                canContinue = false;
              }
            }
          }

          // call the API to get the children for the current entity
          let resultChildren = await this.dataService.fetchApiGetPasportEntityHierarchyChildren(this.uuid || '');
          this.pasportEntityChildren = resultChildren?.children || [];
        })
      );
    }
  }

  onDestroy() {
    this.subscriptions.unsubscribe();
  }

  /** Event emitter that fires when a pasport detail is clicked, emitting the UUID string */
  @Output() emitDeviceHierarchyShowPasportDetails: EventEmitter<string> = new EventEmitter();

  /**
   * Emits an event when a passport detail needs to be shown
   * @param uuid The unique identifier of the passport entity to show details for
   * @emits onClickPasportDetails - Emits the uuid of the passport entity
   */
  showPasportDetails(uuid: string) {
    console.log('DeviceHierarchy showPasportDetails', uuid);
    this.emitDeviceHierarchyShowPasportDetails.emit(uuid);
  }
}
