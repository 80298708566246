import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatElapsedTime',
  standalone: true
})
export class FormatElapsedTimePipe implements PipeTransform {

  transform(refreshTrigger: any, dateString: string | undefined): string {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now.getTime() - date.getTime(); // milliseconds difference
    const diffSecs = diffMs / 1000; // seconds difference
    const diffMins = diffSecs / 60; // minutes difference
    const diffHours = diffMins / 60; // hours difference
    const diffDays = Math.floor( diffHours / 24 ); // days difference

    if (diffMins < 60) {
      return `${Math.round(diffMins)} min`;
    } else if (diffHours < 24) {
      return `${Math.round(diffHours)} h`;
    } else if (diffDays === 1) {
      return `1 deň`;
    } else if (diffDays === 2) {
      return `2 dni`;
    } else if (diffDays === 3) {
      return `3 dni`;
    } else if (diffDays === 4) {
      return `4 dni`;
    } else {
      return `${diffDays} dní`;
    }
  }
}
