@if (relations && relations.length > 0) {
<div class="mb-4"><b>Väzby zariadenia:</b></div>
<div>
  @for (relation of relations; track relation.id) {
  <div class="p-2 rounded-lg mb-4 bg-gray-50 dark:bg-gray-800 flex flex-col">

    <div class="mb-2">
      <b>Typ väzby: {{ relation.type }}</b>&nbsp;&nbsp;(ID väzby: {{ relation.id}})
    </div>
    <div class="mb-4">
      {{ dataService.formatEntityType(relation.ancestor.entityType.type) }} {{ relation.ancestor.data['name'] }} -> {{
      dataService.formatEntityType(relation.descendant.entityType.type) }} {{ relation.descendant.data['name'] }}
    </div>

    @if (deletingRelationId !== relation.id) {
    <div class="flex flex-wrap">
      <button type="button" (click)="toggleDeleteConfirmation(relation.id)"
        class="px-5 py-1.5 text-base font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 448 512">
          <path
            d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z" />
        </svg>&nbsp;&nbsp;Odstrániť väzbu
      </button>
    </div>
    } @else {
    <div class="text-center py-4 px-2 font-bold text-lg">Naozaj si prajete odstrániť väzbu ({{ relation.id }})?</div>
    <div class="flex flex-wrap">
      <button type="button" (click)="cancelDelete()" class="flex-1 mr-1 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800
            disabled:bg-red-400 disabled:dark:bg-red-500 disabled:cursor-not-allowed ">
        Nie, neodstraňovať
      </button>
      <button type="button" (click)="confirmDelete(relation.id)" class="flex-1 ml-1 text-white bg-green-500 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-green-500 dark:hover:bg-green-700 dark:focus:ring-green-800
            disabled:bg-green-400 disabled:dark:bg-green-500 disabled:cursor-not-allowed ">
        Áno, odstrániť
      </button>
    </div>
    }
  </div>
  }
</div>
} @else {
<div>Zariadenie zatiaľ nemá žiadne väzby</div>
}