import { Component } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService, Incident } from '@services/data.service';
import { FormatElapsedTimePipe } from '@app/shared/format-elapsed-time.pipe';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, FormatElapsedTimePipe],
  templateUrl: './table.component.html',
  styleUrl: './table.component.css'
})
export class TableComponent {
  data: Incident[] = [];

  constructor(public dataService: DataService) {}

  ngOnInit() {
    this.dataService.getIncidentsFilteredPaged().subscribe((data: Incident[]) => {
      this.data = data;
    });
  }

  @Output() detailClicked: EventEmitter<Incident> = new EventEmitter();
  @Output() showOnMapClicked: EventEmitter<Incident> = new EventEmitter();
  showDetails(incident: Incident) {
    this.detailClicked.emit(incident);
  }
  showIncidentOnMap(incident: Incident) {
    this.showOnMapClicked.emit(incident);
  }

}
