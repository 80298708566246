import { Component, EventEmitter, Output } from '@angular/core';
import { DataService, PasportHierarchyEntity } from '@app/services/data.service';
import { CommonModule } from '@angular/common';
import { FormatPasportEntityAttributesPipe } from '@app/shared/format-pasport-entity-attributes.pipe';
import { PasportEntity } from '@app/shared/interfaces/pasport-template.interface';
import { StateManagementService } from '@services/state-management.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-list-pasport',
  standalone: true,
  imports: [CommonModule, FormatPasportEntityAttributesPipe],
  templateUrl: './list-pasport.component.html',
  styleUrl: './list-pasport.component.css'
})
export class ListPasportComponent {
  data: PasportEntity[] = [];
  page: number = 0;
  settingsRecordsPerPage: number = 0;

  fetchApiGetPasportEntitiesProcessing: Observable<boolean> = new Observable<boolean>();
  fetchApiGetSimplifiedPasportEntitiesProcessing: Observable<boolean> = new Observable<boolean>();

  constructor(
    public dataService: DataService,
    private stateManagementService: StateManagementService
  ) { }

  ngOnInit() {
    this.dataService.getPasportEntities().subscribe((data: PasportEntity[]) => {
      this.data = data;
    });
    this.dataService.getPasportEntitiesPagesActive().subscribe((data: number) => {
      this.page = data;
    });
    this.stateManagementService.getSettingsRecordsPerPage().subscribe(recordsPerPage => {
      this.settingsRecordsPerPage = recordsPerPage;
    });

    this.fetchApiGetSimplifiedPasportEntitiesProcessing = this.dataService.fetchApiGetSimplifiedPasportEntitiesProcessing.asObservable();
    this.fetchApiGetPasportEntitiesProcessing = this.dataService.fetchApiGetPasportEntitiesProcessing.asObservable();
  }

  @Output() detailClicked: EventEmitter<PasportEntity> = new EventEmitter();
  @Output() showOnMapClicked: EventEmitter<PasportEntity> = new EventEmitter();
  showDetails(pasportEntity: PasportEntity) {
    this.detailClicked.emit(pasportEntity);
  }
  showPasportEntityOnMap(pasportEntity: PasportEntity) {
    this.showOnMapClicked.emit(pasportEntity);
  }

}
