// agent-select.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataService, Incident, Task, Agent } from '@services/data.service';

interface AgentChangeEvent {
  type: string;
  id: number;
  value: string;
}

@Component({
  selector: 'app-agent-select',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './agent-select.component.html',
  styleUrl: './agent-select.component.css'
})
export class AgentSelectComponent {
  @Input() agent: string | undefined = ''; // Add a default value or initialize the property
  @Input() data: Incident | Task | null | undefined = undefined; // Update the default value to undefined
  @Input() dataType: string = ''; // Add a default value or initialize the property
  @Output() agentChange: EventEmitter<AgentChangeEvent> = new EventEmitter<AgentChangeEvent>(); // Add the output event emitter

  agents: Agent[] = [];

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.getAgents().subscribe(data => {
      this.agents = data;
    });
  }

  onAgentChange(newAgentValue: string) {
    // Create the agent change event object
    if (this.data?.id) {
      const agentChangeEvent: AgentChangeEvent = {
        type: this.dataType,
        id: this.data?.id,
        value: newAgentValue
      };

      // Emit the new agent value
      this.agentChange.emit(agentChangeEvent);
    }
  }
}
