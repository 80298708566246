@if (this.isLocked) {
<div class="absolute z-1000 w-full h-full bg-gray-50 dark:bg-gray-800">

  <div class="flex flex-col items-center justify-center h-full">
    <div class="flex space-x-6 mb-16" [style.animation]="bounceAnimation">
      @for (digit of [1, 2, 3, 4]; track digit) {
      <div class="w-8 h-8 rounded-full transition-colors duration-300"
        [ngClass]="{'bg-blue-300': digit > pinLength, 'bg-blue-600': digit <= pinLength}">
      </div>
      }
    </div>

    <div class="grid grid-cols-3 gap-5 bg-white dark:bg-gray-800 rounded-3xl px-6 pt-6 pb-5">
      @for (number of [1, 2, 3, 4, 5, 6, 7, 8, 9]; track number) {
      <button class="w-14 h-14 rounded-2xl bg-gray-200 dark:bg-gray-700 text-2xl font-bold focus:ring-4 focus:outline-none focus:ring-gray-100"
        (click)="enterDigit(number)">
        {{ number }}
      </button>
      }
      <div></div>
      <button class="w-14 h-14 rounded-2xl bg-gray-200 dark:bg-gray-700 text-2xl font-bold focus:ring-4 focus:outline-none focus:ring-gray-100" (click)="enterDigit(0)">
        0
      </button>
      @if (pinLength > 0) {
      <button
        class="w-14 h-14 rounded-2xl bg-gray-200 dark:bg-gray-700 text-2xl font-bold flex items-center justify-center focus:ring-4 focus:outline-none focus:ring-gray-100"
        (click)="backspace()">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 20 20" viewBox="0 0 576 512">
          <path
            d="M576 128c0-35.3-28.7-64-64-64L205.3 64c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7L512 448c35.3 0 64-28.7 64-64l0-256zM271 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
        </svg>
      </button>
      }
    </div>

    <div class="flex items-center mt-16 mb-4 mx-4 p-4 text-sm text-gray-800 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600" role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span class="sr-only">Info</span>
      <div>
        Zadajte <span class="font-medium">PIN</span> alebo choďte na <a href="/signin" class="font-medium hover:underline">prihlásenie</a>.
      </div>
    </div>

  </div>

</div>
}