<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
      <th scope="col" class="px-4 py-3">&nbsp;</th>
      <th scope="col" class="px-4 py-3">&nbsp;</th>
      <th scope="col" class="px-4 py-3">Typ</th>
      <th scope="col" class="px-4 py-3">Názov</th>
      <!-- <th scope="col" class="px-4 py-3">
          <span class="whitespace-nowrap inline-flex items-center">Kód</span>
        </th> -->
      <th scope="col" class="px-4 py-3">
        <span class="whitespace-nowrap inline-flex items-center">ID</span>
      </th>
      <th scope="col" class="px-4 py-3">Parametre</th>
      <th scope="col" class="px-4 py-3">
        <span class="sr-only">Actions</span>
      </th>
    </tr>
  </thead>
  <tbody>
    @for (pasportEntity of data; track pasportEntity.uuid) {
      <tr [id]="'table-pasport-row-'+pasportEntity.uuid" class="border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <th scope="row" class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          <!-- @if (incident.updateAvailable === true) {
            <span class="inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">&nbsp;</span>&nbsp;
        }-->
          <b (click)="showDetails(pasportEntity)" class="hover:underline hover:cursor-pointer">&#35;&nbsp;{{ pasportEntity.listOrderNumber }}</b>
        </th>
        <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          <button type="button" (click)="showPasportEntityOnMap(pasportEntity)"
            class="px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
            <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 384 512">
              <path
                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
            </svg>
          </button>
        </td>
        <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {{ dataService.formatEntityType(pasportEntity.data.entityType) }}
          @if (pasportEntity?.parentData?.entityType === 'location') {
          <br /><span class="text-base text-gray-500 dark:text-gray-400">({{ pasportEntity.parentData.name }})</span>
          }
        </td>
        <td class="px-4 py-3">
          {{ pasportEntity.data.name }}
        </td>
        <td class="px-4 py-3">
          {{ pasportEntity.uuid }}
        </td>
        <td class="px-4 py-3" [innerHTML]="pasportEntity.data | formatPasportEntityAttributes"></td>

        <!-- <td class="px-4 py-3 flex items-center justify-end"> -->
        <td class="px-4 py-3">
          <button (click)="showDetails(pasportEntity)"
            class="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
            type="button">
            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </button>
        </td>
      </tr>
      } @empty {
      <tr>
        <td colspan="8" class="text-center py-2">Žiadne záznamy</td>
      </tr>
      }
  </tbody>
</table>