/**
 * A pipe that formats and transforms pasport entity attributes into a displayable HTML string.
 *
 * This pipe takes a pasport entity's data object and formats its attributes into a human-readable
 * HTML string with proper translations and formatting. It handles:
 * - Filtering out internal/system attributes
 * - Translating attribute keys using a dictionary
 * - Special formatting for location-related attributes (address, lat/lng)
 * - Converting boolean values to yes/no text
 * - Sanitizing output HTML
 *
 * @example
 * // In template:
 * {{ pasportEntity.data | formatPasportEntityAttributes }}
 *
 * // Input object:
 * {
 *   name: "Device1",
 *   address: "123 Main St",
 *   latitude: 48.123,
 *   longitude: 19.456,
 *   isActive: true
 * }
 *
 * // Outputs sanitized HTML like:
 * // <span class="location-block">
 * //   <span class="font-bold">Address:</span> 123 Main St
 * //   <span class="font-bold">Lat:</span> 48.123
 * //   <span class="font-bold">Lng:</span> 19.456
 * // </span>
 * // <span class="font-bold">Is Active:</span> áno
 */

import { Pipe, PipeTransform } from '@angular/core';
import { StateManagementService } from '../services/state-management.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataService } from '@services/data.service';
import { AuthService } from '@services/auth.service';

@Pipe({
  name: 'formatPasportEntityAttributes',
  standalone: true
})
export class FormatPasportEntityAttributesPipe implements PipeTransform {

  constructor(
    private stateManagementService: StateManagementService,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
    private authService: AuthService
  ) { }

  transform(value: any): SafeHtml {
    if (!value) return ''; // If no data, return empty string

    // get template that matches the entity type
    const template = this.dataService.pasportTemplates.find(t => t.entityType.type === value.entityType);
    // Get user's roles
    const userRoles = this.authService.userDetails?.privileges || [];
    // Get list of readable attributes based on user's roles
    const readableAttributes = new Set<string>();

    // Filter (R)ead access permissions based on user's roles
    template?.data.permissions
      .filter(permission => userRoles.includes(permission.role))
      .forEach(permission => {
        Object.entries(permission.accessLevels).forEach(([attribute, access]) => {
          if (access.includes('R')) {
            readableAttributes.add(attribute);
          }
        });
      });

    // List of keys to exclude
    const excludeKeys = ['name', 'entityType', 'importUUID', 'uuid', 'PasportType'];
    let mainResult: string[] = [];
    let locationBlock: string[] = [];

    // Iterate over the object and build the formatted string
    for (const key in value) {
      // Skip if attribute is not in readable attributes
      if (!readableAttributes.has(key)) continue;

      let displayValue = value[key];
      if (value.hasOwnProperty(key) &&
          !excludeKeys.includes(key) &&
          displayValue !== null &&
          displayValue !== undefined &&
          displayValue !== 'null' &&
          displayValue !== 'undefined' &&
          displayValue !== '') {
        let translatedKey = this.stateManagementService.getPasportTemplateDictionary()[key] || key;
        let formattedKey = translatedKey.charAt(0).toUpperCase() + translatedKey.slice(1);

        // Handle boolean values
        if (displayValue === true || displayValue === 'true') {
          displayValue = 'áno';
        } else if (displayValue === false || displayValue === 'false') {
          displayValue = 'nie';
        }

        let formattedValue = `<span class="font-bold">${formattedKey}:</span> ${displayValue}`;

        if (['address', 'latitude', 'longitude'].includes(key)) {
          if (key === 'latitude') translatedKey = 'Lat';
          if (key === 'longitude') translatedKey = 'Lng';
          formattedKey = translatedKey.charAt(0).toUpperCase() + translatedKey.slice(1);
          formattedValue = `<span class="font-bold">${formattedKey}:</span> ${displayValue}`;
          locationBlock.push(formattedValue);
        } else {
          mainResult.push(formattedValue);
        }
      }
    }

    // Create the location block
    let locationHtml = '';
    if (locationBlock.length > 0) {
      locationHtml = `
        <span class="location-block">
          ${locationBlock[0]} ${locationBlock.slice(1).join(' ')}
        </span>
      `;
    }

    // Join the main result
    const mainHtml = mainResult.join(', ');

    // Combine location block and main result
    const htmlString = locationHtml + mainHtml;

    // Sanitize the HTML string to prevent XSS attacks
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
}