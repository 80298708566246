import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export interface SseNotification {
  tableName: string;
  operation: string;
  id: number;
  notifiDate: Date;
}

@Injectable({
  providedIn: 'root',
})
export class SseService implements OnDestroy {
  private eventSource: EventSource | undefined;
  private sseNotification = new Subject<SseNotification>();
  private readonly reconnectInterval = 5000; // Time to wait before attempting reconnect (in milliseconds)

  constructor() { }

  get getSseNotification$() {
    return this.sseNotification.asObservable();
  }

  startEventSource(url: string): void {
    this.initializeEventSource(url);
  }

  private initializeEventSource(url: string): void {
    this.eventSource = new EventSource(url);

    this.eventSource.addEventListener('notification', (event) => {
      const data: SseNotification = JSON.parse(event.data);
      this.sseNotification.next(data);  // Notify subscribers about the update
    });

    this.eventSource.addEventListener('error', (error) => {
      console.error('SSE error:', error);
      this.eventSource?.close();
      this.eventSource = undefined; // Clear the EventSource

      // Attempt to reconnect after a delay
      setTimeout(() => this.initializeEventSource(url), this.reconnectInterval);
    });
  }

  ngOnDestroy(): void {
    this.eventSource?.close();
  }
}
