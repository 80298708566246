import { Component, EventEmitter, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DataService, Incident, IncidentReport, nameMapping, PasportItem, publicIncidentEnum, Task } from '@services/data.service';
import { AuthService, DummyUser } from '@services/auth.service';
import { ToastComponent } from '@app/shared/components/toast/toast.component';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-new-report',
  standalone: true,
  imports: [FormsModule, CommonModule, ToastComponent],
  templateUrl: './new-report.component.html',
  styleUrl: './new-report.component.css'
})
export class NewReportComponent {
  //@ViewChild('toastContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('toastContainer', { read: ViewContainerRef }) container: ViewContainerRef | undefined = undefined;

  nameMapping = nameMapping;

  userDetails: DummyUser | null = null;

  //entity: PasportItem | null = null;
  entities: PasportItem[] = [];

  formSelectEntityType: string = '';
  formInputEntityName: string = '';
  formInputEntityAddress: string = '';

  selectedEntityType: string = '';
  selectedEntityUuid: string = '';
  // selectedEntityName: string = '';
  // selectedEntityAddress: string = '';

  //publicIncidentOptions: { key: string; value: string }[] = Object.entries(publicIncidentEnum).map(([key, value]) => ({ key, value }));
  selectedIncidentType: string | null = '';

  relatedIncidents: Incident[] = [];

  comment: string = '';

  constructor(
    private dataService: DataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    initFlowbite();

    this.dataService.getQrCodeObjectNameScanned().subscribe(async (data: string) => {
      // this.dataService.fetchApiGetPasportEntityByName(data);
      // testing 044.1117
      //this.dataService.fetchApiGetPasportEntityByName('044.1117');
      this.entities = await this.dataService.fetchApiGetPasportEntityByParams('',data,'');
      this.formSelectEntityType = '';
      this.formInputEntityName = data;
      this.formInputEntityAddress = '';

      this.relatedIncidents = [];
      this.selectedEntityType = '';
      this.selectedEntityUuid = '';
    });

    this.dataService.getPasportEntity().subscribe(entity => {

      this.entities = [];
      this.selectedEntityType = '';
      this.selectedEntityUuid = '';

      if ((entity) && (!entity?.data.address)) {
        entity.data.address = entity?.parentData?.address || '';
      }

      let selectedEntityType = '';
      let selectedEntityName = '';
      let selectedEntityUuid = '';
      let selectedEntityAddress = '';

      try {
        selectedEntityType = entity?.data.entityType || '';
        // FIXME: hardcoded check
        if ((selectedEntityType !== 'rvo') && (selectedEntityType !== 'lightplace') && (selectedEntityType !== 'lightpoint')) {
          selectedEntityType = '';
        }

        // set manual search values
        this.formSelectEntityType = selectedEntityType;
        this.formInputEntityName = entity?.data.name || '';
        this.formInputEntityAddress = entity?.data.address || '';

        selectedEntityName = entity?.data.name || '';
        selectedEntityUuid = entity?.data.uuid || '';
        selectedEntityAddress = entity?.data.address || '';

        this.selectedIncidentType = '';
        this.entities.push({
          uuid: selectedEntityUuid,
          name: selectedEntityName,
          address: selectedEntityAddress,
          type: selectedEntityType,
          coordinates: {
            latitude: entity?.data.latitude || 0,
            longitude: entity?.data.longitude || 0
          },
          data: undefined,
          parentRvoUuid: entity?.parentData?.parentRvoUuid || undefined,
          parentData: undefined,
          children: undefined,
          parents: undefined,
          listOrderNumber: entity?.listOrderNumber || 0
        });
      } catch (e) {
        console.log(e);
      }
    });

    this.authService.getUserDetails().subscribe((data: DummyUser | null) => {
      this.userDetails = data;
    });
  }

  @Output() qrCodeScannerClicked: EventEmitter<void> = new EventEmitter();

  // FIXME: this will stop working after server side filtering will be implemented
  private getRelatedIncidents(selectedEntityUuid: string) {
    try {
      this.relatedIncidents = [];
      const incdidents: Incident[] = this.dataService.getFetchedDataIncidents();

      for (const incident of incdidents) {
        if (incident.objectId == selectedEntityUuid) {
          this.relatedIncidents.push(incident);
        } else {
          for (const task of incident.tasks) {
            if (task.objectId == selectedEntityUuid) {
              this.relatedIncidents.push(incident);
              break;
            }
          }
        }
      }

    } catch (e) { }
  }

  showQrCodeScanner() {
    this.dataService.updateQrCodeObjectName(''); // Clear the previous QR code object name
    this.qrCodeScannerClicked.emit();
  }

  get isValid(): boolean {
    return this.selectedEntityUuid !== '' && this.selectedIncidentType !== null && this.selectedIncidentType !== '';
  }

  async createReport() {
    // TODO: better validation
    if (!this.userDetails?.email) {
      alert('Pre vykonanie tejto operácie nemáte oprávnenia!');
      return;
    }
    if (!this.selectedEntityUuid || !this.selectedIncidentType) {
      alert('Vyplňte všetky povinné údaje!');
      return;
    }

    let report: IncidentReport = {
      uuid: this.selectedEntityUuid,
      incidentType: this.selectedIncidentType,
      email: this.userDetails.email,
      description: this.comment
    };

    let result = await this.dataService.fetchApiPostReport(report);

    if (result) {
      this.showToast();
      document.getElementById('drawer-new-report-button-close')!.click();
    } else {
      alert('Chyba!');
    }
  }

  showToasxxxt() {
    this.container?.clear(); // Use optional chaining
    const toastComponentRef = this.container?.createComponent(ToastComponent);
    // Further logic...
  }

  showToast(): void {
    const toastRef = this.container?.createComponent(ToastComponent);
    if (!toastRef) {
      return;
    }
    toastRef.instance.closeToast.subscribe(() => {
      toastRef.destroy(); // Remove the toast component from the DOM when the close event is emitted
    });
  }

  onRadioChange(event: any) {
    this.selectedEntityType = event.type;
    this.selectedEntityUuid = event.uuid;

    this.getRelatedIncidents(this.selectedEntityUuid);
  }

  searchDevicesByParams() {
    this.entities = [];
    this.relatedIncidents = [];
    this.selectedEntityType = '';
    this.selectedEntityUuid = '';
    this.dataService.fetchApiGetPasportEntityByParams(this.formSelectEntityType, this.formInputEntityName, this.formInputEntityAddress).then((data) => {
      this.entities = data;
    });
  }

  // TODO: remove after testing
  async test() {
    this.entities = await this.dataService.fetchApiGetPasportEntityByParams('','044.1117','');
    this.formSelectEntityType = '';
    this.formInputEntityName = '044.1117';
    this.formInputEntityAddress = '';

    this.selectedEntityType = '';
    this.selectedEntityUuid = '';
  }

}
