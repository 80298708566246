import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService, nameMapping } from '@services/data.service';
//import { DashboardCategoryCount, DashboardStatistics, TimeInterval } from '@app/shared/interfaces/dashboard.interface';
import { StatisticsGeneralResponse, StatisticsCategoryResponse, TimeInterval } from '@app/shared/interfaces/dashboard.interface';
import { FormatDhmTimePipe } from '@app/shared/format-dhm-time.pipe';
import ApexCharts from 'apexcharts';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, FormatDhmTimePipe],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {
  @ViewChild('chartResponseTime') chartElement!: ElementRef;
  @ViewChild('chartResolveTime') chartResolveTimeElement!: ElementRef;
  chartResponseTime: ApexCharts | undefined;
  chartResolveTime: ApexCharts | undefined;
  dashboardStatistics: StatisticsGeneralResponse | undefined;
  dashboardStatistics24: StatisticsGeneralResponse | undefined;
  dashboardCategoryCount: StatisticsCategoryResponse[] = [];

  nameMapping = nameMapping;

  constructor(
    private dataService: DataService
  ) {}

  /**
   * Lifecycle hook that is called after a component's view has been fully initialized.
   *
   * @description
   * This method is part of Angular's component lifecycle. It is called once all the component's
   * view and child views have been initialized. In this implementation, it performs two key tasks:
   *
   * 1. Initializes the charts by calling the `initChart()` method.
   * 2. Refreshes the component's data by calling the `refresh()` method.
   *
   * @remarks
   * - The `initChart()` method is responsible for setting up and rendering the ApexCharts
   *   used in the dashboard.
   * - The `refresh()` method is responsible for fetching or updating the latest data
   *   for the dashboard.
   *
   * It's important to note that this method is called only once per component instance,
   * after the first `ngAfterContentChecked()`.
   */
  ngAfterViewInit() {
    this.initChart();
    this.refresh();
  }

  /**
   * Initializes the ApexCharts for response time and resolve time.
   * This method creates and renders two charts if ApexCharts is available.
   *
   * @remarks
   * This method is called in the ngAfterViewInit lifecycle hook.
   * It uses the @ViewChild decorated properties to access the chart elements.
   *
   * @returns void
   */
  initChart() {
    if (typeof ApexCharts !== 'undefined') {
      this.chartResponseTime = new ApexCharts(this.chartElement.nativeElement, this.getChartOptions());
      this.chartResolveTime = new ApexCharts(this.chartResolveTimeElement.nativeElement, this.getChartOptions());
      this.chartResponseTime.render();
      this.chartResolveTime.render();
    }
  }

  /**
   * Returns the configuration options for the ApexCharts radial bar chart.
   *
   * @returns {ApexCharts.ApexOptions} The chart configuration options.
   *
   * @description
   * This method configures a radial bar chart with the following key features:
   * - Three series (initially set to [0, 0, 0])
   * - Custom colors for each series
   * - Chart dimensions and type (radial bar)
   * - Sparkline enabled for a compact display
   * - Custom styling for the radial bars, including track background and hollow size
   * - Hidden grid and data labels
   * - Custom labels for each series ("Max", "Priemer", "Min")
   * - Legend configuration
   * - Disabled tooltip
   * - Hidden y-axis
   *
   * The chart is designed to display three values (typically maximum, average, and minimum)
   * in a compact, radial format suitable for dashboard display.
   */
  getChartOptions(): ApexCharts.ApexOptions {
    return {
      series: [0, 0, 0],
      colors: ["#FDBA8C", "#1C64F2", "#16BDCA"],
      chart: {
        height: 150,
        width: '100%',
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          track: {
            background: '#E5E7EB',
          },
          dataLabels: {
            show: false,
          },
          hollow: {
            margin: 0,
            size: "32%",
          }
        },
      },
      grid: {
        show: false
      },
      labels: ["Max", "Priemer", "Min"],
      legend: {
        show: true,
        position: "right",
        fontFamily: "Inter, sans-serif",
      },
      tooltip: {
        enabled: false,
        x: {
          show: false,
        },
      },
      yaxis: {
        show: false
      }
    };
  }


  /**
   * Refreshes the dashboard data and updates the charts.
   *
   * This method performs the following actions:
   * 1. Fetches the latest dashboard statistics from the API.
   * 2. Updates the response time chart if it exists.
   * 3. Updates the resolve time chart if it exists.
   *
   * For both charts, it calculates the total time, minimum time, and average time
   * based on the fetched statistics. It then normalizes these values to percentages
   * for chart display, ensuring a minimum value of 5% for visibility.
   *
   * @returns {Promise<void>}
   */
  public async refresh(): Promise<void> {
    // reset dashboard
    this.resetDashboard();
    // from and to timestamps for general and category statistics for last 30 days
    // const from = Date.now() - 30 * 24 * 60 * 60 * 1000; // 30 days ago
    // TODO: 0 for now, until the final decision
    const from = 0;
    const to = Date.now();
    // from and to timestamps for general statistics for last 24 hours
    const from24 = Date.now() - 24 * 60 * 60 * 1000; // 24 hours ago
    const to24 = Date.now();
    // Run API calls in parallel
    const [dashboardStatistics, dashboardStatistics24, dashboardCategoryCount] = await Promise.all([
      this.dataService.fetchApiGetStatisticsGeneral(from, to),
      this.dataService.fetchApiGetStatisticsGeneral(from24, to24),
      this.dataService.fetchApiGetStatisticsCategory(from, to)
    ]);
    this.dashboardStatistics = dashboardStatistics;
    this.dashboardStatistics24 = dashboardStatistics24;
    // Combine API results with nameMapping
    this.dashboardCategoryCount = Object.entries(nameMapping).map(([key, value]) => ({
      category: key,
      count: dashboardCategoryCount.find(item => item.category === key)?.count || 0,
      name: value
    }));

    // artificial delay to simulate loading
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Update response time chart
    if (this.chartResponseTime) {
      const totalTime = this.calculateTotalTime(this.dashboardStatistics.timeDifferences.maxIncidentResponseTime);
      let minTime = this.calculateTotalTime(this.dashboardStatistics.timeDifferences.minIncidentResponseTime);
      let avgTime = this.calculateTotalTime(this.dashboardStatistics.timeDifferences.averageIncidentResponseTime);

      avgTime = this.normalizeTimeValue(avgTime, totalTime);
      minTime = this.normalizeTimeValue(minTime, totalTime);

      this.chartResponseTime.updateSeries([100, avgTime, minTime]);
    }

    // Update resolve time chart
    if (this.chartResolveTime) {
      const totalTime = this.calculateTotalTime(this.dashboardStatistics.timeDifferences.maxIncidentResolveTime);
      let minTime = this.calculateTotalTime(this.dashboardStatistics.timeDifferences.minIncidentResolveTime);
      let avgTime = this.calculateTotalTime(this.dashboardStatistics.timeDifferences.averageIncidentResolveTime);

      avgTime = this.normalizeTimeValue(avgTime, totalTime);
      minTime = this.normalizeTimeValue(minTime, totalTime);

      this.chartResolveTime.updateSeries([100, avgTime, minTime]);
    }
  }

  /**
   * Calculates the total time in minutes from a TimeInterval object.
   *
   * @param {TimeInterval | undefined} time - The time interval to calculate
   * @returns {number} The total time in minutes
   */
  private calculateTotalTime(time: TimeInterval | undefined): number {
    return (time?.years ?? 0) * 365 * 24 * 60 +
           (time?.months ?? 0) * 30 * 24 * 60 +
           (time?.days ?? 0) * 24 * 60 +
           (time?.hours ?? 0) * 60 +
           (time?.minutes ?? 0);
  }

  /**
   * Normalizes a time value as a percentage of the total time,
   * ensuring a minimum value of 5%.
   *
   * @param {number} value - The time value to normalize
   * @param {number} totalTime - The total time for percentage calculation
   * @returns {number} The normalized time value as a percentage
   */
  private normalizeTimeValue(value: number, totalTime: number): number {
    let normalizedValue = (value / totalTime) * 100;
    return normalizedValue < 5 ? 5 : normalizedValue;
  }

  /**
   * Resets the dashboard charts and statistics.
   *
   * This method performs the following actions:
   * 1. Resets the response time chart to zero values if it exists.
   * 2. Resets the resolve time chart to zero values if it exists.
   * 3. Clears the dashboard statistics.
   *
   * @private
   * @returns {void}
   */
  private resetDashboard(): void {
    if (this.chartResponseTime) {
      this.chartResponseTime.updateSeries([0, 0, 0]);
    }
    if (this.chartResolveTime) {
      this.chartResolveTime.updateSeries([0, 0, 0]);
    }
    this.dashboardStatistics = undefined;
    return;
  }

}