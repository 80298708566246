@if ((fetchApiGetPasportEntitiesProcessing | async) || (fetchApiGetSimplifiedPasportEntitiesProcessing | async)) {
  <div class="px-3 py-1 even:bg-gray-100 border-b-gray-200 border-b">Načítavam záznamy...</div>
} @else {
@for (pasportEntity of data; track pasportEntity.uuid; let i = $index) {
<div [id]="'list-incidents-row-'+pasportEntity.uuid" class="px-3 py-1 even:bg-gray-100 border-b-gray-200 border-b">
  <div class="flex items-center mb-2">
    <div class="flex-grow" (click)="showDetails(pasportEntity)">
      <!-- @if (pasportEntity.updateAvailable === true) {
      <span
        class="inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">&nbsp;</span>&nbsp;
      } -->
      <b>{{ pasportEntity.data['name'] }}</b>
    </div>
    <div class="flex-shrink">
      <button type="button" (click)="showPasportEntityOnMap(pasportEntity)"
        class="mt-2 px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
        <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 384 512">
          <path
            d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
        </svg>
      </button>
    </div>
  </div>
  <div class="flex" (click)="showDetails(pasportEntity)">
    <div>
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-700 text-white text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-600">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 448 512">
          <path
            d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
        </svg>
        {{ ((page-1)*settingsRecordsPerPage) + i + 1 }}</span>

      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        {{ dataService.formatEntityType(pasportEntity?.entityType?.type) }}</span>

      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        {{ pasportEntity?.uuid || 'UUID neznáme' }}</span>

      <span [innerHTML]="pasportEntity?.data | formatPasportEntityAttributes"></span>
      <!-- <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
        </svg>
        {{ pasportEntity?.evidenceDate | date: 'dd.MM.yyyy HH:mm' || 'Missing date' }}</span>
      <span
        class="whitespace-nowrap inline-flex items-center mb-2 bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded-full dark:bg-gray-700 dark:text-gray-300">
        <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
        </svg>
        {{ null | formatElapsedTime: pasportEntity?.lastUpdateDate }}</span> -->

    </div>
  </div>
</div>
} @empty {
<div class="px-3 py-1 even:bg-gray-100 border-b-gray-200 border-b">No records</div>
}
}