import { Component } from '@angular/core';
import { DataService, PasportEntity } from '@services/data.service';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatElapsedTimePipe } from '@app/shared/format-elapsed-time.pipe';
import { FormatPasportEntityAttributesPipe } from '@app/shared/format-pasport-entity-attributes.pipe';

@Component({
  selector: 'app-table-pasport',
  standalone: true,
  imports: [CommonModule, FormatElapsedTimePipe, FormatPasportEntityAttributesPipe],
  templateUrl: './table-pasport.component.html',
  styleUrl: './table-pasport.component.css'
})
export class TablePasportComponent {
  data: PasportEntity[] = [];

  constructor(public dataService: DataService) {}

  ngOnInit() {
    this.dataService.getPasportEntitiesFilteredPaged().subscribe((data: PasportEntity[]) => {
      this.data = data;
    });
  }

  @Output() detailClicked: EventEmitter<PasportEntity> = new EventEmitter();
  @Output() showOnMapClicked: EventEmitter<PasportEntity> = new EventEmitter();
  showDetails(pasportEntity: PasportEntity) {
    this.detailClicked.emit(pasportEntity);
  }
  showPasportEntityOnMap(pasportEntity: PasportEntity) {
    this.showOnMapClicked.emit(pasportEntity);
  }
}
