import { Component } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { FormsModule } from '@angular/forms';
import { CommonModule, NgSwitch } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiResponsePostPasportEntity, PasportEntity, PasportEntityDetails, PasportEntityRelation, PasportTemplateEntity } from '@app/shared/interfaces/pasport-template.interface';
import { PasportDeviceRelationsComponent } from '@app/features/incidents/pasport-device-relations/pasport-device-relations.component';
import { PasportDeviceNewRelationComponent } from "../pasport-device-new-relation/pasport-device-new-relation.component";

@Component({
  selector: 'app-pasport-new-device',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, NgSwitch, PasportDeviceRelationsComponent, PasportDeviceNewRelationComponent],
  templateUrl: './pasport-new-device.component.html',
  styleUrl: './pasport-new-device.component.css'
})
export class PasportNewDeviceComponent {

  templates: PasportTemplateEntity[] = [];
  selectedTemplateId: number | null = null;
  selectedTemplate: PasportTemplateEntity | null = null;
  selectedParentUuid: string | null = null;
  paramValues: { [key: string]: any } = {};
  newEntity: PasportEntity | null = null;
  relations: PasportEntityRelation[] = [];
  pasportEntityId: number | undefined;
  entityDetails: PasportEntityDetails | null = null;

  constructor(public dataService: DataService) {}

  ngOnInit() {
    console.log('Initializing pasport new device');
    this.initializeData();
  }

  ngOnDestroy() {
    console.log('Destroying pasport new device');
  }

  async initializeData() {
    // Fetch data or perform other initialization tasks here
    console.log('Initializing data for new device');
    this.selectedTemplateId = null;
    this.onTemplateChange(null);
    this.newEntity = null;
    this.relations = [];

    this.templates = await this.dataService.fetchApiGetPasportTemplates();
    this.templates.forEach(template => {
      delete template.data.params['uuid'];
      delete template.data.params['entityType'];
      delete template.data.params['reactivePowerCompensationType'];
      delete template.data.params['importUUID'];
      delete template.data.params['pasportType'];
    });
  }

  onTemplateChange(newValue: any) {
    // find specific template based on id
    this.selectedTemplate = this.templates.find(template => template.id === newValue) ?? null;
    this.resetParams();
  }

  private resetParams() {
    this.paramValues = {};
    if (this.selectedTemplate?.data?.params) {
      for (const key in this.selectedTemplate.data.params) {
        this.paramValues[key] = null;
      }
    }
  }

  objectEntries(obj: any): [string, any][] {
    return obj ? Object.entries(obj) : [];
  }

  get isValid(): boolean {
    return true;
  }

  /**
   * Creates a new device based on the selected template and parent.
   *
   * This method performs the following steps:
   * 1. Logs the parameter values and selected parent UUID.
   * 2. Checks if the selected template has valid entityType id and type.
   * 3. If valid, calls the API to create a new entity.
   * 4. Logs the created device.
   * 5. If the template is not valid, logs an error.
   *
   * @async
   * @returns {Promise<void>}
   * @throws {Error} If the API call fails.
   */
  async createDevice(): Promise<void> {
    console.log('Creating device', this.paramValues);
    console.log('Selected parent uuid', this.selectedParentUuid);
    // create new variable from params stripped of null values
    let paramValues = Object.fromEntries(Object.entries(this.paramValues).filter(([_, v]) => v !== null));
    console.log('paramValues', paramValues);

    if (this.selectedTemplate?.entityType?.id && this.selectedTemplate?.entityType?.type) {
      // call api to create entity
      const newEntityResult: ApiResponsePostPasportEntity = await this.dataService.fetchApiPostPasportEntity(
        this.selectedParentUuid,
        this.selectedTemplate.entityType.id,
        this.selectedTemplate.entityType.type,
        paramValues
      );

      if (newEntityResult) {
        this.newEntity = newEntityResult.entity;
      }
    } else {
      console.error('Selected template is not valid');
    }
  }

  /**
   * Handles the creation of a new relation.
   *
   * This method clears the existing relations array and fetches updated relations
   * for the current pasport entity if its ID is available.
   * Used for example after new relation is created in pasport-device-new-relation component.
   */
  handleNewRelation(): void {
    this.relations = [];
    if (this.newEntity?.id) {
      this.getRelations(this.newEntity.id);
    }
  }

  /**
   * Fetches and processes the relations (ancestors and descendants) for a given Pasport entity.
   *
   * @param id - The ID of the Pasport entity to fetch relations for.
   */
  async getRelations(id: number) {
    // Fetch both ancestor and descendant relations concurrently
    const [resultAncestors, resultDescendants] = await Promise.all([
      this.dataService.fetchApiGetPasportRelationsAncestors(id),
      this.dataService.fetchApiGetPasportRelationsDescendants(id)
    ]);

    // Process ancestor relations
    resultAncestors.relations.forEach(relation => {
      // Replace numeric ancestor reference with full ancestor object
      if (typeof (relation.ancestor as any) === 'number') {
        const ancestorId = relation.ancestor as unknown as number;
        const ancestor = resultAncestors.ancestors.find(a => a.id === ancestorId);
        if (ancestor) {
          relation.ancestor = ancestor;
        }
      }
      // Set descendant to current Pasport entity if available
      if (this.newEntity) {
        relation.descendant = {
          id: resultAncestors.id,
          uuid: resultAncestors.uuid,
          data: resultAncestors.data,
          entityType: resultAncestors.entityType,
          ancestors: [],
          descendants: []
        }
      }
    });

    // Process descendant relations
    resultDescendants.relations.forEach(relation => {
      // Replace numeric descendant reference with full descendant object
      if (typeof (relation.descendant as any) === 'number') {
        const descendantId = relation.descendant as unknown as number;
        const descendant = resultDescendants.descendants.find(d => d.id === descendantId);
        if (descendant) {
          relation.descendant = descendant;
        }
      }
      // Set ancestor to current Pasport entity if available
      if (this.newEntity) {
        relation.ancestor = {
          id: resultDescendants.id,
          uuid: resultDescendants.uuid,
          data: resultDescendants.data,
          entityType: resultDescendants.entityType,
          ancestors: [],
          descendants: []
        }
      }
    });

    // Combine ancestor and descendant relations
    this.relations = [...resultAncestors.relations, ...resultDescendants.relations];
  }


  /**
   * Retrieves and processes parameters for the newly created entity
   *
   * This method performs the following:
   * 1. Checks if a new entity exists, returns early if not
   * 2. Fetches entity details from the API using the new entity's UUID
   * 3. Updates the component's entity ID from the fetched details
   * 4. Sets the selected template from the entity details
   * 5. Filters out template parameters that don't have read access
   * 6. Resets existing parameter values
   * 7. Maps the entity data into parameter values for display/editing
   *
   * The method handles access control by removing parameters that don't have
   * read permissions ('R') in their access settings.
   *
   * @async
   * @returns Promise<void>
   */
  async getParams() {
    if (!this.newEntity) {
      return;
    }

    // call api to get current params and specific template for the entity
    this.entityDetails = await this.dataService.fetchApiGetPasportEntityDetails(this.newEntity.uuid);
    this.pasportEntityId = this.entityDetails?.entity?.id;

    // get template from the entity details
    this.selectedTemplate = this.entityDetails?.template ?? null;
    // remove attributes that data.params["key"].access does not contain "R"
    if (this.selectedTemplate?.data?.params) {
      for (const key in this.selectedTemplate.data.params) {
        if (!((this.selectedTemplate.data.params[key] as any).access?.includes('R'))) {
          delete this.selectedTemplate.data.params[key];
        }
      }
    }

    this.resetParams();

    // map existing entityDetails into paramValues
    if (this.entityDetails?.entity?.data) {
      for (const key in this.entityDetails.entity.data) {
        this.paramValues[key] = this.entityDetails.entity.data[key];
      }
    }
  }


}
