import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-preview',
  standalone: true,
  imports: [],
  templateUrl: './image-preview.component.html',
  styleUrl: './image-preview.component.css'
})
export class ImagePreviewComponent {
  @Input() imgSrc: SafeUrl = '';
  @Output() close = new EventEmitter<void>();

  /**
   * Closes the image preview component.
   * Emits the `close` event to notify the parent component.
   */
  public closeImagePreview() {
    // destroy the image preview component
    this.close.emit();
  }

}
