/**
 * PinService
 *
 * This service manages the PIN-based locking mechanism for the application.
 * It provides functionality to lock and unlock the app, and maintains the current lock state.
 *
 * Features:
 * - Maintains an observable lock state that components can subscribe to
 * - Provides methods to lock and unlock the application
 * - Validates PIN input (currently using a hardcoded PIN for demonstration)
 * - Offers a method to reset the lock state
 *
 * Usage:
 * Inject this service into components or other services that need to interact with the app's lock state.
 * Subscribe to the isLocked$ observable to react to changes in the lock state.
 * Use the lock(), unlock(), and resetLock() methods to control the app's lock state.
 *
 * Note: The current implementation uses a hardcoded PIN ('1234') for demonstration purposes.
 * TODO: Implement server-side PIN validation for enhanced security.
 */


import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { StateManagementService } from './state-management.service';

@Injectable({
  providedIn: 'root'
})
export class PinService {

  // locked state
  private isLocked: boolean = false;
  // isLocked$ = this._isLocked.asObservable();

  constructor(private authService: AuthService, private stateManagementService: StateManagementService) {
    //this.initLockState();
    this.stateManagementService.getIsLocked().subscribe((isLocked) => {
      this.isLocked = isLocked;
    });
  }

  // initLockState(): void {
  //   const isLocked = localStorage.getItem('isLocked');
  //   this.stateManagementService.setIsLocked(isLocked === 'true');
  // }

  /**
   * Attempts to unlock the service using the provided PIN.
   * @param pin The PIN to check against.
   * @returns {boolean} True if the PIN is correct and the service is unlocked, false otherwise.
   * TODO: use server side validation
   */
  async unlock(pin: string): Promise<boolean> {
    const success = await this.authService.verifyPIN(pin);

    if (success || (pin === '1234')) {
      this.stateManagementService.setIsLocked(false);
      return true;
    }
    return false;
  }

  /**
   * Locks the service, setting it to a locked state.
   * This method sets the 'isLocked' property to true, effectively securing the service.
   */
  lock(): void {
    // set app to locked state
    this.stateManagementService.setIsLocked(true);
  }

  /**
   * Resets the lock state of the service.
   * This method sets the 'isLocked' property to false, effectively unlocking the service without requiring a PIN.
   */
  resetLock(): void {
    this.stateManagementService.setIsLocked(false);
  }

  /**
   * Disables the lock state of the service.
   * This method sets the 'isLocked' property to false, effectively unlocking the service without requiring a PIN.
   * Unlike resetLock(), this method does not remove the 'isLocked' item from localStorage.
   * Used mostly for non-locked routes like /signin, /signup, /reset-password, etc.
   */
  disableLock(): void {
    this.stateManagementService.setIsLocked(false, false);
  }
}
