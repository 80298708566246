import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { inject } from '@angular/core';
import { PinService } from '@app/services/pin.service';

@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  constructor(
    private authService: AuthService,
    private router: Router,
    private pinService: PinService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    if (state.url === '/signin') {
      this.pinService.disableLock();
      return true;
    }

    if (!this.authService.isLoggedIn()) {
      const refreshed = await this.authService.renewWithRefreshToken();
      if (!refreshed) {
        this.router.navigate(['/signin']);
        return false;
      }
    }

    return true;
  }
}

export const AuthGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> => {
  return await inject(PermissionsService).canActivate(next, state);
}
