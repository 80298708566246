// TODO: use filter for fetching data (client side)
// TODO: improve pooling mechanism
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateManagementService, MapFilterCheckboxStates, MapFilterOrderState } from './state-management.service';
import { AuthService, DummyUser } from './auth.service';
import { SseService } from './sse.service';
import { NotificationService } from '@services/notification.service';
import { PushNotificationService } from '@services/push-notification.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
// import { DashboardCategoryCount, DashboardStatistics } from '@app/shared/interfaces/dashboard.interface';
import { StatisticsGeneralResponse, StatisticsCategoryResponse } from '@app/shared/interfaces/dashboard.interface';
import { ApiResponseDeletePasportRelation, ApiResponseGetAncestors, ApiResponseGetDescendants, ApiResponseGetPasportEntities, ApiResponsePostPasportEntity, ApiResponsePostPasportRelations, PasportEntityDetails, PasportTemplateDictionary, PasportTemplateEntity } from '@app/shared/interfaces/pasport-template.interface';
import { ApiResponseGetPasportEntityHistory } from '@app/shared/interfaces/pasport-entity-history.interface';
import { ApiResponseGetPasportRelationsAllowed, PasportAllowedRelation } from '@app/shared/interfaces/pasport-allowed-relations.interface';


export enum QrCodeVerificationStatus {
  NoMatch = -1,
  NotTested = 0,
  Match = 1
};

// TODO: move interfaces to separate file?
interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Task {
  id: number;
  taskId: number;
  incidentId: number;
  objectId: string;
  address: string;
  coordinates: Coordinates;
  priority: number;
  name: string;
  state: string;
  comment: string;
  source: string;
  deviceTypeName: string;
  objectName: string;
  evidenceDate: string;
  startDate: string;
  endDate: string;
  agent: string;
  updateAvailable: boolean;
  importance: number;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

export interface Incident {
  id: number;
  agent: string;
  comment: any;
  startDate: any;
  endDate: any;
  evidenceDate: string;
  lastUpdateDate: string;
  name: string;
  objectId: string;
  state: string;
  structureUuuids: any[];
  tasks: Task[];
  code: string;
  uniqueSources: any[];
  deviceTypeNameOccurrences: any[];
  uniqueDeviceTypeNames: any[];
  uniqueAgents: any[];
  listOrderNumber: number;
  address: string;
  updateAvailable: boolean;
  importance: number;
}

// TODO: rename to MetricsLC, also all related stuff
export interface MetricsTemp {
  power: number;
  cosPhy: number;
  current: number;
  dimming: number;
  voltage: number;
  temperature: number;
  type: string;
  dataTs: string;
}

export interface MetricsRvo {
  DO1: number;
  DO2: number;
  DO3: number;
  DO4: number;
  DO5: number;
  DO6: number;
  DO7: number;
  DO8: number;
  VCC: number;
  DIN1: number;
  DIN2: number;
  DIN3: number;
  DIN4: number;
  DIN5: number;
  DIN6: number;
  DIN7: number;
  DIN8: number;
  VBAT: number;
  type: string;
  dataTs: string;
}

export interface MetricsGraphItemData {
  id: number;
  ts: number;
  data: {
    power: number;
    cosPhy: number;
    current: number;
    dimming: number;
    voltage: number;
    temperature: number;
  };
  type: string;
}
export interface MetricsGraphRvoItemData {
  id: number;
  ts: number;
  data: {
    VCC: number;
    VBAT: number;
  };
  type: string;
}

export interface MetricsGraphItem {
  id: string;
  data: MetricsGraphItemData;
  timestamp: string;
  dataTs: string;
}
export interface MetricsGraphRvoItem {
  id: string;
  data: MetricsGraphRvoItemData;
  timestamp: string;
  dataTs: string;
}

export interface Agent {
  id: number;
  name: string;
  email: string;
  password: string;
  privileges: any;
}

interface NameMapping {
  [key: string]: string;
}

export interface PasportItemSource {
  uuid: string;
  type: string;
  name: string;
  lat: number;
  lng: number;
  data: any;
  parentRvoUuid: string | undefined;
  children: PasportItemSource[] | undefined;
  listOrderNumber: number;
}

export interface PasportItem {
  uuid: string;
  name: string;
  address: string;
  type: string;
  coordinates: Coordinates;
  data: any;
  parentRvoUuid: string | undefined;
  parentData: any;
  children: PasportItem[] | undefined;
  parents: PasportItem[] | undefined;
  listOrderNumber: number;
}

export interface PasportEntitySource {
  uuid: string;
  type: string;
  name: string;
  lat: number;
  lng: number;
  data: any;
  parentRvoUuid: string | undefined;
  children: PasportEntitySource[] | undefined;
  listOrderNumber: number;
}

export interface PasportEntity {
  uuid: string;
  type: string;
  name: string;
  coordinates: Coordinates;
  data: any;
  parentRvoUuid: string | undefined;
  parentData: any;
  children: PasportEntity[] | undefined;
  listOrderNumber: number;
}

export interface IncidentReport {
  uuid: string;
  incidentType: string;
  email: string;
  description: string;
}

interface BaseControlDeviceDetails {
  uuid: string;
  status: boolean;
  timestamp: number;
}
export interface ModeControlDeviceDetails extends BaseControlDeviceDetails {
  mode: string;
}
export interface IntensityControlDeviceDetails extends BaseControlDeviceDetails {
  intensity: number;
}
export type ControlDeviceDetails = ModeControlDeviceDetails | IntensityControlDeviceDetails;

export interface NotificationItem {
  id: string;
  title: string;
  message: string;
  timestamp: Date;
  timestampFormattedISO: string;
  timestampFormattedLocal: string;
  read: boolean;
  incident: Incident | null;
  task: Task | null;

  ready: boolean;
  incidentId: number | null;
  taskIds: Set<number>;
  fetchIncidentStarted: boolean;
}

export interface PasportItemFile {
  id: string;
  entityUuid: string;
  uuid: string;
  name: string;
  tags: string;
  creationDate: Date;
}

// TODO: retrieve this dynamically, hardcoded is bad, m'kay?
export const nameMapping: NameMapping = {
  POWER_SUPPLY_FAILURE: 'Porucha napájania', //'Power Supply', 1
  LIGHTING_FAILURE: 'Porucha svietenia', //'Lighting Failure', 2
  DIMMING_FAILURE: 'Porucha stmievania', //'Dimming Failure', 2
  COMMUNICATION_FAILURE: 'Porucha komunikácie', //'Communication Failure', 3
  DEVICE_FAILURE: 'Porucha zariadenia', //'Device Failure', 4
  CONFIGURATION_FAILURE: 'Porucha konfigurácie', //'Configuration Failure', 5
  OPERATIONAL_ISSUE: 'Prevádzková porucha', // 'Funkčná porucha', //'Operational Issue' 5
  APPEARENCE_ISSUE: 'Vizuálne poškodenie', //'Appearance Issue', 6
  OTHER_ISSUE: 'Iný problém', //'Other Issue', 6
};

export const publicIncidentEnum = {
  "rvo" : {
  DAMAGED_RVO : 'Poškodený rozvádzač',
  OTHER_PROBLEM : 'Iný problém'
  },
  "lightpoint" : {
    LIGHT_PLACE_LIGHTING_PROBLEM : 'Problém s lampou',
    LIGHT_PLACES_LIGHTING_PROBLEM : 'Problém s osvetlením',
    DAMAGED_3P_DEVICE : 'Poškodené zariadenia 3. strany',
    DAMAGED_LIGHT_PLACE : 'Poškodené svetelné miesto',
    OTHER_PROBLEM : 'Iný problém'
    },
  "lightplace"  : {
    LIGHT_PLACE_LIGHTING_PROBLEM : 'Problém s lampou',
    LIGHT_PLACES_LIGHTING_PROBLEM : 'Problém s osvetlením',
    DAMAGED_3P_DEVICE : 'Poškodené zariadenia 3. strany',
    DAMAGED_LIGHT_PLACE : 'Poškodené svetelné miesto',
    OTHER_PROBLEM : 'Iný problém'
  }
}


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private pollingInterval: number = 60000; // 60 seconds
  private pollingTimer: any;
  private polingTimerMetrics: any;
  private poolingMetricsCounter = new BehaviorSubject<number>(60);

  private controllerApiGetIncidents = new AbortController();
  private controllerApiGetIncident = new AbortController();
  private controllerApiGetIncidentTasks = new AbortController();
  private controllerApiGetTask = new AbortController();
  private controllerApiGetMetricsTemp = new AbortController();
  private controllerApiGetMetricsGraph = new AbortController();
  private controllerApiGetPasportItems = new AbortController();
  private controllerApiGetPasportEntity = new AbortController();
  private controllerApiGetPasportEntities = new AbortController();
  private controllerApiGetPasportEntityHierarchyParents = new AbortController();
  private controllerApiGetPasportEntityHierarchyChildren = new AbortController();
  private controllerApiGetPasportEntityFileList = new AbortController();

  private user: String = '';

  private fetchedDataIncidents: Incident[] = [];
  private incidentsFiltered: Incident[] = [];
  private incidentsFilteredPaged: Incident[] = [];
  private incidentsChangeDetected: boolean = false;

  private incidentsSubject = new BehaviorSubject<Incident[]>([]);
  private incidentsFilteredSubject = new BehaviorSubject<Incident[]>([]);
  private incidentsFilteredPagedSubject = new BehaviorSubject<Incident[]>([]);
  private incidentsChangeDetectedSubject = new BehaviorSubject<boolean>(false);
  public fetchApiGetIncidentsProcessing = new BehaviorSubject<boolean>(false);

  private incidentSubject = new BehaviorSubject<Incident | null>(null);
  private tasksSubject = new BehaviorSubject<Task[]>([]);
  private incidentTasksSubject = new BehaviorSubject<Task[]>([]);
  private taskSubject = new BehaviorSubject<Task | null>(null);
  private qrCodeVerifiedSubject = new BehaviorSubject<QrCodeVerificationStatus>(QrCodeVerificationStatus.NotTested);
  private qrCodeObjectName = new BehaviorSubject<string>('');
  private qrCodeObjectNameScanned = new BehaviorSubject<string>('');
  private metricsTempSubject = new BehaviorSubject<any>(null);
  private metricsRvoSubject = new BehaviorSubject<any>(null);
  private metricsGraph = new BehaviorSubject<MetricsTemp[]>([]);
  private metricsGraphRvo = new BehaviorSubject<MetricsRvo[]>([]);
  // TODO: do we still need to use this? logic moved to incidents component?
  // TODO: yes we need this here, because in incidents component we see only specific page and we cant calculate total pages
  private incidentsPagesTotalSubject = new BehaviorSubject<number>(0);
  private incidentsPagesActiveSubject = new BehaviorSubject<number>(0);
  private pasportEntitiesPagesTotalSubject = new BehaviorSubject<number>(0);
  private pasportEntitiesPagesActiveSubject = new BehaviorSubject<number>(0);

  // TODO: rename to pasportEntity, remove old pasportItems
  private pasportItems: PasportItem[] = [];
  private pasportItemsSubject = new BehaviorSubject<PasportItem[]>([]);
  private pasportEntity: PasportItem | null = null;
  private pasportEntitySubject = new BehaviorSubject<PasportItem | null>(null);

  private pasportEntities: PasportEntity[] = [];
  private pasportEntitiesFiltered: PasportEntity[] = [];
  private pasportEntitiesFilteredPaged: PasportEntity[] = [];
  private pasportEntitiesSubject = new BehaviorSubject<PasportEntity[]>([]);
  private pasportEntitiesFilteredSubject = new BehaviorSubject<PasportEntity[]>([]);
  private pasportEntitiesFilteredPagedSubject = new BehaviorSubject<PasportEntity[]>([]);
  public fetchApiGetPasportEntitiesProcessing = new BehaviorSubject<boolean>(false);


  private fetchedDataAgents: Agent[] = [];
  private agentsSubject = new BehaviorSubject<Agent[]>([]);

  private states: MapFilterCheckboxStates = this.stateManagementService.getDefaultState();
  private orderState: MapFilterOrderState = this.stateManagementService.getDefaultOrderState();
  private fulltext: string = '';
  // TODO: change name related to specific table / dataset only. ie incidentsRecordsPerPage?
  public settingsRecordsPerPage: number = 25;
  private pasportFilterRvos: string[] = [];

  private lastTaskForMetrics: Task | null = null;

  userDetails: DummyUser | null = null;

  // TODO: use translation service for labels
  // TODO: hardcoded. modify as constant or dynamically retrieve from api. duplicity
  incidentStates = [
    { value: 'new', label: 'Nová porucha' },
    { value: 'inprogress', label: 'Aktívna porucha' },
    { value: 'pending', label: 'Dočasne pozastavené' },
    { value: 'solved', label: 'Vyriešené' },
    { value: 'unresolved', label: 'Uzatvorené bez riešenia' },
    { value: 'terminated', label: 'Zrušené' }
  ];
  taskStates = [
    { value: 'new', label: 'Nový servis' },
    { value: 'inprogress', label: 'Aktívny servis' },
    { value: 'pending', label: 'Dočasne pozastavené' },
    { value: 'solved', label: 'Vyriešené' },
    { value: 'unresolved', label: 'Uzatvorené bez riešenia' },
    { value: 'terminated', label: 'Zrušené' }
  ];

  //device control object - used for controlling different attributes like lightpoint intensity, rvo mode etc.
  //private controlDeviceDetails: ControlDeviceDetails | null = null;
  //private controlDeviceDetailsSubject = new BehaviorSubject<ControlDeviceDetails | null>(null);
  private controlDeviceDetailsMapSubject = new BehaviorSubject<Map<string, ControlDeviceDetails>>(new Map());

  private notificationItems: NotificationItem[] = [];
  private notificationItemsSubject = new BehaviorSubject<NotificationItem[]>([]);
  private notificationTimer: any;

  public pasportTemplates: PasportTemplateEntity[] = [];
  // static list of allowed relations for pasport items
  public allowedRelations: PasportAllowedRelation[] = [];

  constructor(
    private stateManagementService: StateManagementService,
    private authService: AuthService,
    private sseService: SseService,
    private notificationService: NotificationService,
    private pushService: PushNotificationService,
    private sanitizer: DomSanitizer
    //private cdr: ChangeDetectorRef
  ) {
    // liste for changes for number of records per page
    this.stateManagementService.getSettingsRecordsPerPage().subscribe(recordsPerPage => {
      this.settingsRecordsPerPage = recordsPerPage;
    });
    this.stateManagementService.getUser().subscribe(user => {
      this.user = user;
    });
    // listen for changes for checkboxes
    this.stateManagementService.getMapFilterCheckboxStates().subscribe(states => {
      console.log('getMapFilterCheckboxStates');
      if (
        states.statusNew !== this.states.statusNew ||
        states.statusInprogress !== this.states.statusInprogress ||
        states.statusPending !== this.states.statusPending ||
        states.statusSolved !== this.states.statusSolved ||
        states.statusUnresolved !== this.states.statusUnresolved ||
        states.statusTerminated !== this.states.statusTerminated
      )  {
        this.states = { ...states };
        this.fetchApiGetIncidents();
      } else {
        this.states = { ...states };
        this.filterIncidents(this.fetchedDataIncidents, this.orderState);
        this.pageIncidents(this.incidentsFiltered, this.orderState);
      }

      // FIXME: only if pasport related filter changed
      // this also reacts on fulltext related checkboxes even if the dataset refresh is not needed, for example searchInName, searchInUuid, searchInData
      // TODO: fix this. these checkboxes could be in different state management service object instead of mapFilterCheckboxStates
      // TODO: how does
      this.filterPasportEntities(this.pasportEntities);
      this.pagePasportEntities(this.pasportEntitiesFiltered);
    });
    // listen for changes for order
    this.stateManagementService.getMapFilterOrderState().subscribe(orderState => {
      this.orderState = orderState;
      this.filterIncidents(this.fetchedDataIncidents, this.orderState);
      this.pageIncidents(this.incidentsFiltered, this.orderState);
    });
    // listen for changes for fulltext
    this.stateManagementService.getMapFilterFulltextState().subscribe(fulltext => {
      console.log('fulltext', fulltext);
      this.fulltext = fulltext;
      if (typeof fulltext === 'string') {
        this.fulltext = fulltext.trim().toLowerCase();
      }
      this.filterIncidents(this.fetchedDataIncidents, this.orderState);
      this.pageIncidents(this.incidentsFiltered, this.orderState);

      this.filterPasportEntities(this.pasportEntities);
      this.pagePasportEntities(this.pasportEntitiesFiltered);
    });
    this.stateManagementService.getPasportFilterRvos().subscribe(rvos => {
      this.pasportFilterRvos = rvos;
      this.filterPasportEntities(this.pasportEntities);
      this.pagePasportEntities(this.pasportEntitiesFiltered);
    });
    // listen for changes for paging active page and trigger filtering
    this.incidentsPagesActiveSubject.subscribe(activePage => {
      this.filterIncidents(this.fetchedDataIncidents, this.orderState);
      this.pageIncidents(this.incidentsFiltered, this.orderState);
    });
    // listen for changes for paging active page and trigger filtering
    this.pasportEntitiesPagesActiveSubject.subscribe(activePage => {
      this.filterPasportEntities(this.pasportEntities);
      this.pagePasportEntities(this.pasportEntitiesFiltered);
    });

    this.authService.getUserDetails().subscribe((data: DummyUser | null) => {
      this.userDetails = data;
    });

    // immediately do something with the data
    this.sseService.getSseNotification$.subscribe(async (data: any) => {
      // refactoring
      let itemGrouped = false;
      let incidentId:number | null = null;
      let taskId:number | null = null;
      // get type of the sse notification
      // notification is incident insert
      if (data.operation === 'INSERT' && data.tableName === 'incident') {
        // TODO: find in existing non-displayed notifications if there is a match so we can group them. for the case insert for incident comes later than insert for task

        // create new notification item directly
        let uuid = this.generateUUID();
        let notificationItem: NotificationItem = {
          id: uuid,
          title: 'Nová porucha',
          message: 'V systéme bola pridaná nová porucha',
          timestamp: new Date(),
          timestampFormattedISO: new Date().toISOString(),
          timestampFormattedLocal: new Date().toLocaleString('sk-SK'),
          read: false,
          incident: null,
          task: null,

          ready: false,
          incidentId: +data.data.id,
          taskIds: new Set<number>(),
          fetchIncidentStarted: true
        };

        this.notificationItems.unshift(notificationItem);
        let result = await this.fetchApigetIncident(data.data.id, false, false);
        notificationItem.incident = result;
        notificationItem.ready = true;
      } else {
        // find in existing non-displayed notifications if there is a match so we can group them
        if (data.tableName === 'incident') {
          incidentId = data.data.id;
        }
        if (data.tableName === 'task') {
          taskId  = +data.data.id;
          incidentId = +data.data.incident_id;
        }

        let existingNotificationItem: NotificationItem | undefined;
        // TODO: search only in non-displayed notifications
        for (let notification of this.notificationItems) {
          // find incident by id
          if ((notification.read === false) && (notification.incidentId === incidentId)) {
            existingNotificationItem = notification;
            itemGrouped = true;
            break;
          }
        }

        // add new notification to the top of the list
        if (!itemGrouped) {
          let uuid = this.generateUUID();
          let notificationItem: NotificationItem = {
            id: uuid,
            title: 'Upravená porucha',
            message: 'V systéme bola upravená porucha',
            timestamp: new Date(),
            timestampFormattedISO: new Date().toISOString(),
            timestampFormattedLocal: new Date().toLocaleString('sk-SK'),
            read: false,
            incident: null,
            task: null,

            ready: false,
            incidentId: incidentId,
            taskIds: new Set<number>(),
            fetchIncidentStarted: true
          };
          if (taskId) {
            notificationItem.taskIds.add(taskId);
          }

          this.notificationItems.unshift(notificationItem);
          if (incidentId) {
            let result = await this.fetchApigetIncident(incidentId, false, false);
            notificationItem.incident = result;
          }
          notificationItem.ready = true;
        } else {
          // grouped notification already exists
          if (existingNotificationItem) {
            if (taskId) {
              existingNotificationItem.taskIds.add(taskId);
            }
            if (data.operation === 'INSERT') {
            existingNotificationItem.message = `Počet nových hlásení v poruche: ${existingNotificationItem.taskIds.size}`;
            } else if (data.operation === 'UPDATE') {
              existingNotificationItem.message = `Počet upravených hlásení v poruche: ${existingNotificationItem.taskIds.size}`;
            }
          }
        }
      }

      // clear existing timeout
      if (this.notificationTimer) {
        clearTimeout(this.notificationTimer);
      }
      // set new timeout
      this.notificationTimer = setTimeout(() => {
        // filter notifications with ready flag
        const notificationItems = this.notificationItems.filter(notificationItem => notificationItem.ready);
        this.updateNotificationItems(notificationItems);
        this.updateIncidentsChangeDetected(true);

        // set updateAvailable flag for incidents
        this.incidentsFiltered.forEach(incident => {
          if (incident.id == incidentId) {
            incident.updateAvailable = true;
          }
        });
      }, 1500);
    });

    // fetch notifications from local storage
    let notifications = localStorage.getItem('notifications');
    if (notifications) {
      this.notificationItems = JSON.parse(notifications);
      // limit number of notifications to 100
      if (this.notificationItems.length > 100) {
        this.notificationItems = this.notificationItems.slice(0, 100);
      }
      // mark all notifications as read
      this.notificationItems.forEach(notificationItem => {
        notificationItem.read = true;
      });
      this.updateNotificationItems(this.notificationItems);
    }

    this.startPolling();
    this.startSSE();
    //this.subscribeToPushNotifications();
  }

  /**
   * Subscribes to push notifications.
   */
  // subscribeToPushNotifications() {
  //   if (this.userDetails?.token) {
  //     this.pushService.subscribeToNotifications(this.userDetails?.token);
  //   } else {
  //     this.pushService.subscribeToNotifications('');
  //   }
  // }

  /**
   * Starts the Server-Sent Events (SSE) connection.
   * Subscribes to the SSE event source observable and logs the received data.
   */
  private startSSE(): void {
    this.sseService.startEventSource(environment.URL_SSE);
  }

  getFetchedDataIncidents(): Incident[] {
    return this.fetchedDataIncidents;
  }

  getIncidents(): Observable<Incident[]> {
    return this.incidentsSubject.asObservable();
  }
  getIncidentsFiltered(): Observable<Incident[]> {
    return this.incidentsFilteredSubject.asObservable();
  }
  getIncidentsFilteredPaged(): Observable<Incident[]> {
    return this.incidentsFilteredPagedSubject.asObservable();
  }
  getIncidentsChangeDetected(): Observable<boolean> {
    return this.incidentsChangeDetectedSubject.asObservable();
  }
  getIncident(): Observable<Incident | null> {
    return this.incidentSubject.asObservable();
  }
  getTasks(): Observable<Task[]> {
    return this.tasksSubject.asObservable();
  }
  getIncidentTasks(): Observable<Task[]> {
    return this.incidentTasksSubject.asObservable();
  }
  getTask(): Observable<Task | null> {
    return this.taskSubject.asObservable();
  }
  getQrCodeVerified(): Observable<QrCodeVerificationStatus> {
    return this.qrCodeVerifiedSubject.asObservable();
  }
  getQrCodeObjectName(): Observable<string> {
    return this.qrCodeObjectName.asObservable();
  }
  getQrCodeObjectNameScanned(): Observable<string> {
    return this.qrCodeObjectNameScanned.asObservable();
  }
  getMetricsTemp(): Observable<any> {
    return this.metricsTempSubject.asObservable();
  }
  getMetricsRvo(): Observable<any> {
    return this.metricsRvoSubject.asObservable();
  }
  getMetricsGraph(): Observable<any> {
    return this.metricsGraph.asObservable();
  }
  getMetricsGraphRvo(): Observable<any> {
    return this.metricsGraphRvo.asObservable();
  }
  getIncidentsPagesTotal(): Observable<number> {
    return this.incidentsPagesTotalSubject.asObservable();
  }
  getIncidentsPagesActive(): Observable<number> {
    return this.incidentsPagesActiveSubject.asObservable();
  }
  getPasportEntitiesPagesTotal(): Observable<number> {
    return this.pasportEntitiesPagesTotalSubject.asObservable();
  }
  getPasportEntitiesPagesActive(): Observable<number> {
    return this.pasportEntitiesPagesActiveSubject.asObservable();
  }
  getAgents(): Observable<Agent[]> {
    return this.agentsSubject.asObservable();
  }
  getPoolingMetricsCounter(): Observable<number> {
    return this.poolingMetricsCounter.asObservable();
  }
  getPasportItems(): Observable<PasportItem[]> {
    return this.pasportItemsSubject.asObservable();
  }
  getPasportEntity(): Observable<PasportItem | null> {
    return this.pasportEntitySubject.asObservable();
  }
  getPasportEntities(): Observable<PasportEntity[]> {
    return this.pasportEntitiesSubject.asObservable();
  }
  getPasportEntitiesFiltered(): Observable<PasportEntity[]> {
    return this.pasportEntitiesFilteredSubject.asObservable();
  }
  getPasportEntitiesFilteredPaged(): Observable<PasportEntity[]> {
    return this.pasportEntitiesFilteredPagedSubject.asObservable();
  }
  // getControlDeviceDetails(): Observable<ControlDeviceDetails | null> {
  //   return this.controlDeviceDetailsSubject.asObservable();
  // }
  getControlDeviceDetails(uuid: string): Observable<ControlDeviceDetails | null> {
    return this.controlDeviceDetailsMapSubject.asObservable().pipe(
      map(deviceDetailsMap => deviceDetailsMap.get(uuid) || null)
    );
  }
  getNotificationItems(): Observable<NotificationItem[]> {
    return this.notificationItemsSubject.asObservable();
  }

  updateIncidents(updatedItems: Incident[]): void {
    this.incidentsSubject.next(updatedItems);
  }
  updateIncidentsFiltered(updatedItems: Incident[]): void {
    this.incidentsFiltered = updatedItems;
    this.incidentsFilteredSubject.next(updatedItems);
  }
  updateIncidentsFilteredPaged(updatedItems: Incident[]): void {
    this.incidentsFilteredPaged = updatedItems;
    this.incidentsFilteredPagedSubject.next(updatedItems);
  }
  updateIncidentsChangeDetected(updatedItem: boolean): void {
    if (updatedItem === false) {
      this.notificationItems.forEach(notificationItem => {
        if (notificationItem.read === false) {
          notificationItem.read = true;
        }
      });
      this.updateNotificationItems(this.notificationItems);
    }
    this.incidentsChangeDetected = updatedItem;
    this.incidentsChangeDetectedSubject.next(updatedItem);
  }
  updateIncident(updatedItem: Incident | null): void {
    this.incidentSubject.next(updatedItem);
  }
  updateTasks(updatedItems: Task[]): void {
    this.tasksSubject.next(updatedItems);
  }
  updateIncidentTasks(updatedItems: Task[]): void {
    this.incidentTasksSubject.next(updatedItems);
  }
  updateTask(updatedItem: Task | null): void {
    this.taskSubject.next(updatedItem);
  }
  updateQrCodeVerified(updatedItem: QrCodeVerificationStatus): void {
    this.qrCodeVerifiedSubject.next(updatedItem);
  }
  updateQrCodeObjectName(updatedItem: string): void {
    this.qrCodeObjectName.next(updatedItem);
  }
  updateQrCodeObjectNameScanned(updatedItem: string): void {
    this.qrCodeObjectNameScanned.next(updatedItem);
  }
  updateMetricsTemp(updatedItem: any): void {
    this.metricsTempSubject.next(updatedItem);
  }
  updateMetricsRvo(updatedItem: any): void {
    this.metricsRvoSubject.next(updatedItem);
  }
  updateMetricsGraph(updatedItem: any): void {
    this.metricsGraph.next(updatedItem);
  }
  updateMetricsGraphRvo(updatedItem: any): void {
    this.metricsGraphRvo.next(updatedItem);
  }
  updateIncidentsPagesTotal(updatedItem: number): void {
    this.incidentsPagesTotalSubject.next(updatedItem);
  }
  updateIncidentsPagesActive(updatedItem: number): void {
    this.incidentsPagesActiveSubject.next(updatedItem);
  }
  updatePasportEntitiesPagesTotal(updatedItem: number): void {
    this.pasportEntitiesPagesTotalSubject.next(updatedItem);
  }
  updatePasportEntitiesPagesActive(updatedItem: number): void {
    this.pasportEntitiesPagesActiveSubject.next(updatedItem);
  }
  updateAgents(updatedItems: Agent[]): void {
    this.agentsSubject.next(updatedItems);
  }
  updatePoolingMetricsCounter(updatedItem: number): void {
    this.poolingMetricsCounter.next(updatedItem);
  }
  updatePasportItems(updatedItems: PasportItem[]): void {
    this.pasportItemsSubject.next(updatedItems);
  }
  updatePasportEntity(updatedItem: PasportItem | null): void {
    this.pasportEntity = updatedItem;
    this.pasportEntitySubject.next(updatedItem);
  }
  updatePasportEntities(updatedItems: PasportEntity[]): void {
    this.pasportEntities = updatedItems;
    this.pasportEntitiesSubject.next(updatedItems);
  }
  updatePasportEntitiesFiltered(updatedItems: PasportEntity[]): void {
    this.pasportEntitiesFiltered = updatedItems;
    this.pasportEntitiesFilteredSubject.next(updatedItems);
  }
  updatePasportEntitiesFilteredPaged(updatedItems: PasportEntity[]): void {
    this.pasportEntitiesFilteredPaged = updatedItems;
    this.pasportEntitiesFilteredPagedSubject.next(updatedItems);
  }
  // updateControlDeviceDetails(updatedItem: ControlDeviceDetails | null): void {
  //   this.controlDeviceDetailsSubject.next(updatedItem);
  // }
  updateControlDeviceDetails(uuid: string, updatedItem: ControlDeviceDetails): void {
    const currentMap = this.controlDeviceDetailsMapSubject.value;
    currentMap.set(uuid, updatedItem);
    this.controlDeviceDetailsMapSubject.next(currentMap);
  }
  updateNotificationItems(updatedItems: NotificationItem[]): void {
    this.notificationItemsSubject.next(updatedItems);
    // save notifications to local storage
    localStorage.setItem('notifications', JSON.stringify(updatedItems));
  }

  removeControlDeviceDetails(uuid: string): void {
    const currentMap = this.controlDeviceDetailsMapSubject.value;
    if (currentMap.has(uuid)) {
      currentMap.delete(uuid);
      this.controlDeviceDetailsMapSubject.next(currentMap);
    }
  }

  async fetchApiPostExcel(incidentList: number[]): Promise<Blob> {
    await this.authService.refreshToken();
    const url = `${environment.URL_EXPORT}incidents`;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(incidentList),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.userDetails?.token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.blob();
  }

  /**
   * Fetches incidents from the API based on the current filter status.
   *
   * @returns {Promise<any[]>} A promise that resolves to an array of incident data.
   *
   * @throws {Error} If there's a network error or the response is not ok.
   *
   * @description
   * This method performs the following steps:
   * 1. Refreshes the authentication token.
   * 2. Aborts any ongoing fetch request.
   * 3. Creates a new AbortController for the new fetch request.
   * 4. Constructs the filter status based on the current state.
   * 5. Sends a GET request to the incidents API with the constructed filter.
   * 6. Processes the received data:
   *    - Remaps incident names.
   *    - Calculates importance based on tasks.
   *    - Extracts tasks from incidents.
   *    - Retrieves unique sources and device types occurrences for each incident.
   * 7. Updates various data properties and subjects.
   * 8. Resets the change detection flag.
   *
   * If an error occurs, it logs the error and returns an empty array.
   */
  async fetchApiGetIncidents(): Promise<any[]> {
    try {
      await this.authService.refreshToken();
      // Abort any ongoing fetch request
      if (this.controllerApiGetIncidents) {
        this.controllerApiGetIncidents.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetIncidents = new AbortController();

      // TODO: modify hardcoded values
      let filterStatus:string[] = [];
      if (this.states.statusNew) {filterStatus.push('NEW');}
      if (this.states.statusInprogress) {filterStatus.push('INPROGRESS');}
      if (this.states.statusPending) {filterStatus.push('PENDING');}
      if (this.states.statusSolved) {filterStatus.push('SOLVED');}
      if (this.states.statusUnresolved) {filterStatus.push('UNRESOLVED');}
      if (this.states.statusTerminated) {filterStatus.push('TERMINATED');}

      // FIXME: hardcoded paging
      // const url = 'https://liafe.cloudmv.online/liabe/api/v1/incidents';
      const url = environment.URL_INCIDENTS_FILTER+`?filter=${filterStatus.join(',')}&page=0&size=1000000`;

      this.fetchApiGetIncidentsProcessing.next(true);
      const response = await fetch(url, {
        signal: this.controllerApiGetIncidents.signal,
        headers: {
          Authorization: `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Handle 204 No Content response
      if (response.status === 204) {
        this.clearIncidentsData();
        this.fetchApiGetIncidentsProcessing.next(false);
        return [];
      }

      const data = await response.json();

      this.fetchedDataIncidents = data.content;
      // this.fetchedDataIncidents = data;
      // Remap the .name attribute of each incident
      this.fetchedDataIncidents = this.fetchedDataIncidents.map(incident => {
        // Check if any task has an importance of 1
        const hasImportantTask = incident.tasks.some(task => task.importance === 1);

        return {
          ...incident,
          name: nameMapping[incident.name] || incident.name,
          importance: hasImportantTask ? 1 : 0
        };
      });

      let tasks: Task[] = [];
      this.fetchedDataIncidents.forEach(incident => {
        tasks = tasks.concat(incident.tasks);
      });

      // retrieve unique sources and device types occurances of each incident
      for (const incident of this.fetchedDataIncidents) {
        const deviceTypeOccurrences = new Map<string, number>();
        const uniqueDeviceTypeNames = new Set();
        const uniqueAgents = new Set();
        uniqueAgents.add(incident.agent || 'Nepriradený agent');
        for (const task of incident.tasks) {
          const deviceTypeName = task.deviceTypeName || 'Zariadenie';
          const agent = task.agent || 'Nepriradený agent';
          deviceTypeOccurrences.set(deviceTypeName, (deviceTypeOccurrences.get(deviceTypeName) || 0) + 1);
          uniqueDeviceTypeNames.add(deviceTypeName);
          uniqueAgents.add(agent);
        }
        const deviceTypeOccurrencesArray = Array.from(deviceTypeOccurrences.entries()).map(([deviceTypeName, occurrences]) => ({ deviceTypeName, occurrences }));
        incident.deviceTypeNameOccurrences = deviceTypeOccurrencesArray;
        incident.uniqueDeviceTypeNames = Array.from(uniqueDeviceTypeNames);
        incident.uniqueAgents = Array.from(uniqueAgents);

        const uniqueSources = new Set();
        for (const task of incident.tasks) {
          uniqueSources.add(task.source || 'Neznámy zdroj');
        }
        const uniqueSourcesArray = Array.from(uniqueSources);
        incident.uniqueSources = uniqueSourcesArray;
      }

      this.updateTasks(tasks);
      if (this.fetchedDataIncidents.length > 0) {
        this.updateIncidentsPagesActive(1);
      }
      this.incidentsFiltered = this.filterIncidents(this.fetchedDataIncidents, this.orderState);
      this.updateIncidentsFiltered(this.incidentsFiltered);
      this.incidentsFilteredPaged = this.pageIncidents(this.incidentsFiltered, this.orderState);
      this.updateIncidentsFilteredPaged(this.incidentsFilteredPaged);
      // reset changes detected flag
      this.updateIncidentsChangeDetected(false);
      this.fetchApiGetIncidentsProcessing.next(false);
      return this.fetchedDataIncidents;
    } catch (error) {
      this.fetchApiGetIncidentsProcessing.next(false);
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      this.clearIncidentsData();
      return []; // Return an empty array in case of error
    }
  }

  // Clear the incidents data - useful for errors or 204 No Content response
  private clearIncidentsData(): void {
    this.fetchedDataIncidents = [];
    this.updateTasks([]);
    this.updateIncidentsPagesActive(0);
    this.updateIncidentsFiltered([]);
    this.updateIncidentsFilteredPaged([]);
    this.updateIncidentsChangeDetected(false);
  }

  async fetchApiGetIncidentsLastUpdate(): Promise<any[]> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(
        environment.URL_INCIDENTS_LASTUPDATE,
        {
          headers: {
            Authorization: `Bearer ${this.userDetails?.token}`
          }
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // detect changes between two datasets
      // compare length only
      if (this.fetchedDataIncidents.length !== data.length) {
        this.updateIncidentsChangeDetected(true);
        return [];
      }
      // compare each incident - check id and lastUpdateDate
      for (let i = 0; i < this.fetchedDataIncidents.length; i++) {
        if (
          (this.fetchedDataIncidents[i].id !== data[i].id) ||
          (this.fetchedDataIncidents[i].lastUpdateDate !== data[i].lastUpdateDate)
        ) {
          this.updateIncidentsChangeDetected(true);
          return [];
        }
      }
      return [];
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return []; // Return an empty array in case of error
    }
  }

  async fetchApigetIncident(incidentId: number, autoUpdate: boolean = true, abortPreviousRequest: boolean = true): Promise<Incident | null> {
    try {
      await this.authService.refreshToken();
      // Abort any ongoing fetch request
      if ((abortPreviousRequest) && (this.controllerApiGetIncident)) {
        this.controllerApiGetIncident.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetIncident = new AbortController();

      const response = await fetch(`${environment.URL_INCIDENTS}/${incidentId}`, {
        signal: this.controllerApiGetIncident.signal,
        headers: {
          Authorization: `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let incident: Incident = await response.json();
      incident.name = nameMapping[incident.name] || incident.name;
      if (autoUpdate) {
        this.updateIncident(incident);
      }

      // Make update for table data
      const uniqueAgents = new Set();
      // find incident by ID in fetchedDataIncidents and update it
      this.fetchedDataIncidents.forEach((item, index) => {
        if (item.id === incident.id) {
          this.fetchedDataIncidents[index].state = incident.state;
          uniqueAgents.add(incident.agent || 'Nepriradený agent');
          for (const task of incident.tasks) {
            const agent = task.agent || 'Nepriradený agent';
            uniqueAgents.add(agent);
          }
          this.fetchedDataIncidents[index].uniqueAgents = Array.from(uniqueAgents);
        }
      });
      // find incident by ID in incidentsFiltered and update it
      this.incidentsFiltered.forEach((item, index) => {
        if (item.id === incident.id) {
          this.incidentsFiltered[index].state = incident.state;
          this.incidentsFiltered[index].uniqueAgents = Array.from(uniqueAgents);
        }
      });
      this.updateIncidentsFiltered(this.incidentsFiltered);
      // find incident by ID in incidentsFilteredPaged and update it
      this.incidentsFilteredPaged.forEach((item, index) => {
        if (item.id === incident.id) {
          this.incidentsFilteredPaged[index].state = incident.state;
          this.incidentsFilteredPaged[index].uniqueAgents = Array.from(uniqueAgents);
        }
      });

      this.updateIncidentsFilteredPaged(this.incidentsFilteredPaged);

      return incident;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return an empty array in case of error
    }
  }

  async fetchApiPatchIncidentAgent(incidentId: number, agentId: number): Promise<boolean> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_INCIDENTS}/${incidentId}?agentId=${agentId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          agentId: agentId
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      this.fetchApigetIncident(incidentId);
      return true;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false; // Return an empty array in case of error
    }
  }

  // TODO: wait for the final endpoint and modify accordingly
  async fetchApiPatchIncidentStateAndComment(incidentId: number, state: string, comment: string): Promise<boolean> {

    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_INCIDENTS}/${incidentId}/state`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          state: state,
          comment: comment
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      this.fetchApigetIncident(incidentId);
      return true;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false; // Return false in case of error
    }
  }


  /**
   * Fetches the API to update the importance of a task.
   * @param taskId - The ID of the task to update.
   * @param importance - The new importance value for the task.
   * @returns A promise that resolves to a boolean indicating whether the API call was successful.
   */
  async fetchApiPatchTaskImportance(taskId: number, importance: number): Promise<boolean> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_TASKS}/${taskId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          importance: importance
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      //this.fetchApigetIncident(incidentId);
      this.fetchApiGetTask(taskId);
      return true;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false; // Return false in case of error
    }
  }

  async fetchApiGetIncidentTasks(incidentId: number): Promise<Task[]> {
    try {
      await this.authService.refreshToken();
      this.updateIncidentTasks([]);
      // Abort any ongoing fetch request
      if (this.controllerApiGetIncidentTasks) {
        this.controllerApiGetIncidentTasks.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetIncidentTasks = new AbortController();

      const response = await fetch(`${environment.URL_INCIDENTS}/${incidentId}/tasks`, {
        signal: this.controllerApiGetIncidentTasks.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let tasks: Task[] = await response.json();
      this.updateIncidentTasks(tasks);
      return tasks;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return []; // Return an empty array in case of error
    }
  }

  async fetchApiGetTask(taskId: number): Promise<Task | null> {
    try {
      await this.authService.refreshToken();
      this.updateTask(null);
      // Abort any ongoing fetch request
      if (this.controllerApiGetTask) {
        this.controllerApiGetTask.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetTask = new AbortController();

      const response = await fetch(`${environment.URL_TASKS}/${taskId}`, {
        signal: this.controllerApiGetTask.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let task: Task = await response.json();
      this.updateTask(task);

      return task;
    } catch (error) {
    if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return an empty array in case of error
    }
  }

  // TODO: create fetch for live and graph data
  // TODO: rename temp
  async fetchApiGetMetricsTemp(task: Task): Promise<any> {
    try {
      await this.authService.refreshToken();
      // Reset the counter
      let counter = 60;
      this.updatePoolingMetricsCounter(counter);
      // Store the task for later use
      this.lastTaskForMetrics = task;
      let url = '';

      switch (task.deviceTypeName?.toLowerCase()) {
        case 'lightpoint':
          url = environment.URL_METRICS_TEMP.replace('{id}', task.objectId.toString());
          break;
        case 'rvo':
          url = environment.URL_METRICS_RVO.replace('{id}', task.objectId.toString());
          break;
        default:
          break;
      }

      // Abort any ongoing fetch request
      if (this.controllerApiGetMetricsTemp) {
        this.controllerApiGetMetricsTemp.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetMetricsTemp = new AbortController();

      const response = await fetch(url, {
        signal: this.controllerApiGetMetricsTemp.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let json = await response.json();

      switch (task.deviceTypeName?.toLowerCase()) {
        case 'lightpoint': {
            let metrics: MetricsTemp = json.data.data;
            metrics.dataTs = json.dataTs;
            this.updateMetricsTemp(metrics);
            this.startPollingMetrics();
            return metrics;
          }
        case 'rvo': {
            let metrics: MetricsRvo = json.data.data;
            metrics.dataTs = json.dataTs;
            this.updateMetricsRvo(metrics);
            this.startPollingMetrics();
            return metrics;
          }
        default:
          this.startPollingMetrics();
          return [];
      }
    } catch (error) {
      this.updateMetricsTemp(null);
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return an empty array in case of error
    }
  }

  // create fetch for live and graph data
  async fetchApiGetMetricsGraph(task: Task): Promise<any> {
    try {
      await this.authService.refreshToken();
      let url = '';
      switch (task.deviceTypeName?.toLowerCase()) {
        case 'lightpoint':
          url = environment.URL_METRICS_GRAPH_TEMP.replace('{id}', task.objectId.toString());
          break;
        case 'rvo':
          url = environment.URL_METRICS_GRAPH_RVO.replace('{id}', task.objectId.toString());
          break;
        default:
          break;
      }
      const from = new Date().getTime() - 86400000 * 7;
      const to = new Date().getTime();
      url = url.replace('{from}', from.toString());
      url = url.replace('{to}', to.toString());

      // Abort any ongoing fetch request
      if (this.controllerApiGetMetricsGraph) {
        this.controllerApiGetMetricsGraph.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetMetricsGraph = new AbortController();

      const response = await fetch(url, {
        signal: this.controllerApiGetMetricsGraph.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let json = await response.json();

      switch (task.deviceTypeName?.toLowerCase()) {
        case 'lightpoint': {
            let metrics: MetricsGraphItem[] = json;
            this.updateMetricsGraph(metrics);
            return metrics;
          }
        case 'rvo': {
            let metrics: MetricsGraphRvoItem[] = json;
            this.updateMetricsGraphRvo(metrics);
            return metrics;
          }
        default:
          this.updateMetricsGraphRvo([]);
          return [];
      }
    } catch (error) {
    if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      this.updateMetricsGraphRvo([]);
      return [];
    }
  }

  // FIXME: api endpoint in wrong format change task -> tasks
  async fetchApiPatchTask(task: Task, state: string, comment: string): Promise<Task | null> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_TASKS}/${task.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          state: state,
          comment: comment
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let updatedTask: Task = await response.json();
      this.updateTask(updatedTask);
      this.fetchApiGetIncidentTasks(task.incidentId);
      this.fetchApiGetTask(task.id);
      return updatedTask;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null; // Return an empty array in case of error
    }
  }

  async fetchApiPatchTaskAgent(taskId: number, agentEmail: string): Promise<boolean> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_TASKS}/${taskId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          agent: agentEmail
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      this.fetchApiGetTask(taskId);
      return true;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false; // Return an empty array in case of error
    }
  }

  async fetchApiGetAgents(): Promise<Agent[]> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(environment.URL_AGENTS, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const agents: Agent[] = await response.json();
      this.updateAgents(agents);
      return agents;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return []; // Return an empty array in case of error
    }
  }

  async fetchApiGetPasportItems(): Promise<PasportItem[]> {
    try {
      await this.authService.refreshToken();
      // Abort any ongoing fetch request
      if (this.controllerApiGetPasportItems) {
        this.controllerApiGetPasportItems.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetPasportItems = new AbortController();

      const response = await fetch(environment.URL_PASPORT_HIERARCHYPROJECTION, {
        signal: this.controllerApiGetPasportItems.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      this.pasportItems = this.flattenData(json);

      this.updatePasportItems(this.pasportItems);
      return this.pasportItems;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return []; // Return an empty array in case of error
    }
  }

  async fetchApiGetPasportEntity(uuid: string): Promise<PasportItem | null> {
    try {
      this.updatePasportEntity(null);
      await this.authService.refreshToken();
      const url = environment.URL_PASPORT_ENTITY.replace('{id}', uuid);
      // Abort any ongoing fetch request
      if (this.controllerApiGetPasportEntity) {
        this.controllerApiGetPasportEntity.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetPasportEntity = new AbortController();

      const response = await fetch(url, {
        signal: this.controllerApiGetPasportEntity.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      const pasportItems: PasportItem[] = this.flattenData(json);
      for (const item of pasportItems) {
        if (item.uuid === uuid) {
          item.parents = json.children;
          this.updatePasportEntity(item);
        }
      }
      return this.pasportEntity;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return an empty array in case of error
    }
  }

  flattenData(item: PasportItemSource, result: Map<string, PasportItem> = new Map(), parent?: PasportItemSource): PasportItem[] {
    // Check if the item is already in the result map by its uuid
    if (!result.has(item.uuid)) {
      result.set(item.uuid, {
        uuid: item.uuid,
        type: item.type,
        name: item.name,
        address: '',
        data: item.data,
        parentRvoUuid: item.parentRvoUuid ? item.parentRvoUuid : undefined,
        parentData: parent?.data,
        coordinates: {
          latitude: item.lat || parent?.lat || 0 ,
          longitude: item.lng || parent?.lng || 0
        },
        children: undefined,
        parents: undefined,
        listOrderNumber: item.listOrderNumber || 0
      }); // Add item without children to the map
    }

    // If the item has children, recursively flatten them
    if (item.children && item.children.length > 0) {
      item.children.forEach(child => this.flattenData(child, result, item));
    }

    // Convert the map to an array of its values
    return Array.from(result.values());
  }

  async fetchApiGetPasportEntityByName(name: string): Promise<PasportItem | null> {
    try {
      await this.authService.refreshToken();
      const urls = [
        environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'lightpoint'),
        environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'lightplace'),
        environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'rvo'),
      ];
      const responses = await Promise.all(urls.map(url => fetch(url, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      })));
      const jsons = await Promise.all(responses.map(response => {
        if (response.ok && response.status !== 204) {
          return response.json();
        } else {
          return null;
        }
      }));

      console.log('fetchApiGetPasportEntityByName jsons', jsons);

      const pasportItems: PasportItem[] = this.flattenData(jsons[0]);
      for (const item of pasportItems) {
        if (item.uuid === name) {
          this.updatePasportEntity(item);
        }
      }

      return null;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return an empty array in case of error
    }
  }

  async fetchApiGetPasportEntityByParams(type: string, name: string, address: string): Promise<PasportItem[] | []> {
    try {
      await this.authService.refreshToken();
      const urls: string[] = [];
      if (!type){
        urls.push(
          environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'lightpoint'),
          environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'lightplace'),
          environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'rvo')
        );
      }
      if (type === 'lightpoint') {
        urls.push(environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'lightpoint'));
      }
      if (type === 'lightplace') {
        urls.push(environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'lightplace'));
      }
      if (type === 'rvo') {
        urls.push(environment.URL_PASPORT_ENTITY_BY_NAME.replace('{name}', name).replace('{type}', 'rvo'));
      }

      const responses = await Promise.all(urls.map(url => fetch(url, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      })));
      const jsons = await Promise.all(responses.map(response => {
        if (response.ok && response.status !== 204) {
          return response.json();
        } else {
          return null;
        }
      }));

      const pasportItems: PasportItem[] = [];
      for (const json of jsons) {
        if (json) {
          pasportItems.push({
            uuid: json.uuid,
            type: json.type,
            name: json.name,
            address: json.locationName,
            data: undefined,
            parentRvoUuid: undefined,
            parentData: undefined,
            coordinates: {
              latitude: json.lat || 0,
              longitude: json.lng || 0
            },
            children: undefined,
            parents: undefined,
            listOrderNumber: 0
          });
        }
      }

      return pasportItems;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return []; // Return an empty array in case of error
    }
  }

  async fetchApiGetPasportEntityHierarchyParents(uuid: string): Promise<PasportItem | null> {
    try {
      await this.authService.refreshToken();
      const url = environment.URL_PASPORT_ENTITY.replace('{id}', uuid);
      // Abort any ongoing fetch request
      if (this.controllerApiGetPasportEntityHierarchyParents) {
        this.controllerApiGetPasportEntityHierarchyParents.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetPasportEntityHierarchyParents = new AbortController();

      const response = await fetch(url, {
        signal: this.controllerApiGetPasportEntityHierarchyParents.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      return json;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return an empty array in case of error
    }
  }

  async fetchApiGetPasportEntityHierarchyChildren(uuid: string): Promise<PasportItem | null> {
    try {
      await this.authService.refreshToken();
      const url = environment.URL_PASPORT_HIERARCHY.replace('{id}', uuid);
      // Abort any ongoing fetch request
      if (this.controllerApiGetPasportEntityHierarchyChildren) {
        this.controllerApiGetPasportEntityHierarchyChildren.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetPasportEntityHierarchyChildren = new AbortController();

      const response = await fetch(url, {
        signal: this.controllerApiGetPasportEntityHierarchyChildren.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      return json;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        //console.log('Fetch aborted');
      } else {
        //console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return an empty array in case of error
    }
  }

  async fetchApiPostReport(report: IncidentReport): Promise<boolean> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(environment.URL_REPORT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify(report)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return true;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false; // Return an empty array in case of error
    }
  }

  async fetchApiGetControl(uuid: string, type: string): Promise<ControlDeviceDetails | null> {
    try {
      await this.authService.refreshToken();
      const url = environment.URL_CONTROL.replace('{id}', uuid)+`${type}`;
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json:ControlDeviceDetails = await response.json();
      this.updateControlDeviceDetails(uuid,json);
      return json;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return null; // Return null in case of error
    }
  }

  // FIXME: API-Key alternative
  // Get control mode using patch type. usually for RVO device type
  async fetchApiPatchControlMode(uuid: string): Promise<ControlDeviceDetails | null> {
    try {
      await this.authService.refreshToken();
      //this.updateControlDeviceDetails(uuid,null);
      this.removeControlDeviceDetails(uuid);

      const url = environment.URL_CONTROL.replace('{id}', uuid)+"mode";
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          //'X-API-Key': `${this.userDetails?.token}`,
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({ })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json:ControlDeviceDetails = await response.json();
      this.updateControlDeviceDetails(uuid,json);
      return json;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null; // Return false in case of error
    }
  }

  async fetchApiPostControlMode(uuid: string, mode: string): Promise<ControlDeviceDetails | null> {
    try {
      const url = environment.URL_CONTROL.replace('{id}', uuid)+"mode";
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'X-API-Key': `${this.userDetails?.token}`
        },
        body: JSON.stringify({
          mode: mode
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // TODO: useless?
      // const json:ControlDeviceDetails = await response.json();
      // TODO: should we call get fetch for this type. result type is not consistent across different types post requests
      let result = await this.fetchApiPatchControlMode(uuid);
      return result;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null; // Return false in case of error
    }
  }

  // FIXME: API-Key alternative
  // Get control intensity using patch type. usually for lightpoint device type
  async fetchApiPatchControlIntensity(uuid: string): Promise<ControlDeviceDetails | null> {
    try {
      await this.authService.refreshToken();
      const url = environment.URL_CONTROL.replace('{id}', uuid)+"intensity";
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          //'X-API-Key': `${this.userDetails?.token}`,
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({ })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json:ControlDeviceDetails = await response.json();
      this.updateControlDeviceDetails(uuid,json);
      return json;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null; // Return false in case of error
    }
  }

  // FIXME: API-Key alternative
  async fetchApiPostControlIntensity(uuid: string, intensity: number): Promise<ControlDeviceDetails | null> {
    try {
      await this.authService.refreshToken();
      const url = environment.URL_CONTROL.replace('{id}', uuid)+"intensity";
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'X-API-Key': `${this.userDetails?.token}`,
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          intensity: intensity
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json:ControlDeviceDetails = await response.json();
      this.updateControlDeviceDetails(uuid,json);
      return json;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null; // Return false in case of error
    }
  }

  /**
   * Fetches the list of PasportItemFile for a given UUID from the API.
   * @param uuid - The UUID of the entity.
   * @returns A Promise that resolves to an array of PasportItemFile objects, or an empty array if there was an error.
   */
  async fetchApiGetPasportEntityFileList(uuid: string): Promise<PasportItemFile[] | []> {
    try {
      await this.authService.refreshToken();
      const url = `${environment.URL_PASPORT_FILES.replace('{id}', uuid)}/list`;
      // Abort any ongoing fetch request
      if (this.controllerApiGetPasportEntityFileList) {
        this.controllerApiGetPasportEntityFileList.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetPasportEntityFileList = new AbortController();

      const response = await fetch(url, {
        signal: this.controllerApiGetPasportEntityFileList.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json:PasportItemFile[] = await response.json();
      return json;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        //console.log('Fetch aborted');
      } else {
        //console.error('There has been a problem with your fetch operation:', error);
      }
      return []; // Return null in case of error
    }
  }

  /**
   * Fetches the API to post a passport entity file.
   * @param {string} uuid - The UUID of the entity.
   * @param {string} tags - The tags associated with the file.
   * @param {File} file - The file to be uploaded.
   * @returns {Promise<boolean>} - A promise that resolves to true if the API call is successful, false otherwise.
   */
  async fetchApiPostPasportEntityFile(uuid: string, tags: string, file: File): Promise<boolean> {
    try {
      await this.authService.refreshToken();
      const url = environment.URL_PASPORT_FILES.replace('{id}', uuid);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uuid', uuid);
      formData.append('tags', tags);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: formData
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json:PasportItemFile[] = await response.json();
      return true;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false; // Return false in case of error
    }
  }

  // TODO: modify for new endpoint style
  async fetchApiDeletePasportEntityFile(entityUuid: string, id: string): Promise<boolean> {
    try {
      await this.authService.refreshToken();
      const url = `${environment.URL_PASPORT_FILES.replace('{id}', entityUuid)}/${id}`;

      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // const json:PasportItemFile[] = await response.json();
      // console.log(json);
      return true;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false; // Return false in case of error
    }
  }

  // TODO: generate doc here
  async fetchApiGetPasportEntities(): Promise<PasportEntity[]> {
    try {
      await this.authService.refreshToken();
      this.pasportTemplates = await this.fetchApiGetPasportTemplates();
      // fetch allowed relations only if templates are fetched
      if (this.pasportTemplates.length <= 0) {
        this.allowedRelations = await this.fetchApiGetPasportRelationsAllowed();
      }
      // Abort any ongoing fetch request
      if (this.controllerApiGetPasportEntities) {
        this.controllerApiGetPasportEntities.abort();
      }
      // Create a new AbortController for the new fetch request
      this.controllerApiGetPasportEntities = new AbortController();

      this.fetchApiGetPasportEntitiesProcessing.next(true);
      const response = await fetch(environment.URL_ENTITIES, {
        signal: this.controllerApiGetPasportEntities.signal,
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      this.pasportEntities = this.flattenDataPasportEntities(json);
      this.updatePasportEntities(this.pasportEntities);

      if (this.pasportEntities.length > 0) {
        this.updatePasportEntitiesPagesActive(1);
      }
      this.pasportEntitiesFiltered = this.filterPasportEntities(this.pasportEntities);
      this.updatePasportEntitiesFiltered(this.pasportEntitiesFiltered);
      this.pasportEntitiesFilteredPaged = this.pagePasportEntities(this.pasportEntitiesFiltered);
      this.updatePasportEntitiesFilteredPaged(this.pasportEntitiesFilteredPaged);
      this.fetchApiGetPasportEntitiesProcessing.next(false);

      return this.pasportEntities;
    } catch (error) {
      this.fetchApiGetPasportEntitiesProcessing.next(false);
      if ((error as Error).name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('There has been a problem with your fetch operation:', error);
      }
      return []; // Return an empty array in case of error
    }
  }

  flattenDataPasportEntities(item: PasportEntitySource, result: Map<string, PasportEntity> = new Map(), parent?: PasportEntitySource, rvoUuid?: string): PasportEntity[] {
    // If the item's type is "rvo", update the rvoUuid
    if (item.type === 'rvo') {
      rvoUuid = item.uuid;
    }

    // Check if the item is already in the result map by its uuid
    if (!result.has(item.uuid)) {
      result.set(item.uuid, {
        uuid: item.uuid,
        type: item.type,
        name: item.name,
        data: item.data,
        parentRvoUuid: rvoUuid, // Store the rvoUuid in the item
        parentData: parent?.data,
        coordinates: {
          latitude: item.lat || parent?.lat || 0,
          longitude: item.lng || parent?.lng || 0
        },
        children: undefined,
        listOrderNumber: 0
      });
    }

    // If the item has children, recursively flatten them
    if (item.children && item.children.length > 0) {
      item.children.forEach(child => this.flattenDataPasportEntities(child, result, item, rvoUuid));
    }

    // Convert the map to an array of its values
    return Array.from(result.values());
  }


  /**
   * Filters the given array of PasportEntity objects based on the selected states and search term.
   * @param entities - The array of PasportEntity objects to filter.
   * @param orderState - The MapFilterOrderState object representing the selected states.
   * @returns The filtered array of PasportEntity objects.
   */
  filterPasportEntities(entities: PasportEntity[]): PasportEntity[] {
    console.log('filterPasportEntities');
    const sortedData = entities
      .filter(pasportEntity => {
        if (
          (
            (this.states.location && pasportEntity.type == 'location') ||
            (this.states.rvo && pasportEntity.type == 'rvo') ||
            (this.states.rvoc && pasportEntity.type == 'rvoc') ||
            (this.states.phase && pasportEntity.type == 'phase') ||
            (this.states.output && pasportEntity.type == 'output') ||
            (this.states.lightline && pasportEntity.type == 'lightline') ||
            (this.states.lightplace && pasportEntity.type == 'lightplace') ||
            (this.states.lightpoint && pasportEntity.type == 'lightpoint') ||
            (this.states.socket && pasportEntity.type == 'socket') ||
            (this.states.trafficCounter && pasportEntity.type == 'trafficCounter') ||
            (this.states.smartButton && pasportEntity.type == 'smartButton') ||
            (this.states.surfaceTemperatureSensor && pasportEntity.type == 'surfaceTemperatureSensor') ||
            (this.states.speedometer && pasportEntity.type == 'speedometer') ||
            (this.states.motionSensor && pasportEntity.type == 'motionSensor') ||
            (this.states.floodSensor && pasportEntity.type == 'floodSensor') ||
            (this.states.sensorModule && pasportEntity.type == 'sensorModule') ||
            (this.states.agSensor && pasportEntity.type == 'agSensor') ||
            (this.states.shmu && pasportEntity.type == 'shmu') ||
            (this.states.uniza && pasportEntity.type == 'uniza')
          )
        ) {
        } else {
          // The entity does not match the selected states
          return false;
        }

        // check rvos filter
        // only check if there are some rvos selected. if empty, consider as all selected
        if (this.pasportFilterRvos.length > 0) {
          if (!pasportEntity.parentRvoUuid) {
            return false;
          }
          if (!this.pasportFilterRvos.includes(pasportEntity.parentRvoUuid)) {
            return false;
          }
        }

        // Convert the pasport enetity object to an array of its values
        if (this.fulltext && this.fulltext.length > 0) {
          // TODO: modify this to search only in specific fields
          // Search in pasport parameters (data object)

          if (this.states.searchFulltextInData || this.states.searchFulltextInName || this.states.searchFulltextInUuid) {
            let hasMatchInData = false;
            let hasMatchInName = false;
            let hasMatchInUuid = false;

            if (this.states.searchFulltextInData && pasportEntity.data) {
              // Convert object values to array and search in them
              hasMatchInData = Object.values(pasportEntity.data).some((value: any) =>
                value && value.toString().toLowerCase().includes(this.fulltext.toLowerCase())
              );
            }

            // Also search in name if needed
            if (this.states.searchFulltextInName && pasportEntity.name) {
              hasMatchInName = pasportEntity.name?.toLowerCase().includes(this.fulltext.toLowerCase());
            }

            // Also search in uuid if needed
            if (this.states.searchFulltextInUuid && pasportEntity.uuid) {
              hasMatchInUuid = pasportEntity.uuid.toLowerCase().includes(this.fulltext.toLowerCase());
            }

            return hasMatchInData || hasMatchInName || hasMatchInUuid;
          } else {
            const pasportEntityString = JSON.stringify(pasportEntity).toLowerCase();
            return pasportEntityString.includes(this.fulltext.toLowerCase());
          }
        } else {
          return true;
        }
      })
      .map((item, index) => {
        return { ...item, listOrderNumber: index + 1 };
      });

    // update total pages
    this.updatePasportEntitiesPagesTotal(Math.ceil(sortedData.length / this.settingsRecordsPerPage));
    // change active page if some records are filtered and active page is higher than total pages
    if ((this.pasportEntitiesPagesActiveSubject.getValue() > this.pasportEntitiesPagesTotalSubject.getValue()) && (this.pasportEntitiesPagesTotalSubject.getValue() > 0)) {
      // set active page to 1
      this.updatePasportEntitiesPagesActive(1);
    }
    // TODO: do we want to update entities here?
    this.updatePasportEntitiesFiltered(sortedData);
    return sortedData;
  }

  pagePasportEntities(entities: PasportEntity[]): PasportEntity[] {
    // apply paging
    let start = (this.pasportEntitiesPagesActiveSubject.getValue() - 1) * this.settingsRecordsPerPage;
    let end = start + this.settingsRecordsPerPage;
    // Get the incidents for the current page
    const pagedData = entities.slice(start, end);
    // TODO: do we want to update incidents here?
    this.updatePasportEntitiesFilteredPaged(pagedData);
    return pagedData;
  }

  // TODO: add paging to
  // TODO: add filtering to
  filterIncidents(incidents: Incident[], orderState: MapFilterOrderState): Incident[] {
    const sortedData = incidents
      .filter(incident => {
        if (
          // (
          //   (this.states.activeReports && ['new', 'inprogress', 'pending'].includes(incident.state)) ||
          //   (this.states.completedReports && ['solved', 'unresolved', 'terminated'].includes(incident.state))
          // )
          // &&
          (
            (this.states.statusNew && (incident.state === 'new') ) ||
            (this.states.statusInprogress && (incident.state === 'inprogress') ) ||
            (this.states.statusPending && (incident.state === 'pending') ) ||
            (this.states.statusSolved && (incident.state === 'solved') ) ||
            (this.states.statusUnresolved && (incident.state === 'unresolved') ) ||
            (this.states.statusTerminated && (incident.state === 'terminated') )
          )
          &&
          (
            (this.states.sourceDvo && incident.uniqueSources.includes('LIGHTNET')) ||
            (this.states.sourcePublic && incident.uniqueSources.includes('PUBLIC')) ||
            (this.states.sourceOperator && incident.uniqueSources.includes('OPERATOR'))
          )
          &&
          (
            (this.states.last24h && (new Date().getTime() - new Date(incident.evidenceDate).getTime()) <= 86400000) ||
            (!this.states.last24h)
          )
          &&
          (
            (this.states.highImportance && incident.importance >= 1) || (!this.states.highImportance)
          )
          &&
          (
            (this.states.myIncidentsAndTasks && incident.uniqueAgents.includes(this.userDetails?.email) ) ||
            (!this.states.myIncidentsAndTasks)
          )
          &&
          // TODO: modify
          (
            (this.states.powerSupplyFailure && incident.name === nameMapping['POWER_SUPPLY_FAILURE']) ||
            (this.states.lightingFailure && incident.name === nameMapping['LIGHTING_FAILURE']) ||
            (this.states.dimmingFailure && incident.name === nameMapping['DIMMING_FAILURE']) ||
            (this.states.communicationFailure && incident.name === nameMapping['COMMUNICATION_FAILURE']) ||
            (this.states.deviceFailure && incident.name === nameMapping['DEVICE_FAILURE']) ||
            (this.states.configurationFailure && incident.name === nameMapping['CONFIGURATION_FAILURE']) ||
            (this.states.operationalIssue && incident.name === nameMapping['OPERATIONAL_ISSUE']) ||
            (this.states.appearanceIssue && incident.name === nameMapping['APPEARENCE_ISSUE']) ||
            (this.states.otherIssue && incident.name === nameMapping['OTHER_ISSUE'])
          )
          &&
          (
            (this.states.devicetypeLocation && incident.uniqueDeviceTypeNames.includes('location')) ||
            (this.states.devicetypeRvo && incident.uniqueDeviceTypeNames.includes('rvo')) ||
            (this.states.devicetypePhase && incident.uniqueDeviceTypeNames.includes('phase')) ||
            (this.states.devicetypeLightline && incident.uniqueDeviceTypeNames.includes('lightline')) ||
            (this.states.devicetypeLightplace && incident.uniqueDeviceTypeNames.includes('lightplace')) ||
            (this.states.devicetypeLightpoint && incident.uniqueDeviceTypeNames.includes('lightpoint'))
          )
        ) {
        } else {
          // The incident does not match the selected states
          return false;
        }

        // TODO: can we just convert object to string and search in it? the same as filterPasportEntities
        // Convert the incident object to an array of its values
        if (this.fulltext && this.fulltext.length > 0) {
          const incidentValues = Object.values(incident);
          // Check if any of the values includes the search term
          return incidentValues.some(value =>
            value !== null && value.toString().toLowerCase().includes(this.fulltext)
          );
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        let comparison = 0;
        if (orderState.orderField === 'evidenceDate') {
          // Convert the date strings into Date objects for comparison
          const dateA = new Date(a[orderState.orderField]);
          const dateB = new Date(b[orderState.orderField]);
          comparison = dateA.getTime() - dateB.getTime();
        } else if (orderState.orderField === 'lastUpdateDate') {
          // Convert the date strings into Date objects for comparison
          const dateA = new Date(a[orderState.orderField]);
          const dateB = new Date(b[orderState.orderField]);
          comparison = dateA.getTime() - dateB.getTime();
        } else {
          // Compare the values directly
          comparison = a[orderState.orderField].localeCompare(b[orderState.orderField]);
        }
        // Reverse the order if the direction is 'desc'
        return orderState.orderDirection === 'desc' ? comparison * -1 : comparison;
      }).map((item, index) => {
        return { ...item, listOrderNumber: index + 1 };
      });

    // update total pages
    this.updateIncidentsPagesTotal(Math.ceil(sortedData.length / this.settingsRecordsPerPage));
    // change active page if some records are filtered and active page is higher than total pages
    if ((this.incidentsPagesActiveSubject.getValue() > this.incidentsPagesTotalSubject.getValue()) && (this.incidentsPagesTotalSubject.getValue() > 0)) {
      // set active page to 1
      this.updateIncidentsPagesActive(1);
    }
    // TODO: do we want to update incidents here?
    this.updateIncidentsFiltered(sortedData);
    return sortedData;
  }

  pageIncidents(incidents: Incident[], orderState: MapFilterOrderState): Incident[] {
    // apply paging
    let start = (this.incidentsPagesActiveSubject.getValue() - 1) * this.settingsRecordsPerPage;
    let end = start + this.settingsRecordsPerPage;
    // Get the incidents for the current page
    const pagedData = incidents.slice(start, end);

    // TODO: do we want to update incidents here?
    this.updateIncidentsFilteredPaged(pagedData);
    return pagedData;
  }

  startPolling(): void {
    // Clear any existing polling timer
    this.stopPolling();
    this.fetchApiGetIncidents();
    this.fetchApiGetAgents();
    //this.fetchApiGetPasportItems();
    //this.fetchApiGetPasportEntities();
    // return;

    // TODO: deprecated - after implementing SSE we dont need this pooling anymore
    // Start the polling mechanism
    // this.pollingTimer = setInterval(() => {
    //   this.fetchApiGetIncidentsLastUpdate().then(incidents => {
    //     // Update the map markers if needed
    //     //this.updateMapMarkers(incidents);
    //     //this.showIncidentsOnMap(incidents);
    //   }).catch(error => {
    //     console.error('Error fetching incidents:', error);
    //   });
    // }, this.pollingInterval);
  }

  startPollingMetrics(): void {
    // Clear any existing polling timer
    this.stopPollingMetrics();
    // Start the polling mechanism
    this.polingTimerMetrics = setInterval(() => {
      // Decrement the counter
      let counter = this.poolingMetricsCounter.getValue();
      counter--;

      // If the counter has reached 0
      if (counter === 0) {
        // Fetch the metrics
        if (!this.lastTaskForMetrics) throw new Error('No task for metrics');
        this.fetchApiGetMetricsTemp(this.lastTaskForMetrics).then(metrics => {
          // Handle the metrics here
        }).catch(error => {
          console.error('Error fetching incidents:', error);
        });
      } else {
        // Update the counter
        this.updatePoolingMetricsCounter(counter);
      }
    }, 1000);
  }

  stopPolling(): void {
    // Clear the polling timer
    clearInterval(this.pollingTimer);
  }
  stopPollingMetrics(): void {
    // Clear the polling timer
    clearInterval(this.polingTimerMetrics);
  }

  // FIXME: this is not required? time is updated anyways?
  refreshNotificationItems(): void {
    return;
    this.updateNotificationItems(this.notificationItems);
  }

  ngOnDestroy(): void {
    // Stop the polling mechanism
    this.stopPolling();
    this.stopPollingMetrics();
  }

  /**
   * Generates a random UUID (Universally Unique Identifier).
   *
   * @returns A string representing the generated UUID.
   */
  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  /**
   * Formats the given entity type string.
   * @param entityType - The entity type to be formatted.
   * @returns The formatted entity type string.
   */
  public formatEntityType(entityType: string | undefined): string {
    if (!entityType) {
      return 'Neznámy typ';
    }

    // TODO: hardcoded translations
    entityType = entityType.toLowerCase();
    switch (entityType) {
      case 'lightplace':  return 'Stožiar';
      case 'lightpoint': return 'Svietidlo';
      case 'lightline': return 'Vetva';
      case 'phase': return 'Fáza';
      case 'rvo': return 'Rozvádzač';
      case 'location': return 'Lokalita';
      case 'lc': return 'LC modul';
      case 'rvoc': return 'RVOC';
      case 'output': return 'AC/DC Výstup';
      case 'trafficcounter': return 'Merač intenzity dopravy';
      case 'smartButton': return 'SMART tlačidlo';
      case 'sensorModule': return 'SMART senzor';
      case 'motionSensor': return 'SMART detektor';
      case 'smartbutton': return 'SMART tlačidlo';
      case 'sensormodule': return 'SMART senzor';
      case 'motionsensor': return 'SMART detektor';
      case 'surfacetemperaturesensor': return 'Senzor teploty povrchu'; // not used for now?
      case 'speedometer': return 'Merač rýchlosti';
      case 'socket': return 'Zásuvka';

      case 'floodsensor': return 'Záplavový senzor'; // not used for now?
      case 'agsensor': return 'Meteostanica AG Data';
      case 'uniza': return 'UNIZA';
      case 'shmu': return 'SHMÚ';
      default: return entityType;
    }
  }

  public formatPasportEntityAttributes(entity: PasportEntity): string {
    // pasportEntity.data is a json object. we need to remove specific keys and return simple string
    let data = entity.data;
    // remove keys name from the data
    delete data['name'];

    return '';
  }

  async fetchApiGetPasportEntityFile(entityUuid: string, fileUuid: string): Promise<SafeUrl> {
    try {
      await this.authService.refreshToken();

      const response = await fetch(`${environment.URL_PASPORT_FILES.replace('{id}', entityUuid) }/${fileUuid}`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);  // Keep as SafeUrl

      return safeUrl;
    } catch (error) {
      return '';
    }
  }

  async fetchApiGetObject(uuid: string): Promise<Incident[]> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_OBJECT.replace('{id}', uuid) }`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const incidents = await response.json();
      return incidents;
    } catch (error) {
      return [];
    }
  }


  /**
   * Fetches general statistics data from the API for a specified time range.
   *
   * @param {number} from - The start timestamp of the time range in milliseconds.
   * @param {number} to - The end timestamp of the time range in milliseconds.
   * @returns {Promise<StatisticsGeneralResponse>} A promise that resolves to a StatisticsGeneralResponse object.
   *
   * @description
   * This method performs the following steps:
   * 1. Refreshes the authentication token using the authService.
   * 2. Sends a GET request to the general statistics API endpoint with the specified time range.
   * 3. Parses the JSON response.
   * 4. Returns the parsed data as a StatisticsGeneralResponse object.
   *
   * If an error occurs during the process, an empty StatisticsGeneralResponse object is returned.
   *
   * @throws {Error} If the network response is not ok. However, this error is caught and handled internally.
   *
   * @example
   * try {
   *   const from = Date.now() - 7 * 24 * 60 * 60 * 1000; // 7 days ago
   *   const to = Date.now();
   *   const statistics = await dataService.fetchApiGetStatisticsGeneral(from, to);
   *   console.log(statistics);
   * } catch (error) {
   *   console.error('Failed to fetch general statistics:', error);
   * }
   */
  async fetchApiGetStatisticsGeneral(from: number, to: number): Promise<StatisticsGeneralResponse> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_STATISTICS_GENERAL}?from=${from}&to=${to}`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return {} as StatisticsGeneralResponse;
    }
  }

  /**
   * Fetches statistics data by category from the API for a specified time range.
   *
   * @param {number} from - The start timestamp of the time range in milliseconds.
   * @param {number} to - The end timestamp of the time range in milliseconds.
   * @returns {Promise<StatisticsCategoryResponse[]>} A promise that resolves to an array of StatisticsCategoryResponse objects.
   *
   * @description
   * This method performs the following steps:
   * 1. Refreshes the authentication token using the authService.
   * 2. Sends a GET request to the category statistics API endpoint with the specified time range.
   * 3. Parses the JSON response.
   * 4. Returns the parsed data as an array of StatisticsCategoryResponse objects.
   *
   * If an error occurs during the process, an empty array is returned.
   *
   * @throws {Error} If the network response is not ok. However, this error is caught and handled internally.
   *
   * @example
   * try {
   *   const from = Date.now() - 30 * 24 * 60 * 60 * 1000; // 30 days ago
   *   const to = Date.now();
   *   const categoryStatistics = await dataService.fetchApiGetStatisticsCategory(from, to);
   *   console.log(categoryStatistics);
   * } catch (error) {
   *   console.error('Failed to fetch category statistics:', error);
   * }
   */
  async fetchApiGetStatisticsCategory(from: number, to: number): Promise<StatisticsCategoryResponse[]> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_STATISTICS_CATEGORY}?from=${from}&to=${to}`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return [] as StatisticsCategoryResponse[];
    }
  }

  /**
   * Fetches statistics data for incidents from the API for a specified time range.
   *
   * @param {number} from - The start timestamp of the time range in milliseconds.
   * @param {number} to - The end timestamp of the time range in milliseconds.
   * @returns {Promise<Incident[]>} A promise that resolves to an array of Incident objects.
   *
   * @description
   * This method performs the following steps:
   * 1. Refreshes the authentication token using the authService.
   * 2. Sends a GET request to the incidents statistics API endpoint with the specified time range.
   * 3. Parses the JSON response.
   * 4. Returns the parsed data as an array of Incident objects.
   *
   * If an error occurs during the process, an empty array is returned.
   *
   * @throws {Error} If the network response is not ok. However, this error is caught and handled internally.
   *
   * @example
   * try {
   *   const from = Date.now() - 7 * 24 * 60 * 60 * 1000; // 7 days ago
   *   const to = Date.now();
   *   const incidentStatistics = await dataService.fetchApiGetStatisticsIncidents(from, to);
   *   console.log(incidentStatistics);
   * } catch (error) {
   *   console.error('Failed to fetch incident statistics:', error);
   * }
   */
  async fetchApiGetStatisticsIncidents(from: number, to: number): Promise<Incident[]> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_STATISTICS_INCIDENTS}?from=${from}&to=${to}`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return [] as Incident[];
    }
  }

  /**
   * Retrieves the label for a given incident state value.
   *
   * @param {string} value - The value of the incident state to look up.
   * @returns {string} The label corresponding to the incident state value, or the original value if not found.
   *
   * @description
   * This method searches through the `incidentStates` array to find a state object
   * whose `value` property matches the input `value`. If found, it returns the
   * corresponding `label`. If no match is found, it returns the original input `value`.
   *
   * @example
   * // Assuming incidentStates contains {value: 'new', label: 'New Incident'}
   * const label = dataService.getIncidentStateLabel('new');
   * console.log(label); // Outputs: 'New Incident'
   *
   * // If the value doesn't match any known state
   * const unknownLabel = dataService.getIncidentStateLabel('unknown');
   * console.log(unknownLabel); // Outputs: 'unknown'
   */
  getIncidentStateLabel(value: string): string {
    const state = this.incidentStates.find(state => state.value === value);
    return state ? state.label : value;
  }

  /**
   * Fetches Pasport templates from the API.
   *
   * @returns {Promise<PasportTemplateEntity[]>} A promise that resolves to an array of PasportTemplateEntity objects.
   *
   * @description
   * This method performs the following steps:
   * 1. Refreshes the authentication token.
   * 2. Sends a GET request to the Pasport templates endpoint.
   * 3. Processes the response and extracts a dictionary of translations.
   * 4. Updates the state management service with the extracted dictionary.
   * 5. Returns the fetched data.
   *
   * If an error occurs during the process, it logs the error and returns an empty array.
   *
   * @throws {Error} If the network response is not ok.
   *
   * @example
   * try {
   *   const templates = await dataService.fetchApiGetPasportTemplates();
   *   console.log(templates);
   * } catch (error) {
   *   console.error('Failed to fetch Pasport templates:', error);
   * }
   */
  async fetchApiGetPasportTemplates(): Promise<PasportTemplateEntity[]> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(environment.URL_PASPORT_TEMPLATES, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const dictionary = this.extractPasportTemplateDictionary(data);
      this.stateManagementService.setPasportTemplateDictionary(dictionary);
      console.log('Pasport templates fetched and dictionary set', dictionary);
      return data;
    } catch (error) {
      console.error('Error fetching pasport templates:', error);
      return [] as PasportTemplateEntity[];
    }
  }

  /**
   * Extracts translations from a Pasport template object and creates a dictionary.
   *
   * @param {any} obj - The Pasport template object to extract translations from.
   * @param {string} [lang='sk'] - The language code to filter translations (default is 'sk' for Slovak).
   * @returns {PasportTemplateDictionary} A dictionary of translations.
   *
   * @description
   * This method recursively traverses the given object and extracts translations
   * for the specified language. It creates a flat dictionary where keys are derived
   * from the object structure and values are the corresponding translations.
   *
   * The method performs the following steps:
   * 1. Initializes an empty translations dictionary.
   * 2. Defines a recursive 'traverse' function to explore the object structure.
   * 3. For each node in the object:
   *    - If it's an array, it traverses each item.
   *    - If it's an object with a 'translations' property, it extracts the translation
   *      for the specified language.
   *    - For other object properties, it continues traversing with updated keys.
   * 4. Starts the traversal from the root object.
   * 5. Returns the compiled dictionary of translations.
   *
   * @example
   * const templateObj = {
   *   name: { translations: [{ lang: 'sk', translation: 'Meno' }] },
   *   age: { translations: [{ lang: 'sk', translation: 'Vek' }] }
   * };
   * const dictionary = this.extractPasportTemplateDictionary(templateObj);
   * // Result: { name: 'Meno', age: 'Vek' }
   */
  private extractPasportTemplateDictionary(obj: any, lang: string = 'sk'): PasportTemplateDictionary {
    const translations: PasportTemplateDictionary = {};

    function traverse(node: any, parentKey = '') {
      if (node && typeof node === 'object') {
        if (Array.isArray(node)) {
          node.forEach(item => traverse(item, parentKey));
        } else {
          if (node.translations) {
            node.translations.forEach((translation: any) => {
              if (translation.lang === lang) {
                translations[parentKey] = translation.translation;
              }
            });
          }
          Object.entries(node).forEach(([key, value]) => {
            traverse(value, key);
          });
        }
      }
    }

    traverse(obj);
    return translations;
  }

  async fetchApiPostPasportEntity(uuid: string | null, entityTypeId: number, entityType: string, params:{ [key: string]: any }): Promise<ApiResponsePostPasportEntity> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(environment.URL_PASPORT_ENTITIES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          "entityType": {
            "id": entityTypeId,
            "type": entityType
          },
          "data": params
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.error('There has been a problem with your fetch post operation:', error);
      return {} as ApiResponsePostPasportEntity; // Return false in case of error
    }
  }

  async fetchApiGetPasportEntityDetails(uuid: string): Promise<PasportEntityDetails | null> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_PASPORT_ENTITIES}/${uuid}`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching pasport entity:', error);
      return null;
    }
  }

  async fetchApiPatchPasportEntityParams(uuid: string, params:{ [key: string]: any }): Promise<PasportEntityDetails | null> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_PASPORT_ENTITIES}/${uuid}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          "data": params
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching pasport entity:', error);
      return null;
    }
  }

  async fetchApiGetPasportEntitiesByParams(params: { [key: string]: any }): Promise<ApiResponseGetPasportEntities> {
    try {
      await this.authService.refreshToken();

      // Convert params object to URLSearchParams
      const searchParams = new URLSearchParams();
      for (const [key, value] of Object.entries(params)) {
        if (typeof value === 'object') {
          searchParams.append(key, JSON.stringify(value));
        } else {
          searchParams.append(key, value.toString());
        }
      }

      // Append search params to the URL
      const url = `${environment.URL_PASPORT_ENTITIES}?${searchParams.toString()}`;

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const json = await response.json();
      return json;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {} as ApiResponseGetPasportEntities;
    }
  }

  async fetchApiGetPasportRelationsDescendants(id: number): Promise<ApiResponseGetDescendants> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_PASPORT_RELATIONS_DESCENDANTS}/${id}`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching pasport entity descendants:', error);
      return {} as ApiResponseGetDescendants;
    }
  }

  async fetchApiGetPasportRelationsAncestors(id: number): Promise<ApiResponseGetAncestors> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_PASPORT_RELATIONS_ANCESTORS}/${id}`, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching pasport entity ancestors:', error);
      return {} as ApiResponseGetAncestors;
    }
  }

  async fetchApiPostPasportRelations(ancestorUUID: string, descendantUUID: string, relationType: string): Promise<ApiResponsePostPasportRelations> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(environment.URL_PASPORT_RELATIONS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userDetails?.token}`
        },
        body: JSON.stringify({
          "ancestorUuid": ancestorUUID,
          "descendantUuid": descendantUUID,
          "hierarchyType": relationType
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching pasport entity:', error);
      return {} as ApiResponsePostPasportRelations;
    }
  }

  async fetchApiDeletePasportRelation(id: number): Promise<ApiResponseDeletePasportRelation> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(`${environment.URL_PASPORT_RELATIONS}/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    }
    catch (error) {
      console.error('Error deleting pasport relation:', error);
      return {} as ApiResponseDeletePasportRelation;
    }
  }

  async fetchApiPostPasportExport(incidentList: string[], type: string): Promise<Blob> {
    await this.authService.refreshToken();
    const url = environment.URL_PASPORT_EXPORT.replace('{type}', type);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(incidentList),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.userDetails?.token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.blob();
  }

  /**
   * Fetches the history of a passport entity from the API
   * @param uuid - The unique identifier of the passport entity
   * @returns Promise containing the passport entity history response
   * @throws Error if network response is not ok
   */
  async fetchApiGetPasportEntityHistory(uuid: string): Promise<ApiResponseGetPasportEntityHistory> {
    try {
      await this.authService.refreshToken();
      const url = environment.URL_PASPORT_ENTITY_HISTORY.replace('{id}', uuid)+'?sort=timestamp,DESC';
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching pasport entity history:', error);
      return {} as ApiResponseGetPasportEntityHistory;
    }
  }

  /**
   * Fetches the allowed relations for passport entities from the API
   * @returns Promise containing the allowed relations response
   * @throws Error if network response is not ok
   */
  async fetchApiGetPasportRelationsAllowed(): Promise<ApiResponseGetPasportRelationsAllowed> {
    try {
      await this.authService.refreshToken();
      const response = await fetch(environment.URL_PASPORT_RELATIONS_ALLOWED, {
        headers: {
          'Authorization': `Bearer ${this.userDetails?.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      this.allowedRelations = await response.json();
      return this.allowedRelations;
    } catch (error) {
      console.error('Error fetching pasport templates:', error);
      return [] as ApiResponseGetPasportRelationsAllowed;
    }
  }

  /**
   * Gets the numeric ID of a passport entity by its UUID
   * @param uuid - The unique string identifier (UUID) of the passport entity
   * @returns Promise containing the numeric ID if found, null otherwise
   */
  async getPasportEntityIdByUuid(uuid: string): Promise<number | null> {
    const result = await this.fetchApiGetPasportEntityDetails(uuid);
    return result?.entity?.id || null;
  }

}