/**
 * Component for managing relationships between Pasport devices and entities
 *
 * This component provides functionality to:
 * - Create new relationships between Pasport entities
 * - Navigate and select entities in a hierarchical structure
 * - Search for entities manually or through predefined hierarchies
 * - Handle both predefined and manual relationship creation workflows
 * - Validate and enforce allowed relationship types
 * - Maintain state of selected entities and relationship paths
 *
 * Key features:
 * - Predefined hierarchy navigation with type validation
 * - Manual entity search with type filtering
 * - Ancestor/descendant relationship traversal
 * - State management for selected entities and paths
 * - Event emission for relationship updates
 */
import { environment } from '@environments/environment';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import { PasportEntity, PasportEntityDetails } from '@app/shared/interfaces/pasport-template.interface';

@Component({
  selector: 'app-pasport-device-new-relation',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './pasport-device-new-relation.component.html',
  styleUrl: './pasport-device-new-relation.component.css'
})
export class PasportDeviceNewRelationComponent {
  @Input() pasportEntityUuid: string | null = null;

  pasportEntity: PasportEntityDetails | null = null;

  selectedParentControlUuid: string | null = null;

  parentSearch: string = '';
  parentControlSearch: string = '';
  selectedParentUuidManual: string | null = null;

  //pasportEntity: PasportEntity | null = null;
  //relations: PasportEntityRelation[] = [];
  selectedRelationType: string | null = null;
  predefinedHierarchy: {
    entityType: {
      id: number,
      type: string
    },
    pasportEntity: PasportEntity | null
  }[] = [];
  controlEntities: PasportEntity[] = [];
  controlEntitiesManual: PasportEntity[] = [];
  lightnetEntities: PasportEntity[] = [];
  pasportHierarchyControl: PasportEntity[] = [];

  nextPredefinedHierarchyItemType: string | undefined;

  checkboxStatesParentSearch = {
    all: true,
    location: true,
    rvo: true,
    rvoc: true,
    lightline: true,
    output: true,
    lightplace: true,
    lc: true,
    lightpoint: true
  };

  constructor(public dataService: DataService) {}

  // detect pasportEntityUuid change and fetch pasportEntity
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['pasportEntityUuid']) {
      this.init();
      // refresh allowed relations
      await this.dataService.fetchApiGetPasportRelationsAllowed();
      if (this.pasportEntityUuid) {
        this.pasportEntity = await this.dataService.fetchApiGetPasportEntityDetails(this.pasportEntityUuid);
      }
    }
  }

  /**
   * Initializes/resets the component state by clearing all UI-related variables
   * This includes:
   * - Selected relation type
   * - Pasport entity reference
   * - Parent control UUID
   * - Search inputs for parent entities
   * - Manually selected parent UUID
   */
  init() {
    // clear UI states
    this.selectedRelationType = null;
    this.pasportEntity = null;
    this.selectedParentControlUuid = null;
    this.parentSearch = '';
    this.parentControlSearch = '';
    this.selectedParentUuidManual = null;
  }

  /** Event emitter that fires when a new relation is created */
  @Output() onNewRelation: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Creates a new predefined relation between two pasport entities
   *
   * This method:
   * 1. Finds the last selected entity in the predefined hierarchy
   * 2. Creates a relation between that entity and the current pasport entity
   * 3. Resets all UI state and hierarchy data after successful creation
   * 4. Emits an event to notify parent components to refresh relations
   *
   * @returns Promise<void>
   */
  async createRelationPredefined() {
    // get last item that has pasportEntity defined
    const lastItemIndex = this.predefinedHierarchy.reduceRight((acc, item, index) => {
      return acc === -1 && item.pasportEntity !== null ? index : acc;
    }, -1);

    const lastUuid = this.predefinedHierarchy[lastItemIndex]?.pasportEntity?.uuid;

    if (this.selectedRelationType && this.pasportEntityUuid && lastUuid) {
      const newRelationResult = await this.dataService.fetchApiPostPasportRelations(lastUuid, this.pasportEntityUuid, this.selectedRelationType);
      // new relation has been created
      if (newRelationResult && (newRelationResult.id >= 0)) {
        // emit function to refresh relations
        this.onNewRelation.emit();
      }

      // reset search input
      this.parentSearch = '';
      this.parentControlSearch = '';

      // reset hierarchy
      this.controlEntities = [];
      this.controlEntitiesManual = [];
      this.pasportHierarchyControl = [];
      this.addPasportHierarchyControlItem();

      this.predefinedHierarchy = [];
      this.selectedRelationType = null;
      this.onPredefinedRelationTypeChange(null);
    }
  }

  /**
   * Adds a new item to the Pasport hierarchy control structure
   *
   * This method handles two scenarios:
   * 1. Initial load - Fetches descendants of the default primary location
   * 2. Subsequent loads - Adds selected item to hierarchy and fetches its descendants
   *
   * The method:
   * - Clears search inputs and lightnet entities
   * - Updates control entities based on hierarchy position
   * - Maintains the pasportHierarchyControl array to track the hierarchy path
   *
   * @returns Promise<void>
   */
  async addPasportHierarchyControlItem() {
    this.parentControlSearch = '';
    this.lightnetEntities = [];

    if (this.controlEntities.length <= 0) {
      this.controlEntities = [];
      // get id of the pasport entity by uuid
      const id = await this.dataService.getPasportEntityIdByUuid(environment.DEFAULT_PRIMARY_LOCATION_UUID);
      if (id) {
        const result = await this.dataService.fetchApiGetPasportRelationsDescendants(id);
        this.controlEntities = result.descendants;
      }
    } else {
      // clear control hierarchy
      // this.pasportHierarchyControl = []
      // add selected item to pasport hierarchy control array
      const selectedItem = this.controlEntities.find(item => item.uuid === this.selectedParentControlUuid);
      if (selectedItem) {
        this.pasportHierarchyControl.push(selectedItem);
      }
      this.controlEntities = [];
      // FIXME: hardcoded types
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(this.pasportHierarchyControl[this.pasportHierarchyControl.length - 1].id);
      this.controlEntities = result.descendants;
      this.selectedParentControlUuid = null;
    }
  }

  /**
   * Handles changes to the predefined relation type selection
   *
   * This method:
   * 1. Gets the entity type ID and relation type
   * 2. Filters allowed hierarchies based on relation type and entity type
   * 3. Builds the predefined hierarchy structure from allowed ancestors
   * 4. Sets up the next hierarchy item and initializes entity selection
   *
   * @param event The change event from the relation type selector
   * @returns Promise<void>
   * @throws Error if no allowed hierarchies are found for the selected relation type
   */
  async onPredefinedRelationTypeChange(event: any) {
    // TODO: remove hardcoded 5 after testing
    const newEntityTypeId = this.pasportEntity?.entity?.entityType?.id; // Fallback to 5 if not set
    const relationType = this.selectedRelationType;

    if (!relationType) {
      this.predefinedHierarchy = [];
      return;
    }

    // find allowed hierarchies from config array allowedHierarchies
    const allowedHierarchies = this.dataService.allowedRelations.filter(hierarchy => hierarchy.relationType === relationType && hierarchy.typeId === newEntityTypeId);

    if (!(allowedHierarchies.length > 0)) {
      console.error('No allowed hierarchies found');
      this.predefinedHierarchy = [];
      return;
    }

    this.controlEntities = [];
    this.predefinedHierarchy = [];

    for (let i = 0; i < allowedHierarchies[0].allowedTypeAncestors.length; i++) {
      const entityTypeId = allowedHierarchies[0].allowedTypeAncestors[i].id;
      const entityType = allowedHierarchies[0].allowedTypeAncestors[i].type;
      this.predefinedHierarchy.push({
        entityType: {
          id: entityTypeId,
          type: entityType
        },
        pasportEntity: null
      });
    }

    this.setNextPredefinedHierarchyItem();
    this.addPasportEntityToPredefinedHierarchy();
  }

  /**
   * Sets the next item type in the predefined hierarchy that needs to be filled
   *
   * This method:
   * 1. Finds the last filled index by scanning backwards through the hierarchy
   * 2. Calculates the next index that needs to be filled
   * 3. Sets nextPredefinedHierarchyItemType to the entity type at that index
   *    or undefined if we've reached the end
   */
  setNextPredefinedHierarchyItem() {
    let lastFilledIndex = -1;
    for (let i = this.predefinedHierarchy.length - 1; i >= 0; i--) {
      if (this.predefinedHierarchy[i].pasportEntity !== null) {
        lastFilledIndex = i;
        break;
      }
    }

    const nextIndex = lastFilledIndex + 1;
    if (nextIndex < this.predefinedHierarchy.length) {
      this.nextPredefinedHierarchyItemType = this.predefinedHierarchy[nextIndex].entityType.type;
    } else {
      this.nextPredefinedHierarchyItemType = undefined;
    }
  }

  /**
   * Adds a Pasport entity to the predefined hierarchy based on selection state
   *
   * This method handles two scenarios:
   * 1. Initial load - Fetches descendants of the default primary location
   * 2. Selection made - Updates hierarchy with selected item and fetches its descendants
   *
   * The method:
   * - Checks if control entities need to be initially populated
   * - Finds and updates the matching predefined hierarchy item when an entity is selected
   * - Fetches descendants of the selected entity to populate next level options
   * - Updates the next hierarchy item that needs to be filled
   *
   * @returns {Promise<void>}
   */
  async addPasportEntityToPredefinedHierarchy() {
    if (this.controlEntities.length <= 0) {
      this.controlEntities = [];
      const id = await this.dataService.getPasportEntityIdByUuid(environment.DEFAULT_PRIMARY_LOCATION_UUID);
      if (id) {
        const result = await this.dataService.fetchApiGetPasportRelationsDescendants(id);
        this.controlEntities = result.descendants;
      }
      this.setNextPredefinedHierarchyItem();
    } else {
      // find selected item
      const selectedItem = this.controlEntities.find(item => item.uuid === this.selectedParentControlUuid);
      if (selectedItem) {
        // try to find item in predefined hierarchy by entity type
        const predefinedItem = this.predefinedHierarchy.find(item => item.entityType.type === selectedItem.entityType.type);
        if (predefinedItem) {
          predefinedItem.pasportEntity = selectedItem;

          this.controlEntities = [];
          // FIXME: hardcoded types
          const result = await this.dataService.fetchApiGetPasportRelationsDescendants(predefinedItem.pasportEntity.id);
          this.controlEntities = result.descendants;
        }
      }

      this.selectedParentControlUuid = null;
      this.setNextPredefinedHierarchyItem();
    }
  }

  /**
   * Resets the predefined hierarchy items starting from a given index and updates control entities
   *
   * This method performs two main operations:
   * 1. Resets hierarchy items:
   *    - If an index is provided, clears the pasportEntity for that item and all subsequent items
   *    - This effectively "undoes" selections from that point forward
   *
   * 2. Updates control entities list:
   *    - Finds the last valid hierarchy item that still has a pasportEntity
   *    - If found, fetches and displays its descendants as control entities
   *    - If no valid items remain, fetches descendants of the default primary location
   *
   * Finally, updates the next hierarchy item that needs user input
   *
   * @param itemIndex - Index in the predefined hierarchy to start resetting from, or null
   * @returns Promise that resolves when reset and updates are complete
   */
  async resetPredefinedHierarchyItem(itemIndex: number | null) {
    // reset item at index and all items after it
    if (itemIndex !== null && itemIndex >= 0) {
      for (let i = itemIndex; i < this.predefinedHierarchy.length; i++) {
        this.predefinedHierarchy[i].pasportEntity = null;
      }
    }

    // find last item in predefined hierarchy that has pasportEntity
    const lastItemIndex = this.predefinedHierarchy.reduceRight((acc, item, index) => {
      return acc === -1 && item.pasportEntity !== null ? index : acc;
    }, -1);

    if (lastItemIndex !== -1 && this.predefinedHierarchy[lastItemIndex].pasportEntity) {
      const id = this.predefinedHierarchy[lastItemIndex]?.pasportEntity?.id;
      if (id) {
        const result = await this.dataService.fetchApiGetPasportRelationsDescendants(id);
        this.controlEntities = result.descendants;
      }
    } else {
      this.controlEntities = [];

      const id = await this.dataService.getPasportEntityIdByUuid(environment.DEFAULT_PRIMARY_LOCATION_UUID);
      if (id) {
        const result = await this.dataService.fetchApiGetPasportRelationsDescendants(id);
        this.controlEntities = result.descendants;
      }
    }

    this.setNextPredefinedHierarchyItem();
  }

  /**
   * Searches for control entities manually based on selected entity types and search criteria
   *
   * This method performs the following:
   * 1. Builds a comma-separated string of entity types based on checkbox selections:
   *    - If 'all' is selected, includes all possible entity types
   *    - Otherwise only includes specifically checked entity types
   *
   * 2. Constructs search parameters including:
   *    - The types string for filtering by entity types
   *    - Search criteria to filter entities by name using the parentControlSearch value
   *
   * 3. Makes an API call to fetch matching entities and updates controlEntitiesManual
   * with the results
   *
   * @returns Promise that resolves when the search is complete
   */
  async searchControlEntitiesManual() {
    let types = '';
    // TODO: .all not used anymore?
    if (this.checkboxStatesParentSearch.all) {
      types = 'lightplace,lightpoint,trafficCounter,other,phase,breaker,rvoc,output,lc,location,rvo,lightline,crossing,twilightSwitch';
    } else {
      if (this.checkboxStatesParentSearch.location) {
        types += 'location,';
      }
      if (this.checkboxStatesParentSearch.rvo) {
        types += 'rvo,';
      }
      if (this.checkboxStatesParentSearch.rvoc) {
        types += 'rvoc,';
      }
      if (this.checkboxStatesParentSearch.lightline) {
        types += 'lightline,';
      }
      if (this.checkboxStatesParentSearch.output) {
        types += 'output,';
      }
      if (this.checkboxStatesParentSearch.lc) {
        types += 'lc,';
      }
      if (this.checkboxStatesParentSearch.lightplace) {
        types += 'lightplace,';
      }
      if (this.checkboxStatesParentSearch.lightpoint) {
        types += 'lightpoint,';
      }
      // remove last comma
      types = types.slice(0, -1);
    }

    this.controlEntitiesManual = [];
    // FIXME: hardcoded types
    const params = {
      types: types,
      jsonSearchCriteriaList: {
        "dataOption": "all",
        "searchCriteriaList": [
          { "filterKey": "name", "operation": "cn", "value": this.parentControlSearch }
        ]
      }
    };
    const result = await this.dataService.fetchApiGetPasportEntitiesByParams(params);
    this.controlEntitiesManual = result.entities.content;
  }

  /**
   * Adds a manually selected Pasport entity to the predefined hierarchy and populates its ancestors
   *
   * This method:
   * 1. Clears the search input and resets the predefined hierarchy
   * 2. Finds the selected entity from controlEntitiesManual
   * 3. If found, attempts to fit the entity into the predefined hierarchy based on type
   * 4. Recursively fetches and adds ancestors of the selected entity
   * 5. Stops when reaching a location type ancestor or max iterations
   * 6. Cleans up by resetting control entities and hierarchy item
   *
   * @returns Promise<void>
   */
  async addPasportHierarchyPredefinedItemManual() {
    this.parentControlSearch = '';

    // clear hierarchy
    //this.pasportHierarchyControl = []

    // remove all entity items from predefined hierarchy
    for (const item of this.predefinedHierarchy) {
      item.pasportEntity = null;
    }

    // add selected item to pasport hierarchy control array
    const selectedItem = this.controlEntitiesManual.find(item => item.uuid === this.selectedParentUuidManual);

    // we have found item
    if (selectedItem) {
      // try to fit selected item into predefined hierarchy
      const predefinedItem = this.predefinedHierarchy.find(item => item.entityType.type === selectedItem.entityType.type);
      if (predefinedItem) {
        predefinedItem.pasportEntity = selectedItem;

        // recreate control hierarchy to fetch ancestors of added item in loop
        let result = await this.dataService.fetchApiGetPasportRelationsAncestors(selectedItem.id);
        let counter = 0;
        while (result.ancestors.length > 0 && counter < 20) {
          counter++;
          // add all ancestors to the top of the array
          for (const ancestor of result.ancestors) {
            // try to fit ancestor into predefined hierarchy
            const predefinedAncestor = this.predefinedHierarchy.find(item => item.entityType.type === ancestor.entityType.type);
            if (predefinedAncestor) {
              predefinedAncestor.pasportEntity = ancestor;
            }
          }

          if (result.ancestors[0].entityType.type === 'location') {
            break;
          }
          result = await this.dataService.fetchApiGetPasportRelationsAncestors(result.ancestors[0].id);
        }
      }
    }

    // // cleanup for next use
    this.controlEntities = [];
    this.resetPredefinedHierarchyItem(null);
  }

}
