
<button id="filter-pasport-rvo-button-{{ uniqueId }}" [attr.data-dropdown-toggle]="'filter-pasport-rvo-data-'+uniqueId"
  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
  type="button">
  Filter RVO
  <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
  </svg>
</button>

<!-- Dropdown menu -->
<div id="filter-pasport-rvo-data-{{ uniqueId }}"
  class="z-10 hidden max-w-72 bg-gray-50 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" >
  <div class="h-2"></div>
  <div class="p-2">
    <label for="input-search-filter-rvo-{{ uniqueId }}"
      class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div class="relative">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
        </svg>
      </div>
      <input [formControl]="inputSearchFilter" type="search" id="input-search-filter-rvo-{{ uniqueId }}"
        class="block w-full px-4 py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Hľadať RVO ..." required>
    </div>
  </div>
  <div class="text-center pb-2">Označených: {{ checkedRvos.length }}</div>
  <ul class="overflow-auto p-3 space-y-3 text-sm bg-white text-gray-700 dark:text-gray-200" aria-labelledby="filter-pasport-rvo-button" style="max-height: 60vh;">
    @for (location of data; track location.uuid) {
      <li>
        <div class="flex items-center">
          <input [formControl]="location.formControl" id="{{ uniqueId }}-{{ location.uuid }}" type="checkbox" value="" (change)="onCheckboxLocationChange($event,location)"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
          <label for="{{ uniqueId }}-{{ location.uuid }}" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ location.name }}</label>
        </div>
      </li>
      @for (rvo of location.rvos; track rvo.uuid) {
        <li>
          <div class="flex items-center ml-4">
            <input [formControl]="rvo.formControl" #rvoCheckbox id="{{ uniqueId }}-{{ rvo.uuid }}" type="checkbox" value="" (change)="onCheckboxRvoChange()"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="{{ uniqueId }}-{{ rvo.uuid }}" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ rvo.name }}</label>
          </div>
        </li>
      }
    }
  </ul>
  <div class="h-2"></div>
</div>