@if (deviceTypeName?.toLowerCase() === 'lightpoint') {
<div class="mb-4">
  <p class="font-bold mb-2">Ovládanie svetelného miesta {{ objectName }}</p>
  <div class="bg-gray-100 p-2 rounded-lg flex flex-nowrap w-full justify-between">
    <div class="relative flex items-center">
      <button type="button" id="decrement-button" (click)="controlDeviceDetailsNewIntensityDecrement()"
        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg py-3 px-6 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 18 2">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
        </svg>
      </button>
      <input [(ngModel)]="controlDeviceDetailsNewIntensity" type="number" min="0" max="100"
        class="bg-gray-50 border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="" value="50" required />
      <div
        class="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
        <svg class="w-2.5 h-2.5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z" />
        </svg>
        <span>Intenzita</span>
      </div>
      <button type="button" id="increment-button" (click)="controlDeviceDetailsNewIntensityIncrement()"
        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg py-3 px-6 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 18 18">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M9 1v16M1 9h16" />
        </svg>
      </button>
    </div>
    <button type="button" (click)="controlSetIntensity()"
      [disabled]="controlDeviceDetailsIntensity === controlDeviceDetailsNewIntensity"
      [class.disabled]="controlDeviceDetailsIntensity === controlDeviceDetailsNewIntensity" class="flex-none ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      Uložiť
    </button>
  </div>
  <p class="mt-1 text-sm text-gray-500 dark:text-gray-400 text-center">
    Nastavte požadovanú intenzitu a dajte uložiť.
  </p>
</div>
} @else if (deviceTypeName?.toLowerCase() === 'rvo') {
<div class="mb-4">
  <p class="font-bold mb-2">Ovládanie RVO {{ objectName }}</p>
  <div class="mb-2 bg-gray-100 p-2 rounded-lg flex flex-col flex-nowrap w-full">
    <div class="mt-1 text-center">
      Aktívne lokálne nastavenie RVO<br />
      (možné meniť iba fyzicky na zariadení)
    </div>
    <div class="mt-2 flex justify-center">
      <span [ngClass]="{
              'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300': (controlDeviceDetailsMode !== 'localOn' && controlDeviceDetailsMode !== 'localOff'),
              'bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-300': ((!(controlDeviceDetailsMode !== 'localOn' && controlDeviceDetailsMode !== 'localOff')) || (controlDeviceDetailsMode === ''))
            }"
        class="whitespace-nowrap inline-flex items-center mb-2 text-sm font-medium me-2 px-2.5 py-1 rounded-full">
        Auto</span>
      <span [ngClass]="{
              'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300': controlDeviceDetailsMode === 'localOn',
              'bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-300': !(controlDeviceDetailsMode === 'localOn')
            }"
        class="whitespace-nowrap inline-flex items-center mb-2 text-sm font-medium me-2 px-2.5 py-1 rounded-full">
        On</span>
      <span [ngClass]="{
              'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300': controlDeviceDetailsMode === 'localOff',
              'bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-300': !(controlDeviceDetailsMode === 'localOff')
            }"
        class="whitespace-nowrap inline-flex items-center mb-2 text-sm font-medium me-2 px-2.5 py-1 rounded-full">
        Off</span>
    </div>
    <div class="mt-2 flex justify-center items-center">
      <button type="button" (click)="refreshControlDeviceDetailsMode()"
        class="inline-flex items-center text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"
          class="w-3.5 h-3.5">
          <path
            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z">
          </path>
        </svg>&nbsp;Aktualizovať</button>
    </div>
  </div>

  <div class="bg-gray-100 p-2 rounded-lg flex flex-nowrap w-full">
    <select [(ngModel)]="controlDeviceDetailsNewMode"
      [disabled]="controlDeviceDetailsMode === 'localOn' || controlDeviceDetailsMode === 'localOff'"
      class="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      <option value="" disabled selected>Nastavenie módu</option>
      <option value="auto">Auto</option>
      <option value="backup">Backup</option>
      <option value="manual">Manual</option>
      <option value="manualOn">Manual On</option>
      <option value="manualOff">Manual Off</option>
    </select>
    <button type="button" (click)="controlSetMode()"
      [disabled]="controlDeviceDetailsMode === controlDeviceDetailsNewMode || controlDeviceDetailsMode === 'localOn' || controlDeviceDetailsMode === 'localOff'"
      [class.disabled]="controlDeviceDetailsMode === controlDeviceDetailsNewMode" class="flex-none ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
              disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      Uložiť
    </button>
  </div>
  @if (controlDeviceDetailsMode === 'localOn' || controlDeviceDetailsMode === 'localOff') {
  <p class="mt-1 text-sm text-red-800 dark:text-red-400 text-center">
    Pozor! V režime <b>Local On</b> a <b>Local Off</b> nie je možné nastavovať RVO na diaľku.
  </p>
  } @else {
  <p class="mt-1 text-sm text-gray-500 dark:text-gray-400 text-center">
    Nastavte požadovaný mód a dajte uložiť.
  </p>
  }
</div>
}