import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataService, Incident, nameMapping } from '@app/services/data.service';
import { FormatSkDatePipe } from '@app/shared/format-sk-date.pipe';

@Component({
  selector: 'app-dashboard-full',
  standalone: true,
  imports: [FormsModule, FormatSkDatePipe],
  templateUrl: './dashboard-full.component.html',
  styleUrl: './dashboard-full.component.css'
})
export class DashboardFullComponent implements OnInit {
  nameMapping = nameMapping;
  selectedYear: number | null = null;
  selectedMonth: number | string = 'all';
  selectedYearMonthDesc: string = '--/----';
  dashboardIncidents: Incident[] = [];
  years: number[] = [];

  constructor(
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.generateYears();
  }

  /**
   * Generates an array of years from 2023 to the current year.
   *
   * This method populates the `years` array with a range of years starting from 2023
   * up to and including the current year. It's typically used to provide options
   * for a year selection dropdown in the dashboard.
   *
   * @returns {void}
   *
   * @example
   * // If the current year is 2025, after calling this method:
   * // this.years will be [2023, 2024, 2025]
   */
  generateYears(): void {
    const startYear = 2023;
    const currentYear = new Date().getFullYear();
    for (let year = startYear; year <= currentYear; year++) {
      this.years.push(year);
    }
  }

  public async search(): Promise<void> {
    // construct date from and date to from select elements
    let dateFrom: string = '';
    let dateTo: string = '';
    this.dashboardIncidents = [];
    // only year selected
    if (this.selectedYear && this.selectedMonth == 'all') {
      this.selectedYearMonthDesc = `rok ${this.selectedYear}`;
      dateFrom = `${this.selectedYear}-01-01`;
      dateTo = `${this.selectedYear}-12-31`;
    } else if (this.selectedYear && this.selectedMonth) {
      const monthWithLeadingZero = (this.selectedMonth as number).toString().padStart(2, '0');
      this.selectedYearMonthDesc = `${monthWithLeadingZero}/${this.selectedYear}`;
      dateFrom = `${this.selectedYear}-${monthWithLeadingZero}-01`;
      // check how many days are in the month
      const daysInMonth = new Date(this.selectedYear, this.selectedMonth as number, 0).getDate();
      dateTo = `${this.selectedYear}-${monthWithLeadingZero}-${daysInMonth}`;
    } else {
      this.selectedYearMonthDesc = '--/----';
      return;
    }

    // Convert dateFrom and dateTo to numbers
    const fromTimestamp = Math.floor(new Date(dateFrom).getTime() );
    const toTimestamp = Math.floor(new Date(dateTo).getTime() );

    const incidents = await this.dataService.fetchApiGetStatisticsIncidents(fromTimestamp, toTimestamp);
    this.dashboardIncidents = incidents;
  }

  public async export(): Promise<void> {
    console.log("Downloading excel file.");
    if (this.dashboardIncidents.length == 0) {
      console.error("No incidents to download.");
      return;
    }

    let ids: number[] = [];
    this.dashboardIncidents.forEach(entity => {
      ids.push(entity.id);
    });
    this.dataService.fetchApiPostExcel(ids).then(
      result => {
        if (result && result instanceof Blob) {
          let filename: string = "incidents.xlsx";
          let binaryData = [result];
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'text/excel' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          console.log("File downloaded successfully.");
        } else {
          console.error("Response body is not of type Blob.");
        }
      },
      error => {
        console.error("Error while downloading excel file.");
        console.error(error);
      }
    );

  }
}